.Stagesliderfullwidth .m-tp-swiper {
  padding-bottom: rem(0px);
}
.m-swiperSlideContent {
  max-width: rem(390px);
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: rem(83px);
  right: rem(80px);
}
.m-swiperSlideContent--2 {
  max-width: rem(390px);
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: rem(83px);
  right: rem(566px);
  color: #002a5c;
}
.m-swiperSlideContent-line {
  width: 99%;
  height: rem(2px);
  // background-color: #002A5C;
  background-color: #ffffff;
}

.m-swiperSlideContent-header {
  font-weight: bold !important;
  // color: #002A5C !important;
  color: #ffffff !important;
  line-height: rem(52px) !important;
  margin-top: rem(13px) !important;
}
.m-swiperSlideContent-line--2 {
  background-color: #002a5c;
  color: #002a5c !important;
}
.m-swiperSlideContent-header--2 {
  color: #002a5c !important;
}
.m-swiperSlideContent-description--2 {
  color: #002a5c !important;
}
.m-swiperSlideContent-description {
  display: block;
  font-size: rem(20px);
  font-weight: bold;
  // color: #002A5C;
  color: #ffffff;
  margin-top: rem(10px);
  background: transparent;
}
.m-swiperSlideContentMobile {
  display: none;
  text-align: center;
  margin-top: rem(15px);
}
.m-swiperSlideContentMobile-header {
  line-height: 1.2 !important;
  text-transform: uppercase;
  margin-bottom: rem(8px) !important;
}
.m-swiperSlideContentMobile-personName {
  margin-bottom: rem(3px);
}
.m-swiperSlideContentMobile-personDescription {
  font-weight: 300;
  color: $text-main-gray;
}
.mainPage {
  .m-about-qs-headline .m-about-qs-years-switch--visible span {
    transition: all 0.5s;
  }
  .AboutSlider .m-tp-swiper-container .swiper-button-next, .AboutSlider .m-tp-swiper-container .swiper-button-prev {
    width: rem(19px);
    height: rem(26px);
    top: rem(167px);
    bottom: auto;
    right: rem(11px);
    overflow: visible;
  }
  .centerContent {
    // min-height: rem(676px);
  }
  .m-about-qs-headline .m-about-qs-years-switch::before {
    display: block;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    height: rem(146px);
  }
  .m-about-qs-headline {
    display: table;
    table-layout: fixed;
    width: 100%;
    // background-image: url(../img/page-about/main-banner-slider.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .m-about-qs-descriptions {
    margin-top: rem(6px);
  }
  .h-tp-visuallyhidden {
    width: rem(980px);
    min-height: rem(0px) !important;
    margin: 0;
    max-height: rem(0px) !important;
  }
  .m-tp-swiper-container .swiper-wrapper {
    height: auto !important;
  }
  .m-tp-swiper-container .swiper-slide {
    height: auto !important;
    position: initial !important;
  }
  .m-about-qs-descriptions-item {
    width: rem(980px);
    // min-height: rem(264px);
    // background-image: linear-gradient(to bottom, #ebf3f6, #d4dde5);
    margin: 0;
    //transition: 200ms all ease;
    position: relative !important;
    max-height: rem(500px);
  }
  .m-bannerMore-image {
    // width: rem(165px) !important;
    // height: rem(165px) !important;
    // position: absolute;
    // margin-top: rem(39px);
    // margin-left: rem(35px);
  }
  .m-about-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .copytext {
    font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif !important;
    font-size: rem(22px) !important;
    font-weight: 200 !important;
    font-stretch: condensed !important;
    line-height: 1.45 !important;
    letter-spacing: rem(-1.1px)!important;
    font-size: rem(24px);
    font-weight: 300;
    font-stretch: condensed;
    line-height: 1.33;
    text-align: left;
    color: #373f44;
    
  }
   .slider-header {
    min-height: rem(147px);
    width: 100%;
    background-image: url(../img/page-about/main-banner-slider.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 10;
  }
  .data-link-icon {
    width: rem(50px);
    height: rem(32px);
    margin: 0 auto;
  }
  .slick-slider-items {
     position: relative; 
  }
  .slick-slider {
    margin: rem(20px) 0;
  }
  .slick-dots {
    display: none !important;
  }
  .slick-next {
      position: absolute;
      right: rem(0px) !important;
      top: rem(17px) !important;
      width: rem(30px) !important;
      height: rem(33px) !important;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
  }
  .slick-prev {
      position: absolute;
      transform: rotate(180deg);
      left: rem(0px) !important;
      top: rem(17px) !important;
      opacity: 1 !important;
      width: rem(30px) !important;
      height: rem(33px) !important;
      z-index: 10;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
  }
  .data-link {
    width: rem(32px);
    height: rem(32px);
    position: relative;
    display: table-cell;
    &:after {
      content: '';
      position: absolute;
      width: rem(35px);
      height: rem(2px);
      top: rem(77px);
      background-color: transparent;
      transition: all 0.3s ease;
    }
    &:before {
      content: "";
      display: block;
      box-sizing: content-box;
      width: rem(32px);
      height: rem(32px);
      margin: 0 auto;
      margin-bottom: rem(12px);
      border: rem(1px) solid #ccc;
      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.3s;
      background-image: url(../img/icon_plus.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 46%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .data-link--visible {
    width: rem(32px);
    height: rem(32px);
    position: absolute;
    
    &:after {
      content: '';
      position: absolute;
      width: rem(35px);
      height: rem(2px);
      top: rem(77px);
      background-color: #837650;
      transition: all 0.3s ease;
      
    }
    &:before { 
      background-image: url(../img/js_tp_button_close-gold.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 38%;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.3s;
    }  
  }
  .data-link-text {
    text-align: center;
    display: inline-block;
    margin: 4.0em;
    margin-top: rem(13px);
    padding-bottom: .25em;
    border-bottom: solid transparent rem(2px);
    font-family: 'Roboto Condensed',Arial,sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    color: #373f44;
    margin-bottom: rem(5px);
  }
  .data-content {
    .m-about-qs-descriptions-item {
     
      margin-top: rem(-53px);
    }
  }
}
.m-about-qs-descriptions-item--anim {
    animation: ANIMATION_IMG 800ms forwards;
  }
  @keyframes ANIMATION_IMG {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
}

