.aboutPage {
  .js-afterelement-block {
    position: absolute;
    width: 0;
    height: 0;
    top: rem(362px);
    left: rem(533px);
    opacity: 1;
    border-left: rem(20px) solid transparent;
    border-right: rem(20px) solid transparent;
    border-bottom: rem(25px) solid  #ebf3f6;
  }
  .js-afterelement-block--hidden {
    visibility: hidden;
  }
  .m-about-qs-headline .m-about-qs-years-switch--visible span {
    transition: all 0.5s;
  }
  .m-tp-swiper-container .swiper-button-prev {
    width: rem(19px);
    height: rem(26px);
    top: rem(310px);
    bottom: auto;
    right: rem(15px);
    overflow: visible;
  }
  .AboutSlider .m-tp-swiper-container .swiper-button-next, .AboutSlider .m-tp-swiper-container .swiper-button-prev {
    width: rem(19px);
    height: rem(26px);
    top: rem(310px);
    bottom: auto;
    right: rem(11px);
    overflow: visible;
  }
  .centerContent {
    min-height: rem(676px);
  }
  .m-about-qs-headline .m-about-qs-years-switch::before {
    display: block;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    height: rem(286px);
  }
  .m-about-qs-headline {
    display: table;
    table-layout: fixed;
    min-height: rem(191px);
    width: 100%;
    background-image: url(../img/page-about/main-banner-slider.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .m-about-qs-descriptions {
    margin-top: rem(16px);
  }
  .h-tp-visuallyhidden {
    width: rem(980px);
    min-height: rem(0px) !important;
    margin: 0;
    max-height: rem(0px) !important;
    transition: all 0.5s ease;
  }
  .m-tp-swiper-container .swiper-wrapper {
    height: auto !important;
  }
  .m-tp-swiper-container .swiper-slide {
    height: auto !important;
    position: initial !important;
  }
  .m-about-qs-descriptions-item {
    width: rem(980px);
    min-height: rem(264px);
    background-image: linear-gradient(to bottom, #ebf3f6, #d4dde5);
    margin: 0;
    transition: all 0.5s ease;
    position: relative !important;
    max-height: rem(500px);
  }
  .js-afterelement-block--anim {
    animation: ANIMATION_NAME 0.8s forwards;
  }
  @keyframes ANIMATION_NAME {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
  .m-bannerMore-image {
    width: rem(165px) !important;
    height: rem(165px) !important;
    position: absolute;
    margin-top: rem(39px);
    margin-left: rem(35px);
    opacity: 0;
    transition: all 0.3s ease;
  }
  .m-bannerMore-image--visible {
    opacity: 1;
    transition: all 0.5s 0.4s ease;
  }

  .copytext {
    font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif !important;
    font-size: rem(22px) !important;
    font-weight: 200 !important;
    font-stretch: condensed !important;
    line-height: 1.45 !important;
    letter-spacing: rem(-1.1px)!important;
    color: #002a5b;
    padding-top: rem(41px);
    padding-right: rem(40px);
    padding-left: rem(243px);
    text-align: left;
    opacity: 0;
    transition: all 0.3s ease;
  }
  .copytext--visible {
    opacity: 1;
    transition: all 0.5s 0.4s ease;
  }
  .m-about-qs-years-switch--opacity {
    .m-bannerMore-image {
      opacity: 1;
    }
    .copytext {
      opacity: 1;
    }
  }






  .slider-header {
    min-height: rem(290px);
    width: 100%;
    background-image: url(../img/page-about/main-banner-slider.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 10;
  }
  .data-link-icon {
    width: rem(50px);
    height: rem(32px);
    margin: 0 auto;
  }
  .slick-slider-items {
     position: relative; 
  }
  .slick-slider {
    top: -rem(20px);
  }
  .slick-dots {
    display: none !important;
  }
  .slick-next {
      right: rem(11px) !important;
      top: rem(54px) !important;
      width: rem(85px) !important;
      height: rem(33px) !important;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
  }
  .slick-prev {
      transform: rotate(180deg);
      left: rem(17px) !important;
      top: rem(37px) !important;
      opacity: 1 !important;
      width: rem(85px) !important;
      height: rem(33px) !important;
      z-index: 10;
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
  }
  .data-link {
    width: rem(32px);
    height: rem(32px);
    position: relative;
    display: table-cell;
    &:after {
      content: '';
      position: absolute;
      width: rem(35px);
      height: rem(2px);
      top: rem(77px);
      background-color: transparent;
      transition: all 0.3s ease;
    }
    &:before {
      content: "";
      display: block;
      box-sizing: content-box;
      width: rem(32px);
      height: rem(32px);
      margin: 0 auto;
      margin-bottom: rem(12px);
      border: rem(1px) solid #ccc;
      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.3s;
      background-image: url(../img/icon_plus.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 46%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .data-link--visible {
    width: rem(32px);
    height: rem(32px);
    position: absolute;
    
    &:after {
      content: '';
      position: absolute;
      width: rem(35px);
      height: rem(2px);
      top: rem(77px);
      background-color: #837650;
      transition: all 0.3s ease;
      
    }
    &:before { 
      background-image: url(../img/js_tp_button_close-gold.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 38%;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.3s;
    }  
  }
  .data-link-text {
    text-align: center;
    display: inline-block;
    margin: 4.0em;
    margin-top: rem(13px);
    padding-bottom: .25em;
    border-bottom: solid transparent rem(2px);
    font-family: 'Roboto Condensed',Arial,sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
    color: #373f44;
    margin-bottom: rem(5px);
  }
  .data-content {
    .m-about-qs-descriptions-item {
     
      margin-top: -rem(53px);
    }
  }
  
}
