* {
  box-sizing: border-box;
}
ul, li, h1, h2, h3, h4, h5, p {
  padding: 0;
  margin: 0;
  font-weight: normal;
}
li {
  list-style: none;
}
a {
  color: inherit;
  text-decoration: none;
}

button, a {
  border: none;
  cursor: pointer;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range], button {
  &:active, &:focus {
    outline: none !important;/* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
}

input[type="range"]:-moz-focusring {
  border : rem(0px) !important;
  outline: none !important;
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}


textarea {
  resize: none;
}
.c-tp-copytext {
  font-family: $alt-font-light,Arial,Helvetica,Verdana,sans-serif !important;
  color: $text-main-gray;
  font-weight: 300;
  font-size: rem(16px);
  line-height: 1.15;
  @media screen and (max-width: rem(768px)) {
    line-height: 1.4;
  }
}
.c-tp-simplebutton--white {
  color: #ffffff;
  border-color: #ffffff;
}
.c-tp-simplebutton--white .c-tp-simplebutton-text {
  color: #ffffff;
}
.c-tp-simplebutton--white:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.c-tp-simplebutton--white:after {
  width: rem(30px);
  right: rem(-5px);
  background-image: url('/img/c_tp_simplebutton_icon_standard.png');
}
.c-tp-simplebutton--white:hover:after {
  background-image: url('/img/c_tp_simplebutton_icon.png');
}
.c-tp-simplebutton--white:hover .c-tp-simplebutton-text{
  color: #373e44;
}
.c-tp-simplebutton--blue {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #284178;
  border-color: #284178;
  &:hover {
    .c-tp-simplebutton-text {
      color: #fff !important;
    }
  }
  .c-tp-simplebutton-text {
    padding: 0.62em 3.68em;
    color: #284178;
  }
  &::after {
    display: none;
  }
}
.c-tp-simplebutton--primary {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #284178;
  color: #fff;
  border-color: #284178;
  &:hover {
    background: #fff;
    .c-tp-simplebutton-text {
      color: #284178 !important;
    }
  }
  .c-tp-simplebutton-text {
    padding: rem(10px) rem(20px);
    color: #fff;
  }
  &::after {
    display: none;
  }
}
