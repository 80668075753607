

//fonts
$main-font: 'Roboto Condensed';
$alt-font: 'InterstateWGL';
$alt-font-light: 'InterstateWGL-Light';

$fs-36px: 2em;
$fs-30px: 1.667em;
$fs-21px: 1.167em;
$fs-18px: 1em;
$fs-16px: 0.889em;
$fs-15px: 0.833em;
$fs-14px: 0.778em;
$fs-13px: 0.722em;
$fs-12px: 0.667em;
$fs-10px: 0.556em;

// grid
$max: 1670px;
$xlg: 1400px;
$lg: 1279px;
$md: 821px;
$sm: 767px;
$xs: 375px;

// sizes
$inner-page-banner-offset: rem(80px);