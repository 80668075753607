/* font-family: "RobotoCondensedRegular"; */
@font-face {
  font-family: "InterstateWGL";
  src: url("../fonts/InterstateWGL/InterstateWGL-Regular.eot");
  src: url("../fonts/InterstateWGL/InterstateWGL-Regular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/InterstateWGL/InterstateWGL-Regular.woff") format("woff"),
  url("../fonts/InterstateWGL/InterstateWGL-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "InterstateWGL-Light";
  src: url("../fonts/InterstateWGL/InterstateWGL-Light.eot");
  src: url("../fonts/InterstateWGL/InterstateWGL-Light.eot?#iefix")format("embedded-opentype"),
  url("../fonts/InterstateWGL/InterstateWGL-Light.woff") format("woff"),
  url("../fonts/InterstateWGL/InterstateWGL-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensedRegular.eot");
    src: url("../fonts/RobotoCondensedRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/RobotoCondensedRegular.woff") format("woff"),
    url("../fonts/RobotoCondensedRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensedLight.eot");
    src: url("../fonts/RobotoCondensedLight.eot?#iefix")format("embedded-opentype"),
    url("../fonts/RobotoCondensedLight.woff") format("woff"),
    url("../fonts/RobotoCondensedLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensedBold.eot");
    src: url("../fonts/RobotoCondensedBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/RobotoCondensedBold.woff") format("woff"),
    url("../fonts/RobotoCondensedBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/roboto-regular-webfont.woff") format("woff"), url("../fonts/roboto-regular-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  /* normal */
}
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"), url("../fonts/roboto-bold-webfont.woff") format("woff"), url("../fonts/roboto-bold-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  /* bold */
}
/* fallback cluster headlines */

@font-face {
  font-family: "tp-icon";
  src: url("../fonts/tp-icons.ttf?68f703f6863dc59a03b856f539ceeff5") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*# sourceMappingURL=std.css.map */
