.m-varieties {
  &-type {
    position: relative;
    padding-top: rem(13px);
    padding-bottom: rem(130px);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &._full-width-bg {
      background-size: 100%;
    }
    &-headline {
      margin-bottom: rem(7px);
      text-align: center;
      text-transform: uppercase;
      font-size: rem(36px);
      line-height: rem(39px);
      font-weight: 700;
    }
    &-img-mobile {
      display: none;
    }
    &-text {
      max-width: 55%;
      margin: auto;
      text-align: center;
    }
    &-list {
      margin-top: rem(40px);
      flex: 0 0 32%;
      &-wrapper {
        min-height: rem(260px);
        align-items: center;
      }
      &-icon {
        width: rem(48px);
        margin-right: rem(16px);
        flex-shrink: 0;
      }
      &-item {
        display: flex;
        margin-bottom: rem(17px);
        align-items: flex-start;
      }
      &-text {
        span {
          display: block;
          margin: rem(3px) 0 rem(5px);
          font-weight: 400;
          font-family: $alt-font;
          line-height: 1.2;
          font-size: rem(18px);
        }
        p {
          font-size: rem(16px);
          font-family: $alt-font-light;
        }
      }
    }
  }
  &-beans {
    &-about {
      width: 100%;
      padding: rem(100px) 0 rem(248px);
      display: flex;
      align-items: center;
      justify-content: center;
      @include bg-image('page-varieties/beans.png');
      background-size: 100%;
      &-text {
        width: rem(362px);
        max-width: 100%;
        margin-left: 10%;
      }
    }
    &-text-block {
      .text-block-img {
        min-height: rem(635px);
      }
    }
  }
  @media screen and (max-width: rem(768px)) {
    $offset: rem(26px);
    &-type {
      padding-top: 0;
      padding-bottom: rem(15px);
      background: none !important;
      &-img-mobile {
        position: absolute;
        left: 0;
        top: rem(50px);
        width: calc(100% + #{$offset} * 2);
        left: -$offset;
        display: block;
        z-index: -1;
      }
      &-text {
        max-width: 100%;
      }
      &-headline {
        margin-bottom: rem(15px);
        font-size: rem(24px);
        line-height: rem(26px);
      }
      &-list {
        width: 100%;
        flex: auto;
        margin-top: 0;
        &-wrapper {
          padding-top: rem(260px);
          flex-direction: column !important;
        }
        &-item {
          margin-bottom: rem(15px);
          &:empty {
            display: none;
          }
        }
        &-text {
          span {
            margin-top: 0;
            font-size: rem(16px);
          }
          p {
            line-height: 1.1;
          }
        }
      }
    }
    &-beans {
      &-about {
        padding-top: 0;
        @include bg-image('page-varieties/beans-mob.png');
        background-position: bottom left;
      }
      &-text-block {
        padding-top: rem(40px);
        .text-block-img {
          min-height: rem(400px);
          background-image: url('/img/page-varieties/img-7-mob.png') !important;
          background-size: 100%;
        }
      }
    }
  }
}