.ChainStores {
    .g-tp-colum--both {
      clear: both;
      height: rem(20px);
    }
    .c-tp-headline--80 {
        font-size: rem(18px) !important;
        line-height: rem(24px) !important;
        font-weight: bold !important;
        text-transform: uppercase;
        margin-bottom: rem(24px);
        letter-spacing: rem(0.4px);
    }
    .c-tp-headline--70 {
        font-size: rem(16px) !important;
        line-height: rem(18px) !important;
        font-weight: bold !important;
        text-transform: uppercase;
        letter-spacing: rem(0.3px);
        margin-bottom: rem(16px);
    }
    .Jumbotron {
        position: relative;

        .m-jumbotron-content {
            width: rem(377px);
            position: absolute;
            top: rem(41px);
            left: rem(45px);
        }
        
        .m-jumbotron-content-line {
            width: 100%;
            height: rem(2px);
            background-color: #ffffff;
        }
        .m-jumbotron-content-header {
            font-weight: bold !important;
            color: #fff !important;
            line-height: rem(47px) !important;
            text-transform: uppercase;
            padding-top: rem(8px);
            padding-bottom: rem(13px);
        }
        .m-jumbotron-content-header--1 {
            font-size: rem(32px);
            font-weight: bold;
            line-height: 1.31;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: rem(0.7px);
            margin-top: rem(5px);
            margin-bottom: rem(5px);
        }
    }

    .Information {
        margin-bottom: rem(31px);

        .c-tp-copytext {
            font-size: rem(18px) !important;
            font-weight: 300 !important;
            line-height: rem(24px) !important;
            color: #373f44;
            letter-spacing: rem(0.3px);
            margin-bottom: rem(25px);
        }
    }

    .Declarations {
        position: relative;
        .c-tp-headline--left {
          float: left;
          width: rem(600px);
          clear: both;
        }
        .c-tp-headline {
            padding-left: rem(38px);
        }

        .m-tp-declaration-list {
            margin-bottom: rem(56px);
        }

        .m-tp-declaration-item {
            margin-bottom: rem(15px);
        }
        .m-tp-declaration-image {
            width: rem(327px);
            margin: 0 auto;
            margin-bottom: rem(50px);
            position: absolute;
            top: 0;
            right: 0;
        }

        .m-tp-declaration-link {
            text-decoration: underline;
            font-weight: 300 !important;
            font-size: rem(16px) !important;
            letter-spacing: rem(0.3px);
            color: #373f44;
            padding-left: rem(38px);
            position: relative;

            &:hover {
                opacity: 0.8;
            }

            &:before {
                content: '';
                width: rem(15px);
                height: rem(20px);
                background-image: url('../img/page-chain-stores/cs-icon-doc.png');
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

@media screen and (max-width: rem(768px)) {
    .ChainStores {

        .Information {
            padding-right: rem(40px);
            padding-left: rem(40px);
        }

        .Declarations {
            padding-right: rem(40px);
            padding-left: rem(40px);

            .m-tp-declaration-image {
                position: relative;
            }
        }
    }
}
@media screen and (max-width: rem(768px)) {
    .ChainStores {
        .c-tp-headline--80 {
            font-size: rem(16px) !important;
            margin-bottom: rem(10px);
        }
        .Jumbotron {
            .m-tp-singleimage {
                height: rem(150px);

                img {
                    height: 100%;
                    object-fit: cover;
                    padding-bottom: 0;
                }
            }
            .m-jumbotron-content {
                width: auto;
                top: rem(29px);
                left: rem(22px);
            }
            .m-jumbotron-content-header {
                font-size: rem(25px) !important;
                line-height: normal !important;
                padding-top: rem(5px);
                padding-bottom: rem(9px);
            }
        }

        .Information {
            padding-right: rem(20px);
            padding-left: rem(20px);
            margin-bottom: rem(20px);

            .c-tp-copytext {
                font-size: rem(16px) !important;
                margin-bottom: rem(15px);
            }
        }

        .Declarations {
            padding-right: rem(20px);
            padding-left: rem(20px);
            .c-tp-headline {
                padding-left: rem(0px);
            }
            .m-tp-declaration-list {
                margin-bottom: rem(40px);
            }
            .m-tp-declaration-image {
                width: 90%;
            }
            .m-tp-declaration-link {
                // padding-left: rem(0px);

                // &:before {
                //     content: none;
                // }
            }
        }
    }
}