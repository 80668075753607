.btn {
  display: inline-block;
  align-items: center;
  padding: rem(16px) rem(8px) rem(16px) rem(12px) !important;
  color: $text-main-gray;
  font-family: $alt-font;
  font-weight: 400;
  font-size: rem(20px);
  background: #F8F6F2;
  border: 1px solid #DFDDD9;
  border-radius: rem(10px) 0 rem(10px) 0;
  cursor: pointer;
  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: rem(10px);
    height: rem(15px);
    margin: 0 0 0 rem(5px);
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat;
    background-size: contain;
  }
  &._no-arrow {
    &::after {
      display: none;
    }
  }
  &._big {
    width: rem(237px);
    max-width: 100%;
    padding: rem(14px) !important;
    font-size: rem(16px);
  }
  &._dark {
    background: $brown;
    color: $beige;
  }
  @media screen and (max-width: rem(768px)) {
    padding: rem(8px) rem(9px) rem(8px) rem(12px) !important;
    font-size: rem(16px);
    &._big {
      padding: rem(10px) !important;
      font-size: rem(12px);
    }
  }
}
