.m-grain-processing {
  &-headline.headline {
    margin-bottom: rem(16px);
    line-height: rem(39px) !important;
    text-align: center;
    color: #000;
  }
  &-subtitle {
    margin-bottom: rem(65px);
    text-align: center;
    font-size: rem(24px);
    line-height: rem(26px);
    font-family: $alt-font;
  }
  &-accordion {
    &-label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      padding-bottom: rem(23px);
      width: 100%;
      height: rem(281px);
      background-size: cover;
      background-position: center;
      text-align: center;
      cursor: pointer;
      &::before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(87, 56, 28, 0) 0%, #57381C 100%);
      }
      span {
        position: relative;
        margin-bottom: rem(30px);
        font-size: rem(36px);
        color: #FFF;
        font-weight: 700;
        letter-spacing: 0.02em;
        font-family: $alt-font;
        z-index: 1;
      }
      img {
        position: relative;
        z-index: 1;
        transition: 0.4s;
        .active & {
          transform: rotate(180deg);
        }
      }
    }
    &-trigger {
      display: none;
      &:checked {
        & + div {
          display: block;
        }
      }
    }
    &-content {
      display: none;
      padding: rem(55px) 0;
      background: #F8F6F2;
      &-text {
        width: 65%;
        margin: auto;
      }
    }
  }
  &-stages {
    display: flex;
    justify-content: center;
    padding: rem(46px) 0 rem(80px);
  }
  &-stage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:not(:last-of-type) {
      margin-right: rem(70px);
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(100% + rem(35px));
        transform: translate(-50%, -50%);
        display: block;
        width: rem(16px);
        height: rem(32px);
        @include bg-image('/icons/icon_arrow.svg');
      }
    }
    &-image {
      width: rem(172px);
      margin-bottom: rem(20px);
    }
    span {
      font-size: rem(24px);
    }
  }
  @media screen and (max-width: rem(768px)) {
    &-accordion {
      &-label {
        height: rem(220px);
        padding-top: rem(30px);
        padding-bottom: rem(26px);
        span {
          font-size: rem(24px);
          line-height: 1;
          letter-spacing: 0.02em;
        }
        img {
          width: rem(66px);
        }
      }
      &-content {
        padding: rem(32px) rem(27px);
        &-text {
          width: 100%;
          p {
            font-size: rem(14px);
            line-height: rem(17px);
          }
        }
      }
    }
    &-stages {
      flex-direction: column;
      padding: rem(24px) 0 rem(62px);
    }
    &-stage {
      &-image {
        width: rem(176px);
      }
      span {
        font-size: rem(18px);
      }
      &:not(:last-of-type) {
        margin-right: 0;
        padding-bottom: rem(54px);
        &::before {
          width: rem(30px);
          height: rem(40px);
          bottom: rem(5px);
          left: 50%;
          top: auto;
          transform: translateX(-50%) rotate(90deg);
        }
      }
    }
  }
}