.field {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  &--select {
    &.has-error {
      .select2-selection {
        border-color: #FF0000;
      }
    }
    @media screen and (max-width: rem(768px)) {
      .select2 .select2-selection.select2-selection--single .select2-selection__rendered {
        padding: rem(13px) rem(10px) rem(12px) rem(12px);
        font-size: rem(14px);
        line-height: rem(14px);
      }
    }
  }
  &--checkbox + .field--checkbox {
    margin-top: rem(8px);
  }
  &__filezone {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: rem(768px)) {
      align-items: center;
    }
  }
  &--file {
    &.field {
      margin-bottom: rem(20px);
      @media screen and (max-width: rem(768px)) {
        margin-bottom: rem(15px);
      }
    }
    &-1 {
      &.field {
        margin-bottom: rem(41px);
        @media screen and (max-width: rem(768px)) {
          margin-bottom: rem(15px);
        }
      }
    }
    .field__label {
      background: #C0A468;
      padding: rem(9px) rem(45px);
      border-radius: rem(4px);
      font-size: rem(16px);
      line-height: rem(30px);
      font-weight: 300;
      color: #fff;
      cursor: pointer;
      &.has-error {
        border-color: #FF0000;
      }
      &-text {
        position: static;
      }
      .field__input {
        padding: 0;
        position: absolute;
      }
      @media screen and (max-width: rem(768px)) {
        padding: rem(6px) rem(25px);
        font-size: rem(12px);
        line-height: rem(20px);
      }
    }
    .field__title {
      padding-top: rem(7px);
      font-size: rem(16px);
      line-height: rem(30px);
      font-weight: 300;
      span {
        display: block;
        font-size: rem(12px);
        line-height: rem(12px);
        color: #828282;
      }
      @media screen and (max-width: rem(768px)) {
        padding-top: 0;
        font-size: rem(10px);
        line-height: rem(14px);
        font-family: $alt-font-light;
        span {
          font-size: rem(9px);
          line-height: rem(12px);
        }
      }
    }
  }
  &__input,
  &__placeholder,
  &__file,
  &__file .selected {
    font-weight: 300;
    font-family: $main-font;
    font-size: rem(18px);
    line-height: rem(22px);
    padding: rem(13px) rem(10px) rem(12px) rem(19px);
    @media screen and (max-width: rem(768px)) {
      padding: rem(9px) rem(10px) rem(9px) rem(12px);
      font-size: rem(10px);
      line-height: rem(14px);
    }
  }
  &__input {
    &[type="file"] {
      width: rem(0px);
      height: rem(0px);
      position: absolute;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      border: none;
    }
  }
  &__input,
  &__file {
    display: block;
    width: 100%;
    border: none;
    border: rem(1px) solid #B9B9B9;
    color: $main-gray;
    border-radius: rem(4px);
    box-sizing: border-box;
    background: transparent;
    appearance: none;
    outline: none;
    .has-error & {
      border-color: #FF0000;
    }
    &:focus {
      border-color: #284178;
      & ~ .field__placeholder {
        opacity: 0;
        transform: translateX(20%);
      }
    }
    &[disabled] {
      opacity: 0.5;
    }
  }
  &__placeholder,
  &--file .selected{
    position: absolute;
    top: 0;
    left: 0;
    color: #858585;
    transition: opacity 0.3s, transform 0.3s;
    cursor: text;
  }
  &--textarea {
    margin-bottom: rem(36px);
    textarea {
      height: rem(149px);
      width: 100%;
      border: rem(1px) solid #B9B9B9;
      border-radius: rem(4px);
      font-weight: 300;
      font-size: rem(16px);
      line-height: rem(22px);
      padding: rem(14px) rem(20px) rem(20px) rem(19px);
      color: #000;
      box-sizing: border-box;
      resize: none;
      @media screen and (max-width: rem(768px)) {
        height: rem(65px);
        padding: rem(9px) rem(20px) rem(20px) rem(11px);
      }
    }
  }
  &__error {
    position: absolute;
    left: 0;
    bottom: rem(-16px);
    width: 100%;
    display: none;
    opacity: 0;
    pointer-events: none;
    color: #FF0000;
    font-weight: 300;
    font-size: rem(12px);
    line-height: rem(14px);
    transition: opacity .2s ease-in-out;
    @media screen and (max-width: rem(768px)) {
      bottom: rem(-14px);
      font-size: rem(10px);
    }
  }
  &__text {
    margin-top: rem(12px);
    font-size: rem(14px);
    line-height: rem(17px);
  }
  &__label {
    position: relative;
    display: block;
    &.has-focus .field__placeholder,
    &.has-value .field__placeholder {
      opacity: 0;
      pointer-events: none;
      transform: translateX(20%);
    }
    &.has-error ~ .field__error {
      opacity: 1;
      display: block;
    }
    &.has-error .field__error {
      opacity: 1;
    }
    &--phone {
      &:hover, .field__input:hover {
        .field__placeholder {
          display: none;
        }
      }
      .field__placeholder {
        width: 100%;
      }
    }
  }
  &__label-text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: rem(12px);
    line-height: rem(14px);
    text-transform: uppercase;
    color: #fff;
    z-index: 1;
  }
}
.field--radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .field {
    &__wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &__label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: rem(30px);
      cursor: pointer;
      &-text {
        margin-bottom: rem(14px);
        position: static;
      }
    }
    &__text {
      margin-top: 0;
    }
    &__box {
      width: rem(20px);
      height: rem(20px);
      border-radius: 50%;
      border: rem(1px) solid #000;
      margin-right: rem(10px);
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem(12px);
        height: rem(12px);
        border-radius: 50%;
        transition: background-color .2s ease-in-out;
      }
    }
    &__input {
      border: none;
      position: absolute;
      opacity: 0;
      &:checked + .field__label {
        .field__box {
          &::before {
            background-color: #000;
          }
        }
      }
    }
  }
}
.field--textarea, .field--textblock {
  .field__label-text {
    position: static;
    margin-bottom:rem(8px);
  }
}
.field--checkbox {
  &.form__field {
    margin-bottom: 0;
  }
  .field {
    &__label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: rem(768px)) {
        align-items: flex-start;
      }
    }
    &__check{
      width: rem(14px);
      height: rem(11px);
      position: absolute;
      top: rem(6px);
      left: rem(4px);
      background: url('/img/check-thin.svg') 0 0 no-repeat;
      background-size: contain;
      opacity: 0;
      z-index: 1;
      transition: opacity .3s ease-in-out;
    }
    &__input {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      &:checked ~ .field__check{
        opacity: 1;
      }
    }
    &__box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 rem(22px);
      width: rem(22px);
      height: rem(22px);
      border-radius: rem(4px);
      border: rem(1px) solid #284178;
      color: #000;
      background: transparent;
      margin-right: rem(17px);
      cursor: pointer;
      &.has-error {
        border-color: #FF0000;
      }
    }
    &__text {
      font-weight: 300;
      font-size: rem(14px);
      font-family: $alt-font-light;
      line-height: rem(20px);
      color: #000;
      margin-top: 0;
      a {
        color: inherit;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    @media screen and (max-width: rem(768px)) {
      &__check{
        width: rem(10px);
        height: rem(8px);
        top: rem(3px);
        left: rem(2px);
      }
      &__box {
        width: rem(14px);
        height: rem(14px);
        flex: 0 0 rem(14px);
        margin-right: rem(8px);
      }
      &__text {
        font-size: rem(10px);
        line-height: rem(12px);
      }
    }
  }
  &.is-checked .icon-check {
    opacity: 1;
  }
}
.form {
  &__btns {
    display: flex;
    justify-content: space-between;
    text-align: center;
    @media screen and (max-width: rem(768px)) {
      .btn {
        width: calc(50% - #{rem(3px)}) !important;
      }
      .btn + .btn {
        margin-left: rem(6px);
      }
    }
    &--center {
      justify-content: center;
    }
  }
  &__field {
    position: relative;
    display: block;
    margin-bottom: rem(29px);
    @media screen and (max-width: rem(768px)) {
      margin-bottom: rem(19px);
    }
  }
}
.field-path {
  display: none;
  ._show-path & {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__img {
    width: rem(60px);
    height: rem(60px);
    margin-right: rem(20px);
    border-radius: rem(4px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__path {
    max-width: rem(210px);
    font-weight: 300;
    font-size: rem(12px);
    line-height: rem(16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media screen and (max-width: rem(768px)) {
      font-size: rem(10px);
    }
  }
  &__remove {
    font-weight: 300;
    font-size: rem(12px);
    line-height: rem(16px);
    text-transform: uppercase;
    color: #000;
    margin-left: auto;
    cursor: pointer;
  }
}
