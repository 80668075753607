.text-block {
  position: relative;
  display: flex;
  align-items: stretch;
  margin-bottom: rem(80px);
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    display: block;
    z-index: -1;
  }
  &.text-block--background {
    &::before {
      height: calc(100% - rem(86px));
    }
  }
  &._dark {
    &::before {
      background: $brown;
    }
  }
  &._light {
    &::before {
      background: $beige;
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 50%;
    padding-left:rem(235px);
    padding-right: rem(60px);
    .text-block--background & {
      padding-top: rem(43px);
      padding-bottom: rem(43px);
      margin-top: rem(33px);
      margin-bottom: rem(33px);
    }
    // ._dark & {
    //   background: $brown;
    // }
    // ._light & {
    //   background: $beige;
    // }
    ._right & {
      padding-left: rem(80px);
      padding-right: rem(150px);
    }
    p,li {
      &.d-flex {
        display: flex;
        align-items: flex-start;
        img {
          width: rem(12px);
          margin-right: rem(5px);
          margin-top: rem(3px);
          flex-shrink: 0;
        }
        &:not(:last-child) {
          margin-bottom: rem(38px);
        }
      }
      ._dark & {
        color: #E9D397;
      }
    }
    li {
      font-family: $alt-font-light;
      font-size: rem(16px);
      line-height: rem(22px);
      list-style: disc;
      list-style-position: inside;
      margin-bottom: 0 !important;
      &::marker {
        font-size: rem(10px);
      }
      &:not(:last-child) {
        margin-bottom: rem(20px);
      }
    }
  }
  &-headline {
    margin-bottom: rem(35px);
    font-size: rem(36px);
    line-height: rem(40px);
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #000;
    ._dark & {
      color: #FFF;
    }
  }
  &-img {
    display: block;
    flex: auto;
    min-height: rem(425px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: rem(768px)) {
    $offset: rem(26px);
    flex-direction: column;
    padding: 0 $offset 0;
    margin-bottom: 0;
    &:last-of-type {
      margin-bottom: rem(40px);
    }
    & + .centerContent {
      padding-top: rem(40px) !important;
    }
    &.text-block--background {
      &::before {
        height: 100%;
      }
    }
    &:first-of-type {
      margin-top: rem(-44px);
    }
    &-text {
      padding-left: 0;
      padding-right: 0;
      padding-top: rem(40px);
      margin-bottom: rem(25px);
      p {
        font-size: rem(14px);
        line-height: rem(17px);
        &:empty {
          display: none;
        }
        &.d-flex {
          &:not(:last-child) {
            margin-bottom: rem(12px);
          }
          img {
            width: rem(10px);
            margin-right: rem(12px);
          }
        }
      }
      .text-block--background & {
        padding-top: rem(22px);
        margin-bottom: rem(19px);
        margin-top: 0;
        padding-bottom: 0;
      }
      ._right & {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    &-headline {
      margin-bottom: rem(15px);
      font-size: rem(24px);
      line-height: rem(30px);
      letter-spacing: 0.02em;
    }
    &-img {
      order: 1;
      width: calc(100% + #{$offset} * 2);
      margin-left: -$offset;
      min-height: auto;
      height: 84vw;
      flex-shrink: 0;
      .CoffeeBeans &,
      ._img-order-first-mob & {
        order: -1;
      }
    }
  }
}