@media screen and (max-width: rem(768px)) {
  .Assortment {
      .m-swiperSlideContentMobile {
           display: none;
      }
      .m-swiperSlideContent {
           display: none;
      }
      .m-tp-swiper-container .swiper-button-next, .m-tp-swiper-container .swiper-button-prev {
          bottom: 5.8em;
      }
      .m-swiperSlideContent {
          width: rem(165px);
          right: rem(28px);
          top: rem(11px);
      }
      .m-swiperSlideContent-header {
         font-size: rem(16px) !important;
         font-weight: bold !important;
         font-stretch: condensed !important;
         line-height: 1.31 !important;
         text-align: center !important;
         padding: rem(2px);
         margin-top: rem(0px) !important;
      }
      .m-swiperSlideContent-description {
        font-size: rem(10px) !important;
        font-weight: bold !important;
        font-stretch: condensed !important;
        line-height: 2.1 !important;
        text-align: center !important;
      }
      .m-swiperSlideContent-line--2 {
        background-color: #002a5c !important;
        color: #002a5c !important;
      }
      .match_group_1 {
         padding-top: rem(3px);
      }
     .m-tp-productbox-image img {
         width: 100% !important;
     }
     .match_group_1 .m-tp-productbox-info,
     .match_group_2 .m-tp-productbox-info {
        padding-top: rem(13px);
        text-align: left;
        padding-left: 0;
        margin-left: rem(22px);
        margin-bottom: rem(30px);
    }
    
    .match_group_1 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text,
    .match_group_2 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text {
        font-size: rem(18px) !important;
        font-weight: bold !important;
        font-stretch: condensed !important;
        text-align: left !important;
        color: #373f44 !important;
    }
    .match_group_1 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text:after,
    .match_group_2 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text:after {
        position: absolute;
        content: '';
        top: 5%;
        right: -rem(24px);
        display: block;
        width: 1em;
        height: 100%;
        pointer-events: none;
        background-image: url(../img/c_tp_simplebutton_arrow_dark.png);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .match_group_2 .tp-component-productlist .tp-component-productlistitem {
        width: rem(480px);
        display: inline-block;
        text-align: center;
    }
    .match_group_2 {
        padding-top: rem(0px);
        padding-bottom: rem(15px);
    }
    .match_group_1 .tp-component-productlist .tp-component-productlistitem,
    .match_group_2 .tp-component-productlist .tp-component-productlistitem {
        width: 100%;
    }
    .m-tp-swiper-container .swiper-slide img,
    .m-tp-swiper-container .swiper-slide picture {
        width: 100%;
        object-fit: cover;
        height: auto;
    }
    .m-swiperSlideContent {
        width: rem(165px);
        left: 30%;
        top: rem(11px);
    }
    .m-swiperSlideContent-description {
        margin-top: rem(2px);
    }
  }
}    