.m-cooking-methods {
  &-top {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% + #{$inner-page-banner-offset} + #{rem(43px)});
      position: absolute;
      top: -$inner-page-banner-offset;
      left: 0;
      background: $beige;
      z-index: -1;
    }
  }
}