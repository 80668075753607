.ErrorPage {
   .centerContent {
      min-height: rem(432px);
   }
  .ErrorTitle {
    margin-top: rem(18px);
    h1 {
      font-size: rem(58px);
      font-weight: 300;
      font-stretch: condensed;
      color: #373f44;
    }
    h1:nth-child(2) {
      font-size: rem(38px);
      font-weight: 300;
      font-stretch: condensed;
      color: #373f44;
    }
    .g-tp-column {
      margin-top: rem(10px);
      .m-tp-textblock-headline {
        margin-top: -rem(15px);
      }
      .m-tp-singleimage {
        width: 83%;
        margin: 0 auto;
        margin-top: rem(28px);
      }
    }
  }
}

@media screen and (max-width: rem(768px)) {

}
@media screen and (max-width: rem(420px)) {

}