.ProductPage {
	padding-top: rem(112px);
	.centerContent {
    position: relative;
    width: rem(1000px);
		max-width: 100%;
  }
	@media screen and (max-width: rem(768px)) {
		padding-top: rem(57px);
	}
}

.product-page {
	&-main-info {
		display: flex;
		justify-content: space-between;
		padding-bottom: rem(40px);
		margin-bottom: rem(46px);
		border-bottom: 1px solid #A6A9AC;
		&-slider,
		&-content {
			width: 50%;
		}
	}
	&-slider {
		&-for {
			margin-bottom: rem(20px);
			&-slide {
				display: flex !important;
				align-items: center;
				justify-content: center;
			}
			&-img {
				height: rem(375px);
			}
		}
		&-nav {
			width: 90%;
			margin: auto;
			&-slide {
				margin: auto;
				width: rem(62px);
				display: flex !important;
				align-items: center;
				justify-content: center;
				padding-bottom: rem(6px);
				border-bottom: 1px solid transparent;
				cursor: pointer;
				.slick-current & {
					border-bottom-color: #000;
				}
			}
			&-img {
				height: rem(64px);
			}
		}
	}
	&-title {
		font-weight: 700;
		font-size: rem(36px);
		line-height: rem(40px);
		letter-spacing: 0.04em;
		font-family: $alt-font;
	}
	&-descr {
		margin-bottom: rem(32px);
		font-size: rem(36px);
		line-height: rem(39px);
		letter-spacing: 0.04em;
		font-family: $alt-font-light;
	}
	&-small-descr {
		margin-bottom: rem(16px);
		font-family: $alt-font;
		font-size: rem(18px);
	}
	&-saturation {
		padding: rem(32px) 0;
		margin-bottom: rem(28px);
		border-top: 1px solid #A6A9AC;
		border-bottom: 1px solid #A6A9AC;
	}
	&-list {
		span {
			display: block;
			margin-bottom: rem(16px);
			font-size: rem(18px);
			font-family: $alt-font;
		}
		ul {
			padding-left: rem(15px);
			li {
				margin-bottom: rem(12px);
				padding-left: rem(8px);
				font-size: rem(16px);
				line-height: rem(19px);
				list-style: disc;
				font-family: $alt-font-light;
			}
		}
	}
	&-info {
		margin-bottom: rem(100px);
		&-title {
			margin-bottom: rem(32px);
			font-size: rem(36px);
			line-height: rem(36px);
			font-family: $alt-font-light;
			br {
				display: none;
			}
		}
		&-row {
			display: flex;
			justify-content: space-between;
			& > div {
				flex: 0 0 50%;
			}
		}
		&-text {
			padding-right: rem(56px);
		}
	}
	&-other-products {
		position: relative;
		padding-top: rem(60px);
		margin-bottom: rem(80px);
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 90%;
			display: block;
			background: $brown;
		}
		&-title {
			margin-bottom: rem(40px);
			font-family: $alt-font-light !important;
			font-weight: normal;
			color: #FFF;
		}
		&-list {
			display: flex;
		}
	}
	&-cooking-recommendation {
		display: flex;
		align-items: center;
		margin-bottom: rem(100px);
		&-content {
			padding-right: rem(40px);
			font-family: $alt-font-light;
		}
		&-title {
			margin-bottom: rem(28px);
			font-size: rem(26px);
		}
		&-text {
			width: 80%;
			line-height: 1.1;
		}
		&-img {
			width: 50%;
		}
	}
	@media screen and (max-width: rem(768px)) {
		&-main-info {
			width: 100%;
			flex-direction: column;
			padding: 0 rem(26px) rem(10px);
			margin-bottom: rem(45px);
			&-slider,
			&-content {
				width: 100%;
			}
			&-slider {
				margin-bottom: rem(37px);
			}
		}
		&-slider {
			&-for {
				margin-bottom: rem(20px);
			}
		}
		&-title {
			margin-bottom: rem(3px);
		}
		&-title,
		&-descr {
			margin-bottom: rem(6px);
			font-size: rem(28px);
			line-height: rem(31px);
		}
		&-descr {
			margin-bottom: rem(12px);
		}
		&-small-descr {
			margin-bottom: rem(12px);
		}
		&-saturation {
			padding: rem(20px) 0 rem(16px);
			margin-bottom: rem(21px);
		}
		&-list {
			span {
				margin-bottom: rem(20px);
			}
		}
		&-info {
			padding: 0 rem(26px);
			margin-bottom: rem(56px);
			&-title {
				margin-bottom: rem(23px);
				font-size: rem(28px);
				line-height: 1.15;
			}
			&-text {
				margin-bottom: rem(20px);
				padding-right: rem(10px);
			}
			&-row {
				flex-direction: column;
			}
		}
		&-other-products {
			padding: rem(34px) rem(26px) 0;
			margin-bottom: rem(44px);
			&-title {
				margin-bottom: rem(30px);
				font-size: rem(28px);
				line-height: rem(30px);
			}
			&-list {
				padding-bottom: rem(32px);
        overflow-x: auto;
        &::-webkit-scrollbar {
          height: rem(7px);
        }
        &::-webkit-scrollbar-track {
          background: #CED0D1;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: #22292E;
          border-radius: 10px;
        }
				.product {
					flex-shrink: 0;
					width: 100%;
				}
			}
		}
		&-cooking-recommendation {
			padding: 0 rem(26px);
			margin-bottom: rem(40px);
			flex-direction: column;
			&-title {
				margin-bottom: rem(20px);
				font-size: rem(28px);
				line-height: 1.15;
			}
			&-text {
				width: 100%;
				margin-bottom: rem(18px);
				p {
					font-size: rem(16px);
					line-height: 1.25;
				}
			}
			&-img {
				width: 100%;
			}
		}
	}
}