.c-tp-headline.headline,
.c-tp-headline--90,
.match .c-tp-headline {
    font-family: $alt-font, Arial, Helvetica, Verdana, sans-serif!important;
    line-height: 1.45!important;
    font-weight: 700;
    text-transform: none;
    font-size: rem(36px);
    color: $text-main-gray;
}
.c-tp-headline--30 {
  font-size: rem(18px);
  font-weight: 700;
}
p {
  font-family: $alt-font-light;
  font-size: rem(16px);
  line-height: rem(22.5px);
  &:not(:last-child) {
    margin-bottom: rem(20px);
  }
}