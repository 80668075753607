.centerWrapper {
	position: relative;
}

.centerContent {
	width: rem(975px);
	// min-height: rem(600px);
	overflow: hidden;
	padding-top: 0;
}

.Stagesliderfullwidth {
	.m-tp-swiper {
		padding-bottom: rem(0px);
	}
	.m-tp-swiper-container {
		margin-bottom: 0;
	}
	.g-tp-row {
		margin: 0;
	}
	.g-tp-column {
		padding: 0;
	}
	.swiper-slide-img-mobile,
	.swiper-slide-img-small  {
		display: none;
	}
	.swiper-slide video {
		width: 100%;
		height: auto;
		object-fit: cover;
		&.swiper-slide-video-mobile {
			display: none;
		}
		&.swiper-slide-video-desktop {
			display: block;
		}
	}
	
}
@media screen and (max-height: 660px), screen and ( orientation: landscape) {
	.Stagesliderfullwidth .swiper-slide-img-small {
		display: block !important;
	}
	.Stagesliderfullwidth .swiper-slide-img-desktop,
	.swiper-slide-video-desktop {
		display: none;
	}
}
.AboutCompany {
	padding: rem(96px) 0 rem(44px);
	background: #F8F6F2;

	.c-tp-headline,
	.ProductsBlock .c-tp-headline,
	.NewsMain .c-tp-headline {
		
	}
	.c-tp-headline.headline {
		line-height: 1.1 !important;
		letter-spacing: 0.03em;
	}
	.c-tp-copytext {
		margin-top: rem(25px);
		margin-bottom: rem(40px);
	}
}
.ProductsBlock {
	padding: rem(75px) 0 rem(77px);
	&-content {
		display: flex;
		justify-content: center;
		.m-tp-teaser002 .c-tp-headline {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 0;
			font-size: rem(16px) !important;
			font-weight: 300 !important;
			text-transform: none;
			&.c-tp-headline--arrow:after {
				position: static;
				height: rem(15px);
				width: rem(22px);
				margin-left: 0;
				margin-top: rem(15px);
				background-image: url("data:image/svg+xml,%3Csvg width='25' height='14' viewBox='0 0 25 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6006 0.999924L12.4756 12.7104L1.35059 0.999924' stroke='black' stroke-width='1.17105' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
				background-size: contain;

			}
		}
	}
}
.m-tp-teaser002 .c-tp-headline {
	font-size: rem(18px) !important;
	font-weight: bold !important;
	text-transform: none;
}

.AboutCompany .m-tp-textblock,
.NewsMain .m-tp-textblock {
	padding-bottom: rem(10px);
	padding-left: rem(6px);
	padding-right: rem(50px);
}

.ProductsBlock .m-tp-textblock {
	padding-bottom: rem(25px);
	letter-spacing: 0.05em;
}
.m-home-nav {
	justify-content: center;
	gap: 0 rem(16px);
	padding: rem(46px) 0 rem(36px);
	&-link.c-tp-headline--arrow {
		display: flex;
		align-items: center;
		padding: rem(16px) rem(8px) rem(16px) rem(12px) !important;
		color: $text-main-gray;
		font-family: $alt-font;
		font-weight: 400;
		font-size: rem(20px);
		background: #F8F6F2;
		border: 1px solid #DFDDD9;
		border-radius: rem(10px) 0 rem(10px) 0;
		&::after {
			position: relative !important;
			width: rem(10px);
			height: rem(15px);
			margin: rem(2px) 0 0 rem(6px);
		}
	}
}

.m-about-qs {
	line-height: 1.429;
	font-weight: normal;
	margin: 0;
	color: $text-main-gray;
	padding: 0;
}

.m-about-qs-headline {
	display: table;
	table-layout: fixed;
	min-height: rem(256px);
	width: 100%;
	@include bg-image('/page-main/main-page-about.jpg');
	background-position: top left;
	background-repeat: no-repeat;
}

.m-about-qs-content {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.m-about-qs-headline .m-about-qs-years-switch {
	display: table-cell;
	position: relative;
	width: 20%;
	padding: 0;
	cursor: pointer;
	text-align: center;
	vertical-align: top;
}

.m-about-qs-headline .m-about-qs-years-switch::before {
	display: block;
	content: "";
	background-position: center center;
	background-repeat: no-repeat;
	height: rem(145px);
}

.m-about-qs-headline .m-about-qs-years-switch span {
	display: inline-block;
	margin: .5em;
	margin-top: 0;
	padding-bottom: .25em;
	border-bottom: solid transparent rem(2px);
	font-family: 'Roboto Condensed', Arial, sans-serif;
	font-size: 1.8rem;
	font-weight: bold;
	color: #373f44;
	text-align: center;
}

.m-about-qs-headline .m-about-qs-years-switch span:before {
	content: "";
	display: block;
	box-sizing: content-box;
	width: rem(32px);
	height: rem(32px);
	margin: rem(-17px) auto;
	margin-bottom: rem(12px);
	border: rem(1px) solid #ccc;
	border-radius: 50%;
	background-color: #ffffff;
	transition: all 0.2s;
	@include bg-image('icon_plus.png');
	background-size: 46%;
	background-position: center;
	background-repeat: no-repeat;
}

.m-about-qs-years-switch--visible {
	transition: all 1s;
}

.m-about-qs-headline .m-about-qs-years-switch--visible span:before {
	@include bg-image('js_tp_button_close-gold.svg');
	background-size: 38%;
	background-position: center;
	background-repeat: no-repeat;
	transition: all 0.2s;
}

.m-about-qs-headline .m-about-qs-years-switch--visible span {
	border-bottom: rem(2px) solid #837650;
	transition: all 1s;
}

.m-about-qs-descriptions {

	.copytext {
		padding-top: rem(10px);
		font-size: rem(24px) !important;
		font-weight: 300 !important;
		line-height: 1.33 !important;
		color: #373f44;
	}
}

.AboutSlider {
	.m-about-qs-descriptions {
		// min-height: rem(40px);
	}

	.m-tp-swiper-container .swiper-button-next,
	.m-tp-swiper-container .swiper-button-prev {
		width: rem(15px);
		height: rem(24px);
		background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat;
		background-size: cover;
		top: rem(173px);
		bottom: auto;
		right: rem(15px);
		overflow: visible;
	}

	.m-tp-swiper-container .swiper-button-next:before,
	.m-tp-swiper-container .swiper-button-prev:before {
		content: '';
		display: block;
		width: 300%;
		height: 300%;
		margin-top: rem(-23px);
		margin-left: rem(-14px);

	}

	.m-tp-swiper-container .swiper-button-next:hover,
	.m-tp-swiper-container .swiper-button-prev:hover {
		border: none;
	}

	.m-tp-swiper-container .swiper-button-prev {
		transform: rotate(180deg);
		right: none;
		left: rem(15px);
	}
}

.AboutSliderMobile {
	display: none;

	.m-about-qs-years-switch {
		display: block;
		margin: 0 auto;
		transition: all 1s;

		&:before {
			height: rem(170px);
		}

		span {
			font-size: 2rem;
			margin: 0;

			&:before {
				width: rem(50px);
				height: rem(50px);
				margin: rem(-27px) auto;
				margin-bottom: rem(5px);
			}
		}
	}

	.m-about-qs-descriptions {
		padding-right: rem(20px);
		padding-left: rem(20px);

		.copytext {
			font-size: rem(18px) !important;
		}
	}

	.m-tp-swiper-container .swiper-button-next,
	.m-tp-swiper-container .swiper-button-prev {
		top: 5.3em;
	}
}

.m-tp-teaser002 .c-tp-headline--arrow:after {
	margin-top: .15em
}

.m-tp-teaser002 {
	position: relative !important;
	padding: 0 0 0;
	width: 100%;
	overflow: hidden;
}

.m-tp-teaser002 .m-tp-teaser002-image {
	display: flex;
	align-items: center;
	justify-content: center;
	width: rem(135px);
	height: rem(100px);
	position: relative !important;
	margin-bottom: rem(10px);
}

.m-tp-teaser002 .c-tp-fleximage {
	width: 90%;
	margin: 0;
}

.m-tp-teaser002 .m-tp-teaser002-content {
	margin: 0;
	padding: 0 rem(2px) 0 rem(2px);
}

.m-tp-teaser002-headline {
	margin: 0 0 0;
	overflow: hidden;
}

.m-tp-teaser002-content:hover {
	opacity: 0.8;
}

.g-tp-column--2 .c-tp-fleximage {
	width: 100%;
}

.g-tp-column--3 .c-tp-fleximage {
	width: 87%;
}

.g-tp-column--4 .c-tp-fleximage {
	width: 87%;
}

.g-tp-column--5 .c-tp-fleximage {
	width: 80%;
	left: 10%;
	bottom: rem(15px);
}

.ProductSlider {
	display: none;

	.m-tp-teaser002 .m-tp-teaser002-image {
		width: 100%;
		height: rem(270px);
		@include bg-image('/page-main/main-page-product-bg-mobile.png');
		background-size: cover;
	}

	.m-tp-teaser002-content {
		padding-left: rem(28px);
	}

	.m-tp-swiper-container .swiper-button-next,
	.m-tp-swiper-container .swiper-button-prev {
		bottom: rem(198px);
	}

	.m-tp-swiper-container .swiper-slide img {
		width: auto;
		height: rem(200px);
		position: relative;
		left: auto;
		bottom: rem(-36px);
		margin: 0 auto;
	}

	.m-tp-teaser002 .c-tp-headline {
		font-size: rem(26px) !important;
	}
}

.NewsMain {
	margin-bottom: rem(45px);
}

.m-news-qs-headline {
	display: table;
	table-layout: fixed;
	min-height: rem(145px);
	width: 100%;
	//@include bg-image('/page-main/main-page-news-banner.jpg');
	background-size: inherit;
	background-position: center rem(-62px);
	background-repeat: no-repeat;
	padding-top: rem(38px);
	padding-right: rem(32px);
	padding-bottom: rem(29px);
	padding-left: rem(26px);
	position: relative;
}

.m-news-qs-headline .c-tp-headline {
	max-width: rem(276px);
	color: #f8f8f8 !important;
	font-size: rem(28px) !important;
	line-height: 1.29 !important;
	font-weight: normal !important;
}

.NewsMain .m-bannerMore-btn {
	position: absolute;
	right: rem(32px);
	bottom: rem(29px);
	left: auto;
}
