.news-block {
  position: relative;
  display: flex;
  align-items: stretch;
  margin-bottom: rem(80px);
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-50%);
    background: $beige;
    display: block;
    z-index: -1;
  }
  &-text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 52%;
    padding-top: rem(86px);
    padding-bottom: rem(86px);
    padding-left: rem(235px);
    padding-right: rem(60px);
    ._right & {
      padding-left: rem(80px);
      padding-right: rem(150px);
    }
    &::after {
      content: '';
      position: absolute;
      top: rem(-16px);
      left: rem(235px);
      transform: translateX(-100%);
      display: block;
      width: rem(78px);
      height: rem(78px);
    }
    .news-block--new & {
      &::after {
        @include bg-image('/page-news/new.svg');
      }
    }
    .news-block--promotion & {
      &::after {
        @include bg-image('/page-news/promotion.svg');
      }
    }
    p,li {
      &.d-flex {
        display: flex;
        align-items: flex-start;
        img {
          margin-right: rem(5px);
          margin-top: rem(3px);
          flex-shrink: 0;
        }
        &:not(:last-child) {
          margin-bottom: rem(38px);
        }
      }
      ._dark & {
        color: #E9D397;
      }
    }
    li {
      font-family: $alt-font-light;
      font-size: rem(16px);
      line-height: rem(22px);
      list-style: disc;
      list-style-position: inside;
      margin-bottom: 0 !important;
      &::marker {
        font-size: rem(10px);
      }
      &:not(:last-child) {
        margin-bottom: rem(20px);
      }
    }
  }
  &-date {
    margin-bottom: rem(10px);
    font-size: rem(16px);
    font-family: $alt-font-light;
    color: #9F9A97;
  }
  &-headline {
    margin-bottom: rem(35px);
    font-size: rem(36px);
    line-height: rem(40px);
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #000;
    ._dark & {
      color: #FFF;
    }
  }
  &-img {
    display: block;
    flex: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: rem(768px)) {
    flex-direction: column;
    &-date {
      font-size: rem(12px);
    }
    &-text {
      padding: rem(25px) rem(27px) rem(30px);
      p,li {
        font-size: rem(14px);
        line-height: 1.3;
        &:not(:last-child) {
          margin-bottom: rem(15px);
        }
      }
      &::after {
        top: rem(-26px);
        left: auto;
        right: rem(33px);
        transform: translateX(0);
        display: block;
        width: rem(56px);
        height: rem(56px);
      }
      .news-block--new & {
        &::after {
          @include bg-image('/page-news/new-reverse.svg');
        }
      }
      .news-block--promotion & {
        &::after {
          @include bg-image('/page-news/promotion-reverse.svg');
        }
      }
    }
    &-headline {
      margin-bottom: rem(15px);
      font-size: rem(24px);
      line-height: rem(27px);
    }
    &-img {
      height: rem(240px);
    }
  }
}