@mixin bg-image($image-name) {
  background-image: url('/img/' + $image-name);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

$default-font-size: 16px;
$defW: 1440px;

@function rem($size) {
  @return calc($size / $default-font-size * 1rem);
}
@function vh($num, $def: 800px) {
	@return calc(($num * 100 / $def) * 1vh);
}
