@media screen and (max-width: rem(768px)) {
    .Exclusive .productPage .Jumbotron .m-tp-singleimage img {
        height: rem(168px);
        width: 112% !important;
        object-fit: cover;
        object-position: -rem(50px);
        padding: 0;
    }   
    .productPage .Jumbotron .m-tp-singleimage img {
        height: rem(168px);
        width: 112% !important;
        object-fit: cover;
        object-position: -rem(50px);
        padding: 0;
    }
    .ChainStores .Jumbotron .m-tp-singleimage img {
        height: rem(168px);
        width: 100% !important;
        object-fit: cover;
        object-position: -rem(50px);
        padding: 0;
    }
    .productPage .Jumbotron .m-jumbotron-content-header--1 {
        font-size: rem(16px);
        font-weight: bold;
        font-stretch: condensed;
        line-height: 1.31;
        text-align: center;
        color: #002a5b;
    }
    .productPage .Jumbotron .m-jumbotron-content-line {
        width: 100%;
        height: rem(0px);
        border-top: rem(1px) solid transparent;
        border-color: #002a5c;
    }
    .productPage .Jumbotron .m-jumbotron-content-description--1 {
        font-size: rem(10px);
        font-weight: bold;
        font-stretch: condensed;
        line-height: 2.1;
        text-align: center;
        color: #002a5b;
    }
    .Exclusive .MainTitle {
        padding: 0 rem(19px);
        margin-bottom: rem(61px);
    }
    .Exclusive .m-tp-productbox-info .c-tp-headline--60 {
        font-size: rem(18px) !important;
        line-height: 2.4 !important;
        font-weight: bold !important;
        letter-spacing: rem(0.3px);
        margin: 0;
        margin-bottom: -rem(20px);
    }
    .Exclusive .ItemBanner {
        margin-bottom: rem(20px);
    }
    .productPage .m-tp-productbox-info .m-tp-productbox-description {
        font-size: rem(18px) !important;
        line-height: 2.4 !important;
    }
    .productPage .c-tp-intensity {
        letter-spacing: 0;
    }
    .productPage .m-tp-productbox-image {
        text-align: center;
        text-align: -webkit-center;
    }
    .Suggestions .m-tp-productbox-image {
        text-align: center;
        text-align: -webkit-center;
    }
    .productPage .m-tp-productbox-info {
        width: auto !important;
    }
    .productPage .m-tp-productbox-info .m-tp-productbox-description {
        margin-bottom: rem(17px);
    }
    .Exclusive .Suggestions .tp-component-productlistitem {
        margin-bottom: rem(18px);
    }
    .Exclusive .Suggestions .tp-component-productlistitem .m-tp-productbox-description {
        min-height: auto;
        margin-bottom: rem(19px);
    }
    .Exclusive .ItemBanner {
        margin-bottom: -rem(13px); 
    }
    .ItemBanner--2 .m-tp-mag1-image-hero img {
        width: 100%;
        height: auto !important;
    }
    .ItemBanner--2 .m-tp-mag1-image-hero {
        position: initial;
        right: 0;
    }
    .productPage .ItemBanner--2 .m-tp-mag1 .m-tp-mag1-image-hero {
        width: 100%;
        height: auto;
        margin-bottom: rem(38px);
    }    
    .ChainStores .Jumbotron .m-jumbotron-content {
        width: rem(250px) !important;
        position: absolute !important;
        top: rem(50px) !important;
        left: rem(0px) !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .ChainStores .Jumbotron .m-jumbotron-content-header--1 {
        font-size: rem(20px) !important;
        font-weight: bold !important;
        font-stretch: condensed !important;
        line-height: 1.05 !important;
        text-align: center !important;
        color: #ffffff !important;
        padding: rem(6px) !important;
    }
    .productPage .Jumbotron {
        margin-bottom: rem(58px);
    }
    .productPage .MainTitle .c-tp-headline--120 {
        font-size: rem(32px) !important;
        font-weight: 300 !important;
        font-stretch: condensed !important;
        line-height: 0.75 !important;
        text-align: center !important;
        color: #373f44;
    }
    .Exclusive .MainTitle {
        padding: 0 rem(19px);
        margin-bottom: rem(0px);
    }
    .m-tp-productbox-image--custom {
        background-repeat: no-repeat;
        background-position: center !important;
        background-size: contain;
        height: rem(340px)!important;
    }
    .m-tp-productbox-image--custom-1 {
        background-repeat: no-repeat;
        background-position: center !important;
        background-size: contain;
        height: rem(472px)!important;
    }
}    