.Expertise {
	position: relative;
	&::before {
		content: '';
		width: 100%;
		height: rem(328px);
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		background: #423C39;
		z-index: -1;
	}
}

@media screen and (max-width: rem(768px)) {
	.Expertise {
		&::before {
			height: 56%;
		}
		.Banners {
			.g-tp-row {
				display: flex;
				flex-direction: column;
				overflow-x: hidden;
			}
		}
	}
}

