.News {
    .Jumbotron {
        position: relative;

        .m-jumbotron-content {
            max-width: rem(377px);
            position: absolute;
            top: rem(41px);
            left: rem(45px);
        }

        .m-jumbotron-content-line {
            width: 100%;
            height: rem(2px);
            background-color: #ffffff;
        }
        .m-jumbotron-content-header {
            font-weight: bold !important;
            color: #fff !important;
            line-height: rem(47px) !important;
            text-transform: uppercase;
            padding-top: rem(8px);
            padding-bottom: rem(13px);
        }
        .m-jumbotron-content-header--1 {
            font-size: rem(32px);
            font-weight: bold;
            line-height: 1.31;
            color: #fff;
            text-transform: uppercase;
            letter-spacing: rem(0.7px);
            margin-top: rem(5px);
            margin-bottom: rem(5px);
        }
        .m-jumbotron {
            img {
                height: rem(254px);
            }
        }
    }
    .Newslist {

        .m-newsItem {
            position: relative;

            .c-tp-headline--span {
                top: -rem(15px);
                left: rem(2px);
                position: relative;
                color: #373f44;
                font-size: rem(14px);
                font-stretch: condensed;
                line-height: 2;
                text-align: left;
                color: #373f44;
            }
            &-content {
                max-width: rem(551px);
                position: absolute;
                top: rem(44px);
                left: rem(48px);

                &-header {
                    font-size: rem(32px) !important;
                    line-height: rem(40px) !important;
                    color: #373f44 !important;
                    text-transform: uppercase;
                    font-weight: bold !important;
                    margin-top: -rem(1px);

                    &-red {
                        color: #d0011b !important;
                        margin-top: -rem(10px);
                    }
                }

                &-description {
                    max-width: 98%;
                    font-size: rem(22px) !important;
                    line-height: rem(28px) !important;
                    color: #373f44 !important;
                    margin-top: rem(24px);
                }
            }

            img {

            }
        }

        .g-tp-row-3 {
            .m-newsItem {
                &-content {
                    &-description {
                        max-width: 75%;
                    }
                }
            }
        }
    }
}

.m-tp-singleimage img.img-mobile {
    display: none;
}

@media screen and (max-width: rem(768px)) {
    .News {
        .Newslist {
            padding-top: rem(0px);

            .m-newsItem {

                &-content {
                    left: 0;
                    top: rem(8px);
                    margin: 0 auto;
                    width: 90%;

                    &-header {
                        font-size: rem(28px) !important;
                    }

                    &-description {
                        max-width: 100%;
                        font-size: rem(18px) !important;
                        margin-top: 0;
                    }
                }

                img {

                }
            }

            .g-tp-row-3 {
                .m-newsItem {
                    &-content {
                        &-description {
                            max-width: 75%;
                        }
                    }
                }
            }
        }
    }

    .m-tp-singleimage img.img-desk {
        display: none;
    }

    .m-tp-singleimage img.img-mobile {
        display: block;
    }
}
@media screen and (max-width: rem(420px)) {}
