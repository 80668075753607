html {
  &.popup-opened {
    overflow: hidden;
  }
}
body {
  .popup-opened & {
    overflow: hidden;
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 99;
  display: none;
  background: rgba(0,0,0, .5);
  overflow-y: auto;
  overflow-x: hidden;
  @supports (-ms-ime-align:auto) {
    overflow-x: hidden;
  }
  ._desktop {
    @media screen and (max-width: rem(768px)) {
      display: none;
    }
  }
  &__inner {
    background: #fff;
  }
  &__window {
    width: 100%;
    max-height: 100vh;
    max-width: rem(784px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    @media screen and (max-width: rem(768px)) {
      position: relative;
      transform: none;
      max-width: calc(100vw - #{rem(40px)});
      margin-left: rem(20px);
      top: auto;
      left: auto;
      margin-top: rem(50px);
      min-height: 100vh;
    }
  }
  &__close {
    position: absolute;
    top: rem(15px);
    right: rem(15px);
    z-index: 5;
    width: rem(19px);
    height: rem(19px);
    cursor: pointer;
    background: url('/img/cross.svg') 0 0 no-repeat;
    background-size: contain;
  }
  &__logo {
    width: rem(69px);
    height: rem(68px);
    background: url('/img/msg.svg') 0 0 no-repeat;
    background-size: contain;
    margin: 0 auto rem(11px);
    @media screen and (max-width: rem(768px)) {
      width: rem(38px);
      height: rem(38px);
      margin: 0 auto rem(5px);
    }
  }
  &__heading {
    font-size: rem(32px);
    line-height: rem(45px);
    font-weight: 300;
    font-family: $main-font;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: rem(30px);
    @media screen and (max-width: rem(768px)) {
      margin-bottom: rem(13px);
      font-size: rem(18px);
      line-height: rem(25px);
    }
  }
  &__content {
    width: 100%;
    padding: rem(31px) rem(135px) 0;
    @media screen and (max-width: rem(768px)) {
      padding: rem(31px) rem(25px) 0;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: rem(768px)) {
      flex-direction: column;
    }
  }
  &__text {
    font-weight: 300;
    font-size: rem(14px);
    line-height: rem(19px);
    @media screen and (max-width: rem(768px)) {
      font-size: rem(10px);
      line-height: rem(14px);
      font-family: $alt-font-light;
    }
    &--center {
      text-align: center;
    }
    &--big {
      font-family: $alt-font-light;
      font-size: rem(20px);
      line-height: rem(30px);
      padding-bottom: rem(50px);
      @media screen and (max-width: rem(768px)) {
        font-size: rem(12px);
        line-height: 1.1;
      }
    }
  }
  &__list {
    padding: 0;
    &-item {
      list-style-type: disc;
      list-style-position: inside;
      font-weight: 300;
      font-size: rem(14px);
      line-height: rem(19px);
      @media screen and (max-width: rem(768px)) {
        font-size: rem(10px);
        line-height: rem(14px);
        font-family: $alt-font-light;
      }
    }
  }
  &__img {
    display: block;
    &--1 {
      width: rem(277px);
      height: auto;
      @media screen and (max-width: rem(768px)) {
        width: 100%;
        margin-top: rem(10px);
      }
    }
  }
  &__tip {
    color: #858585;
    font-weight: 300;
    font-family: $alt-font-light;
    font-size: rem(12px);
    line-height: rem(20px);
    margin-top: rem(41px);
    @media screen and (max-width: rem(768px)) {
      margin-top: rem(10px);
      font-size: rem(10px);
    }
  }
  &__step {
    position: relative;
    padding-bottom: rem(20px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    &.active {
      opacity: 1;
      pointer-events: auto;
      position: relative;
      transition: opacity .3s ease-in-out;
    }
    &--1 {
      padding-bottom: rem(20px);
      margin-top: rem(22px);
      .form__btns {
        margin-top: rem(51px);
      }
      @media screen and (max-width: rem(768px)) {
        padding-bottom: rem(33px);
      }
    }
    &--3 {
      padding-bottom: rem(74px);
      .form__btns {
        margin-top: rem(33px);
      }
      @media screen and (max-width: rem(768px)) {
        padding-bottom: rem(33px);
      }
    }
    &--4 {
      padding-bottom: rem(64px);
      .form__btns {
        margin-top: rem(53px);
      }
      @media screen and (max-width: rem(768px)) {
        padding-bottom: rem(33px);
        .form__btns {
          margin-top: rem(24px);
        }
      }
    }
    &-title {
      line-height: rem(30px);
      font-size: rem(26px);
      font-weight: 300;
      margin-bottom: rem(15px);
      @media screen and (max-width: rem(768px)) {
        margin-bottom: rem(6px);
        font-size: rem(12px);
        line-height: rem(20px);
        font-family: $alt-font-light;
      }
    }
    .c-tp-simplebutton {
      width: rem(237px);
      @media screen and (max-width: rem(768px)) {
        width: rem(175px);
      }
      &[disabled="disabled"] {
        opacity: 0.5;
        pointer-events: none;
      }
      .c-tp-simplebutton-text {
        @media screen and (max-width: rem(768px)) {
          padding-left: rem(20px);
          padding-right: rem(20px);
        }
      }
    }
  }
  &__counter {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: rem(46px);
    @media screen and (max-width: rem(768px)) {
      margin-bottom: rem(26px);
    }
    &-item {
      position: relative;
      width: rem(36px);
      height: rem(36px);
      border-radius: 50%;
      color: #fff;
      background: #D8D8D8;
      text-align: center;
      line-height: rem(36px);
      font-size: rem(15px);
      font-weight: 600;
      @media screen and (max-width: rem(768px)) {
        width: rem(21px);
        height: rem(21px);
        font-size: rem(8px);
        line-height: rem(21px);
      }
      &.active {
        background: #C0A468;
      }
      &.done {
        background: #E4CB94;
      }
      & + .popup__counter-item {
        margin-left: rem(45px);
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: rem(-45px);
          width: rem(45px);
          height: rem(1px);
          background: #D8D8D8;
        }
        @media screen and (max-width: rem(768px)) {
          margin-left: rem(26px);
          &::before {
            width: rem(26px);
            left: rem(-26px);
          }
        }
      }
    }
  }
  &-accordion {
    &.active {
      .popup-accordion__icon::after {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    &__top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: rem(27px);
    }
    &__icon {
      position: relative;
      width: rem(22px);
      height: rem(22px);
      border-radius: rem(4px);
      margin-left: rem(12px);
      background: #C0A468;
      &::after, &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%);
        width: rem(11px);
        height: rem(2px);
        background: #fff;
        transition: transform .3s ease-in-out;
      }
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
      @media screen and (max-width: rem(768px)) {
        width: rem(14px);
        height: rem(14px);
        border-radius: rem(2px);
        &::after, &::before {
          width: rem(7px);
          height: rem(1px);
        }
      }
    }
    &__title {
      line-height: rem(20px);
      font-size: rem(16px);
      font-family: $alt-font-light;
      font-weight: 300;
      @media screen and (max-width: rem(768px)) {
        font-size: rem(10px);
      }
    }
    &__content {
      display: none;
      position: relative;
      padding: rem(53px) rem(135px);
      margin: 0 rem(-135px) rem(40px);
      background: $beige;
      @media screen and (max-width: rem(768px)) {
        padding: rem(23px) rem(26px);
        margin: 0 rem(-25px) rem(20px);
      }
      .form__field {
        &:last-child {
          margin-bottom: 0;
        }
        .field__input {
          background: #fff;
        }
      }
    }
  }
}
