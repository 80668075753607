.bottomBoxFull {

}
.l-tp-footer {
  background: #fff;
  &-logo {
    height: rem(37px);
  }
  .footer-accordion {
    display: none;
  }
  .g-tp-flex {
    overflow: visible;
  }
}
.l-tp-footer .g-tp-column--first .m-tp-base-column {
    // padding-left: rem(0px);
}
.l-tp-footer .g-tp-column--last {
  margin-top: auto;
  margin-left: auto;
  flex: 0 0 auto !important;
  overflow: visible;
  padding-bottom: rem(27px);
}
.l-tp-footer .m-tp-base-column {
  padding: 0 rem(3px);
}
.l-tp-footer .m-tp-base {
  padding-bottom: rem(25px);
}
.l-tp-footer-gray {
    background: $beige;
    padding-top: rem(60px);
    padding-bottom: rem(35px);
}
.l-tp-footer-content {
    width: rem(984px);
    margin: 0 auto;
}
.l-tp-footer-content .g-tp-column--first {
    position: relative;
    flex: 0 0 17%;
}
.m-linklist {
    list-style: none;
    margin: 0;
    padding: 0;
    // padding-left: rem(12px);
    font-size: rem(14px);
    font-weight: 400;
    font-family: $alt-font;
    line-height: 1.4;
}
.c-tp-headline+.m-linklist {
    // padding-left: rem(12px);
    margin-top: rem(15px);
}
.l-tp-footer .g-tp-column--first .c-tp-headline + .m-linklist {
  padding-left: 0;
}
.m-linklist-item {
    margin-bottom: rem(4px);
}
.l-tp-footer .m-tp-base-column--service {
  // padding-left: 0;
}
.l-tp-footer .m-tp-base-column--service .c-tp-headline {
  font-size: rem(18px);
  font-family: $alt-font-light;
}
.l-tp-footer .m-tp-base-column--range {
  padding-left: rem(27px);
  padding-right: 0;
}
.l-tp-footer .m-tp-base-column--range .c-tp-headline {
  font-size: rem(18px);
  font-family: $alt-font-light;
}
.m-linklist-link {
    text-decoration: none;
    color: $light-grey;
    position: relative;
    padding-left: rem(18px);
    display: inline-block;
}
.m-linklist-link:hover {
  text-decoration: underline;
}
.m-linklist-link::before {
    font-family: tp-icon;
    font-style: normal;
    speak: none;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    left: rem(0px);
    top: rem(2px);
    content: "";
    font-size: rem(11px);
    font-weight: bold;
    text-decoration: none;
}
.m-sociallinks {
    float: left;
    margin-top: .7145em;
    margin-right: rem(24px);
}
.m-sociallinks-item {
    float: left;
    margin-right: rem(19px);
}
.m-sociallinks-link {
    width: rem(35px);
    height: rem(35px);
    text-decoration: none;
    display: block;
    position: relative;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-color: #ffffff;
}
.m-sociallinks-link::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}
.m-sociallinks-link--facebook::before {
  width: rem(9px);
  height: rem(19px);
  @include bg-image('icons/icon-facebook.svg');
  margin-left: rem(-4px);
  margin-top: rem(-9px);
}
.m-sociallinks-link--facebook:hover::before {
  @include bg-image('icons/icon-facebook-hover.svg');
}
.m-sociallinks-link--vk::before {
  width: rem(19px);
  height: rem(11px);
  @include bg-image('icons/icon-vk.svg');
  margin-left: rem(-9px);
  margin-top: rem(-5px);
}
.m-sociallinks-link--vk:hover::before {
  @include bg-image('icons/icon-vk-hover.svg');
}
.m-sociallinks-link--odnoklassniki::before {
  width: rem(12px);
  height: rem(20px);
  @include bg-image('icons/icon-odnoklassniki.svg');
  margin-left: rem(-6px);
  margin-top: rem(-10px);
}
.m-sociallinks-link--odnoklassniki:hover::before {
  @include bg-image('icons/icon-odnoklassniki-hover.svg');
}
.m-sociallinks-link--instagram::before {
  width: rem(17px);
  height: rem(17px);
  @include bg-image('icons/icon-instagram.svg');
  margin-left: rem(-8px);
  margin-top: rem(-9px);
}
.m-sociallinks-link--instagram:hover::before {
  @include bg-image('icons/icon-instagram-hover.svg');
}
.m-sociallinks-link--youtube::before {
  width: rem(19px);
  height: rem(23px);
  @include bg-image('icons/icon-youtube.svg');
  margin-left: rem(-9px);
  margin-top: rem(-12px);
}
.m-sociallinks-link--youtube:hover::before {
  @include bg-image('icons/icon-youtube-hover.svg');
}
.m-metanav-list--footer {
  float: left;
  .m-metanav-item {
    position: relative;
    font-size: rem(14px);
    margin-left: 0;
    margin-bottom: 0;

    &:last-of-type {
      margin-left: rem(28px);
    }
  }
  .m-metanav__popup {
    top: auto;
    bottom: 100%;
  }
  .m-metanav-link {
    position: relative;
    display: flex;
    align-items: center;
    color: $light-grey;
    font-weight: 400;
    font-size: rem(14px);
    padding-left: rem(0px);
    letter-spacing: 0.02em;
    position: relative;
  }

  .m-metanav-link:hover {
    opacity: 0.8;
  }
  .m-metanav-link--contact::before {
    content: '';
    width: rem(28px);
    height: rem(25px);
    margin-top: rem(3px);
    margin-right: rem(6px);
    margin-bottom: 0;
    @include bg-image('icons/icon-contacts-black.svg');
  }
  .m-metanav-link--buyonline::before {
    content: '';
    width: rem(25px);
    height: rem(28px);
    margin-right: rem(10px);
    position: relative;
    left: 0;
    top: 0;
    @include bg-image('icons/icon-buyonline-black.svg');
  }
}
.l-tp-footer .c-tp-separatorline {
  padding-bottom: rem(12px);
}
.m-copyrights {
  color: #64696D;
  font-family: $alt-font;
  font-size: rem(14px) !important;
  float: right;
  margin: rem(8px) 0 auto auto;
}
.m-tchibointernational {
    background: #ffffff;

    select {
      font-family: $main-font !important;
      font-size: rem(16px) !important;
      font-weight: 300 !important;
      padding-top: rem(4px);
      padding-bottom: rem(6px);
      padding-left: rem(16px);
    }
}
.l-tp-footer .m-tp-base .c-tp-headline+.m-tchibointernational {
  margin-top: rem(8px);
}
.c-tp-formelement-select-container:after {
  background-color: #ffffff;
  right: rem(6px);
}
.l-tp-footer .g-tp-column--international .m-tp-base {
  padding-bottom: rem(14px);
}
.m-flags {

  &-list {

  }

  &-item {
    float: left;
    margin-left: rem(11px);

    &:first-of-type {
      margin-left: 0;
    }
  }

  &-link {
    display: block;
    width: rem(51px);
    font-size: rem(16px) !important;
    color: $light-grey;
    padding-left: rem(32px);
    position: relative;

    &:before {
      content: '';
      width: rem(26px);
      height: rem(15px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: rem(3.6px);
      left: rem(1px);
    }

    &--de {
      
      &:before {
        background-image: url('/img/icon-de.png');
      }
    }
    &--cz {
      
      &:before {
        background-image: url('/img/icon-cz.png');
      }
    }
    &--ch {
      
      &:before {
        background-image: url('/img/icon-ch.png');
      }
    }
    &--hu {
      
      &:before {
        background-image: url('/img/icon-hu.png');
      }
    }
    &--pl {
      
      &:before {
        background-image: url('/img/icon-pl.png');
      }
    }
    &--sk {
      
      &:before {
        background-image: url('/img/icon-sk.png');
      }
    }
    &--at {
      
      &:before {
        background-image: url('/img/icon-at.png');
      }
    }
    &--tr {
      
      &:before {
        background-image: url('/img/icon-tr.png');
      }
    }
    
  }
}
.g-tp-row--footerBottom {
  .c-tp-headline--70 {
    margin-bottom: rem(6px);
  }
}
@media screen and (max-width: rem(768px)) { 
  .l-tp-footer {
    &-content {
      width: 100%;
      .g-tp-column  {
        width: 100%;
      }
      .g-tp-row {
        margin: 0;
      }
    }
    .footer-nav-desk {
      display: none;
    }
    .footer-accordion {
      display: block;
      ul {
        display: none;
      }
    }
    .g-tp-flex {
      flex-direction: column;
      align-items: flex-start;
    }
    .c-tp-separatorline {
      max-width: rem(708px);
      padding-bottom: rem(28px);
      margin: 0 auto;
    }
    .l-tp-footer-logo {
      margin-bottom: rem(8px);
    }
    // .g-tp-column--half {
    //   width: 40%;
      
    //   &:first-of-type {
    //     width: 60%;
    //   }
    // } 
    .m-tp-base {
      padding-bottom: rem(16px);
    }
    .m-flags-item {
      margin-left: 13%;
      margin-bottom: rem(10px);

      &:nth-of-type(5n), &:first-of-type {
        margin-left: 0;
      }
    }
    .m-tp-base-column--range {
      padding-left: 0;
    }
  }
  
  .l-tp-footer-gray {
    padding-right: rem(28px);
    padding-left: rem(28px);
  }
}
@media screen and (max-width: rem(768px)) {
  .l-tp-footer-content {
    padding: 0 rem(12px);

    .g-tp-column--quarter {
      width: 48%;
      padding-top: rem(7px);

      &:first-of-type {
        width: 52%;
      }

      
    }
    .c-tp-headline + .m-linklist {
        padding-left: rem(8px);
    }
    
    .m-flags {
      margin-top: rem(5px);
      margin-bottom: rem(15px);
    }
    .m-flags-item {
      margin-left: 10% !important;
    }
    .m-tp-base-column {
      padding: 0;
      text-align: left;
    }
    .c-tp-headline {
      max-width: 100%;
      line-height: 1.1;
    }
    .g-tp-column--quarter .c-tp-headline {
      padding-left: rem(31px);
    }
    .g-tp-column--first .c-tp-headline {
      // padding-left: rem(22px);
    }
    .c-tp-headline::before {
      font-family: tp-icon;
      font-style: normal;
      speak: none;
      text-transform: none;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      right: rem(0px);
      top: rem(0px);
      transform: rotate(90deg);
      content: "";
      font-size: rem(17px);
      font-weight: bold;
      text-decoration: none;
    }
    .m-linklist-link {
      padding-left: rem(20px);
    }
    .c-tp-headline + .m-linklist {
      margin-bottom: rem(20px);
    }
    .g-tp-column--half {
      width: 100%;
    }
    .g-tp-column--last {
      margin-left: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    // .m-tchibointernational, .c-tp-separatorline {
    //   margin-bottom: rem(12px);
    // }
    .m-sociallinks {
      float: none;
      margin-top: rem(16px);
    }
    .m-metanav-list--footer {
      display: flex;
      width: 100%;
      float: none;
      margin-top: rem(25px);
      margin-left: 0;
      margin-bottom: rem(14px);
      .m-metanav-link {
        padding-left: 0px;
        font-family: $alt-font;
      }
      .m-metanav-link--contact::before {
        width: rem(20px);
      }
      .m-metanav-link--buyonline::before {
        top: 0;
        width: rem(17px);
      }
    }
    .m-metanav-item:last-child {
      margin-left: auto;
    }
    .m-copyrights {
      width: 100%;
      float: none;
      text-align: left;
      margin-top: rem(5px);
    }
  }
  .l-tp-footer-gray {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: rem(18px);
  }
}
// @media screen and (max-width: rem(375px)) {
//   .l-tp-footer-content .m-flags-item {
//     margin-left: 15%;
//   }
// }

// @media screen and (max-width: rem(320px)) {
//   .l-tp-footer-content .m-flags-item {
//     margin-left: 10%;
//   }
// }
@media screen and (max-width: rem(375px)) {
.l-tp-footer-content .m-flags-item {
    margin-left: 10% !important;
  }
}
@media screen and (max-width: rem(768px)) and (min-width: rem(375px)) {
  .l-tp-footer-content .m-flags-item {
    margin-left: 18%;
  }
}
