@media screen and (max-width: rem(768px)) {
  .ontactsPage {
    .Jumbotron .m-jumbotron img {
        height: auto;
        width: 100%;
    }
    .Jumbotron .m-jumbotron-content {
        max-width: rem(142px);
        position: absolute;
        top: rem(30px);
        left: rem(19px);
    }
    .Jumbotron .m-jumbotron-content-header--1 {
        font-size: rem(20px);
        font-weight: bold;
        font-stretch: condensed;
        line-height: 1.05;
        text-align: center;
        color: #ffffff;
        margin-top: rem(11px);
        margin-bottom: rem(13px);
    }
    .match_group_1 {
        span.c-tp-headline--120 {
            font-size: rem(20px);
            font-weight: 300;
            font-stretch: condensed;
            line-height: 1.5;
            text-align: left;
            color: #373f44;
        }
        .tp-component-productlistitem .m-metanav-link--contact-custom--3:after {
            display: none;
        }
        .tp-component-productlist {
            margin-top: rem(9px);
            padding: 0 rem(5px);
        }
        .tp-component-productlistitem {
            margin-bottom: rem(22px);
        }
    }
  }
}
