.l-header-meta {
  .h-gold.navItem a {
    color: #857650;
  }

  .h-gold.navItem a:hover::after {
    background-color: #857650;
  }

  .h-gold .active_menu:after {
    content: '';
    width: calc(100% - rem(50px));
    background-color: #857650;
    height: rem(1px);
    position: absolute;
    bottom: 0;
    left: rem(26px);

    &:hover {
      .h-gold .active_menu:after {
        content: '';
        width: calc(100% - rem(50px));
        background-color: rgba(133, 118, 80, 0.32);
        height: rem(1px);
        position: absolute;
        bottom: 0;
        left: rem(26px);
      }
    }
  }

  .active_menu:after {
    content: '';
    width: calc(100% - rem(50px));
    background-color: #373f44;
    height: rem(1px);
    position: absolute;
    bottom: 0;
    left: rem(26px);

    &:hover {
      .active_menu:after {
        content: '';
        width: calc(100% - rem(50px));
        background-color: rgba(55, 63, 68, 0.32);
        height: rem(1px);
        position: absolute;
        bottom: 0;
        left: rem(26px);
      }
    }
  }
}





.headerWrapper {
  position: relative;
  min-width: rem(1010px);
  z-index: 11;
}

.js-tp-simplelist-item {
  position: absolute;
  top: rem(100px);
  left: rem(20px);
  width: rem(200px);
  height: rem(20px);
  background-color: pink;
}

.l-header {
  position: relative;
  z-index: 1000;
  background-color: #fff;
  padding-bottom: rem(5px);
}

.l-header-main {
  position: relative;
  background-color: $beige;

}

.l-header-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-top: rem(40px);
}
.l-header-width {
  width: rem(1300px);
  max-width: 100%;
  margin: 0 auto;
}

.c-hamburger {
  display: none;
  width: rem(25px);
  height: rem(18px);
  float: left;
  margin-top: rem(16px);
  // margin-left: rem(10px);
}

.c-hamburger-btn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  padding: 0;
}

.c-hamburger-line {
  display: block;
  width: 100%;
  height: rem(3px);
  transform-origin: left;
  background: $main-gray;
}

.c-hamburger-line:last-child {
  margin-bottom: rem(5px);
}

.l-logo {
  text-indent: rem(-99999px);
  float: left;
  position: relative;
  z-index: 1;
  @include bg-image('logo.svg');
  overflow: hidden;
  display: block;
  width: rem(118px);
  height: rem(43px);
  margin-right: rem(45px);
  margin-bottom: rem(29px);
}

.l-header-meta {
  min-height: rem(49px);
}

.m-metanav {
  position: relative;
  font-size: rem(16px);
  // margin-bottom: rem(20px);
  margin-left: auto;
  color: #373f44;
  float: right;

  &__popup {
    display: none;
    position: absolute;
    top: rem(30px);
    right: 0;
    justify-content: flex-end;
    z-index: 10;
    &-container {
      position: relative;
      right: rem(-6px);
      width: rem(130px);
      padding: rem(10px) rem(10px) rem(10px) rem(16px);
      background: #FFF;
      line-height: rem(28px);
      box-shadow: 0px -3px 14px 0px rgba(66, 60, 57, 0.24);
    }
    &-link {
      display: block;
      font-family: $alt-font;
      font-size: rem(14px);
      line-height: rem(24px);
      color: #64696D;
      &:hover {
        opacity: 0.7;
      }
      &>img {
        height: 100%;
        border: none;
      }

    }
    &-item {
      &:not(:last-child) {
        margin-bottom: rem(7px);
      }
    }
  }
}

.m-metanav-item {
  float: left;
  margin-left: rem(14px);
  margin-bottom: rem(25px);
}

.m-metanav-link {
  display: flex;
  color: #000;
  padding-left: rem(25px);
  font-size: rem(18px);
  font-family: $alt-font-light;
  line-height: 1.3;
  position: relative;
  cursor: pointer;
}

.m-metanav-link:hover {
  opacity: 0.8;
}

.m-metanav-link--contact::before {
  content: '';
  display: block;
  margin-bottom: rem(3px);
  width: rem(24px);
  height: rem(22px);
  @include bg-image('icons/icon-pin.svg');
}

.m-metanav-link--buyonline::before {
  content: '';
  width: rem(20px);
  height: rem(22px);
  @include bg-image('icons/icon-buyonline.svg');
  position: absolute;
  left: rem(0px);
  top: rem(-3px);
}

.m-metanav-link--faq::before {
  content: '';
  width: rem(24px);
  height: rem(20px);
  @include bg-image('icons/icon-faq.png');
  position: absolute;
  left: rem(4px);
  top: rem(10px);
}

.l-header-action {
  position: relative;
  clear: right;
}

.m-searchbar {
  padding: rem(4px) 0 0 rem(198px);
}

.m-searchbar-form {
  position: relative;
}

.headerSearchBox {
  width: rem(403px);
  position: relative;
  border: rem(1px) solid #d9d9d9;
  border-radius: rem(4px);
  overflow: hidden;
}

.headerSearchBox fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.headerSearchKeywords {
  height: rem(45px);
  font-size: rem(16px);
  width: 100%;
  padding: 0 0 0 rem(20px);
  background-color: transparent;
  line-height: rem(43px);
  border: 0;
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif;
}

.headerSearchButtonSubmit {
  width: rem(45px);
  height: rem(45px);
  text-indent: -9999em;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-left: rem(1px) solid #d9d9d9;
  border-bottom-right-radius: rem(4px);
  border-top-right-radius: rem(4px);
  @include bg-image('icons/icon-search.png');
  background-size: 60%;
}

.block-title-price>a {
  transition: all 1s ease;
}

.block-title-price>a:hover {
  text-decoration: none;
  color: #6b6765;
  transition: all 1s ease;
}

.m-navigation {
  clear: left;
  height: rem(30px);
}

.navLayer {
  position: static !important;
}

.mainNav {
  // padding-bottom: rem(7px);
  .navItem {
    margin-bottom: 0;
  }
}

.navItem {
  float: left;
  position: relative;
}

.navItem a,
.navItem span.switchOnHover {
  position: relative;
  display: block;
  padding: rem(1px) 0 rem(25px);
  margin-right: rem(32px);
  font-size: rem(20px);
  line-height: rem(20px);
  letter-spacing: 0.006em;
  color: $text-main-gray;
  font-family: $alt-font;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.navItem a.active,
.navItem.opened a,
.navItem.opened span,
.navItem a:hover,
.navItem span:hover,
.navItem span.active {
  text-shadow: 0.5px 0.5px 0px $text-main-gray;
}
.navItem a.active::after,
.navItem a:hover::after,
.navItem.opened span::after,
.navItem.opened a::after,
.navItem span.switchOnHover:hover::after {
  content: '';
  width: 100%;
  background-color: $text-main-gray;
  height: rem(3px);
  position: absolute;
  bottom: 0;
  left: 0;
}

// .active_menu--bottom:after {
//   content: '';
//   width: calc(100% - rem(50px));
//   background-color: #373f44;
//   height: rem(1px);
//   position: absolute;
//   bottom: 0;
//   left: rem(26px);
// }

.h-gold.navItem a:hover::after {
  background-color: #857650;
}

.navItem-break {
  clear: left;
}

.mobile-menu {
  display: none;
  position: absolute;
  width: 100%;
  background: $beige;
  z-index: 10;
  top: 0;
  &__close {
    position: absolute;
    display: block;
    top: rem(133px);
    right: rem(41px);
    width: rem(21px);
    height: rem(21px);
    @include bg-image('/icons/icon_close.svg');
    cursor: pointer;
    z-index: 100;
  }
  &__content {
    padding: rem(166px) rem(136px) rem(100px);
    width: 100%;
    position: relative;
    display: flex;
    z-index: 2;

    .mainNav {
      width: 100%;
      padding: 0 rem(37px);
      margin: 0 0 rem(14px);
      border-bottom: 1px solid #A6A9AC;
    }

    .navItem {
      float: none;
      margin-bottom: rem(26px);
      // border-bottom: rem(1px) solid #e9e9e9;
      background: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 1L9 9L1 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat top rem(13px) right;
    }
    .no-arrow {
      display: none;
    }
    .navItem ul {
      display: none;
      padding-bottom: rem(15px);
      li {
        margin-bottom: rem(5px);
      }
      a {
        font-size: rem(12px);
        line-height: 1.3;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .navItem a,
    .navItem span.switchOnHover {
      font-size: rem(16px);
      padding-bottom: 0;
      line-height: rem(18px);
      font-family: $alt-font;
      color: $main-gray;
    }

    .navItem a.active::after,
    .navItem a:hover::after,
    .navItem span.switchOnHover:hover::after {
      content: none;
    }
  }
  .menu {
    flex: 0 0 40%;
    padding-right: rem(115px);
  }
  .menu-blocks {
    display: flex;
    flex-wrap: wrap;
    gap: rem(26px) rem(35px);
  }
  .menu-block {
    display: flex;
    flex-direction: column;
    width: rem(280px);
    color: #FFF;
    font-size: rem(18px);
    font-family: $alt-font;
    span {
      display: block;
      margin-bottom: rem(16px);
    }
    img {
      max-height: 25vh;
      object-fit: cover;
    }
  }
}

/*-----block-input-search-----*/
#block-search {
  margin: 0 auto;
  width: rem(423px);
  height: rem(33px);
}

// Поле поиска
#input-search {
  height: rem(29px);
  width: rem(355px);
  border: rem(1px) solid #D8D8D8;
  padding-left: rem(5px);
  padding-right: rem(5px);
}

// Изменение цвета фокуса при вводе текста.
#input-search:focus {
  border: rem(1px) solid #75D9FF;
}

// Кнопка
#form-search {
  background: url(button-search-norm.png) no-repeat;
  width: rem(54px);
  height: rem(33px);
  border: none;
  cursor: pointer;
  position: absolute;
  margin-left: rem(-5px);

}

// Кнопка по наведению
#form-search:hover {
  background: url(button-search-hover.png) no-repeat;
  width: rem(54px);
  height: rem(33px);
}

/* Результат поиска */
#block-search {
  margin: 0 auto;
  width: rem(423px);
  height: rem(33px);
}

// Поле поиска
#input-search {
  height: rem(29px);
  width: rem(355px);
  border: rem(1px) solid #D8D8D8;
  padding-left: rem(5px);
  padding-right: rem(5px);
}

// Изменение цвета фокуса при вводе текста.
#input-search:focus {
  border: rem(1px) solid #75D9FF;
}

// Кнопка
#form-search {
  background: url(button-search-norm.png) no-repeat;
  width: rem(54px);
  height: rem(33px);
  border: none;
  cursor: pointer;
  position: absolute;
  margin-left: rem(-5px);
}

// Кнопка по наведению
#form-search:hover {
  background: url(button-search-hover.png) no-repeat;
  width: rem(54px);
  height: rem(33px);
}

/* Результат поиска */

#block-search-result {
  border-left: rem(1px) solid #E1DFDD;
  border-right: rem(1px) solid #E1DFDD;
  border-bottom: rem(1px) solid #E1DFDD;
  width: rem(358px);
  height: auto;
  background-color: white;
  position: absolute;
  z-index: 9999;
  display: none;
}

#list-search-result {
  //    margin-top: rem(-10px);
}

#list-search-result>li {
  border-bottom: rem(1px) solid #EAEAEA;
  min-height: rem(100px);
  height: auto;
  padding: rem(14px);
  vertical-align: middle;
  transition: all 1s ease;
}

.block-title-price {
  width: rem(136px);
  float: left;
  max-height: rem(70px);
  height: rem(70px);
  vertical-align: middle;
  line-height: rem(70px);
  position: relative;
}

.block-title-price>a {
  //    margin-top: rem(10px);
  display: block;
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif;
  font-size: rem(16px);
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.13;
  text-align: left;
  color: #b2aeac;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: rem(136px);
  height: 50%;
  margin: auto;
  overflow: hidden;
}

.block-title-price>a:hover {
  text-decoration: none;
}

.block-title-price>p {
  font: rem(15px) sans-serif;
  color: black;
  margin: rem(10px) 0;
  display: block;
}

#search-noresult {
  color: #E96767;
  font: rem(13px) sans-serif;
  margin-top: rem(10px);
  margin-bottom: rem(10px);
  display: inline-block;
}

.search-result-image {
  width: rem(70px);
  float: right;
  max-height: rem(70px);
  height: rem(70px);
  vertical-align: middle;
}

.search-result-image img {
  max-height: rem(70px);
  margin-right: rem(14px);
  max-width: rem(70px);
}

#search-more {
  color: #287FC3;
  font: rem(13px) sans-serif;
  margin-top: rem(10px);
  margin-bottom: rem(10px);
  display: inline-block;
}

.title-price--gray {
  font-weight: bold;
  //  padding: 0 rem(5px);
}

.block-search-result--error {
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif;
  font-size: rem(16px);
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.13;
  text-align: left;
  color: #b2aeac;
  vertical-align: middle;
  margin-top: rem(20px);
}

/*-----block-input-search-----*/
@media screen and (min-width: rem(1024px)) {
  .mobile-menu {
    // display: none !important;
  }
}

// @media screen and (min-width: rem(769px)) {
//   .mobile-menu {
//     display: none !important;
//   }
// }
@media screen and (max-width: rem(1030px)) {

  .navItem a.active::after,
  .navItem a:hover::after,
  .navItem span.switchOnHover:hover::after {
    content: none;
  }

  .m-tp-base-row {
    margin: 0;
  }

  .headerWrapper {
    min-width: 0;
    padding: 0 rem(23px);
    background: $beige;
    border-top: rem(1px) solid #e9e9e9;
    // border-bottom: rem(1px) solid #e9e9e9;
  }
  .l-header-container {
    padding: rem(30px) 0 rem(36px);
    align-items: center;
  }
  .l-header-main .l-header-width {
    background: transparent;
    width: 100%;
  }

  .m-searchbar {
    display: none;
  }

  .c-hamburger {
    display: block;
    width: rem(37px);
    height: rem(30px);
    margin-left: auto;
  }

  .l-logo {
    width: rem(118px);
    height: rem(43px);
    margin: 0;
  }

  .m-metanav {
    display: none !important;
    font-size: rem(12px);
    margin-top: rem(4px);
    margin-bottom: rem(5px);
  }

  .m-metanav-item {
    margin-left: 0;

    &:last-child {
      margin-left: 0.8vw;
    }
  }

  .m-metanav-link {
    padding-left: rem(39px);
  }

  .m-metanav-link--contact::before {
    width: rem(27px);
    height: rem(23px);
    left: 0;
    top: rem(10px);
  }

  .m-metanav-link--buyonline::before {
    width: rem(18px);
    height: rem(26px);
    left: rem(12px);
    top: rem(3px);
  }

  .m-navigation {
    display: none;
  }
  .mobile-menu {
    &__close {
      display: none;
    }
    &__content {
      flex-direction: column;
      padding: rem(115px) rem(23px) rem(30px);
      .no-arrow {
        display: block;
        background: none;
      }
      .m-metanav {
        display: block !important;
        flex-direction: column;
        padding: 0 rem(37px);
        margin-left: 0;
        float: left;
        &-list {
          display: flex;
          flex-direction: column;
        }
        &-item {
          margin-bottom: rem(18px);
        }
        &-link {
          padding-left: rem(30px);
          font-size: rem(16px);
          line-height: rem(24px);
        }
        &-link--contact::before {
          position: absolute;
          left: rem(0px);
          top: 0;
          height: rem(20px);
        }
        &-link--buyonline::before {
          top: 0;
          left: 0;
          height: rem(20px);
        }
      }
      .menu {
        flex: 0;
        width: 100%;
        padding-right: 0;
        // margin-bottom: rem(20px);
      }
    }
  }
}

@media screen and (max-width: rem(420px)) {}
