.InnerPage {
  position: relative;
  &.CoffeeBerries {
    .m-inner-page-header {
      margin-bottom: 0;
    }
  }
  &.Roasting {
    .m-inner-page-header {
      margin-bottom: rem(40px);
    }
  }
  .BannersNav {
    margin-bottom: rem(80px);
  }
  .centerContent {
    position: relative;
    width: rem(1134px);
  }
	.m-inner-page {
    padding-bottom: rem(120px);
    &-header {
      position: relative;
      width: 100%;
      height: rem(508px);
      margin-bottom: $inner-page-banner-offset;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      text-align: center;
      z-index: 2;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: linear-gradient(360deg, rgba(17, 12, 0, 0.71) -18.95%, rgba(58, 42, 7, 0) 113.02%);
      }
      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
      &-decor {
        position: absolute;
        bottom: rem(28px);
        left: 50%;
        transform: translateX(-50%);
        width: 52%;
        height: rem(54px);
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem(54px);
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 40%;
          height: 1px;
          background: #FFF;
        }
        &::after {
          right: 0;
        }
      }
      .centerContent {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
    &-title {
      margin-bottom: rem(17px);
      font-size: rem(36px);
      line-height: rem(39px) !important;
      letter-spacing: 0.03em;
      color: #000;
      font-weight: 700;
      text-align: center;
    }
    &-subtitle {
      margin-bottom: rem(32px);
      font-size: rem(24px);
      line-height: rem(26px);
      font-family: $alt-font;
      text-align: center;
    }
    &-headline {
			color: #FFF;
			font-family: $alt-font;
		}
    &-back {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: rem(5px);
      top: rem(45px);
      width: rem(76px);
      padding-bottom: rem(12px) !important;
      transition: 0.4s;
      border-radius: 0 rem(10px) 0 rem(10px);
      &::after {
        width: rem(43px);
        height: rem(18px);
        margin: 0;
        @include bg-image('/icons/icon_arrow-back.svg');
        
      }
      &:hover {
        background: $yellow;
      }
    }
    &-variants {
      text-align: center;
      &-list {
        margin-top: rem(55px);
      }
    }
    &-variant {
      display: flex;
      flex: 0 0 25%;
      align-items: flex-start;
      text-align: left;
      margin-bottom: rem(80px);
      &-icon {
        margin-right: rem(16px);
        flex-shrink: 0;
      }
      &-title {
        margin-bottom: rem(7px);
        font-size: rem(18px);
        font-family: $alt-font;
      }
      &-text {
        font-family: $alt-font-light;
        font-size: rem(16px);
      }
    }
    &-note {
      padding: rem(30px) 0;
      margin-bottom: rem(80px);
      color: $yellow;
      background: $brown;
      font-family: $alt-font-light;
      font-size: rem(16px);
      &-row {
        display: flex;
        align-items: center;
      }
      &-img {
        flex-shrink: 0;
        width: rem(50px);
        margin-right: rem(30px);
      }
      &-text {
        padding-right: rem(30px);
        font-size: rem(16px);
        line-height: rem(20px);
      }
    }
  }
  @media screen and (max-width: rem(768px)) {
    .g-tp-column {
      padding: 0 rem(30px);
    }
    .centerContent {
      position: relative;
      width: 100%;
      padding: 0 rem(26px);
    }
    .m-inner-page {
      padding-bottom: rem(83px);
      &-back {
        top: rem(41px);
        left: rem(11px);
        &::after {
          width: rem(48px);
          height: rem(25px);
        }
      }
      &-header {
        height: rem(448px);
        margin-bottom: rem(47px);
        &-decor {
          width: 85%;
          height: rem(70px);
          bottom: rem(16px);
          background-size: rem(48px);
          &::before,
          &::after {
            top: 65%;
            width: 35%;
          }
        }
      }
      &-headline {
        font-size: rem(32px);
        line-height: rem(36px);
        font-weight: 700;
        max-width: 80%;
        margin: auto;
      }
      &-title {
        margin-bottom: rem(15px);
        font-size: rem(23px);
        line-height: rem(26px) !important;
        letter-spacing: 0.05em;
        br {
          display: none;
        }
      }
      &-subtitle {
        margin-bottom: rem(15px);
        font-size: rem(14px);
        line-height: rem(17px);
        br {
          display: none;
        }
      }
      &-variants {
        padding-top: rem(25px);
        padding-bottom: rem(20px);
        &.flexbox {
          .g-tp-flex {
              flex-direction: column;
          }
        }
        &-list {
          margin-top: rem(13px);
        }
      }
      &-variant {
        width: 100%;
        margin-bottom: rem(20px);
        &-icon {
          width: rem(49px);
        }
        &-title {
          margin-bottom: rem(10px);
        }
        &-text {
          font-size: rem(16px);
          line-height: 1.09;
          letter-spacing: -0.05em;
        }
      }
      &-note {
        padding: rem(33px) 0;
        margin-bottom: 0;
        &-row {
          flex-direction: column;
        }
        &-img {
          width: rem(52px);
          margin-right: 0;
          margin-bottom: rem(25px);
        }
        &-text {
          padding-right: 0;
          text-align: center;
          font-size: rem(14px);
          line-height: 1.25;
          letter-spacing: 0.02em;
          br {
            display: none;
          }
        }
      }
    }
    .Banners,
    .BannersNav {
      .g-tp-row {
        width: 100%;
        display: flex;
        // overflow-x: scroll;
        // -webkit-overflow-scrolling: auto;
        .mCSB_container {
          gap: 0 rem(20px);
          .m-inner-page-nav {
            margin-bottom: 0;
          }
        }
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
          height: rem(7px);
          
        }
        &::-webkit-scrollbar:horizontal {
          -webkit-appearance: none;
          width: 7px;
          height: rem(7px);
          
        }
        &::-webkit-scrollbar-track {
          background: #CED0D1 !important;
          border-radius: 10px;
          height: rem(7px) !important;
          
        }
        &::-webkit-scrollbar-thumb {
          background: #22292E;
          border-radius: 10px;
          height: rem(7px) !important;
          border: 1px solid #CED0D1;
        }
      }
      .g-tp-column {
        width: calc(100vw - #{rem(50px)});
        flex-shrink: 0;
        padding: 0;
      }
      .g-tp-column--quarter {
        width: 100%;
      }
    }
  }
}