.BannersMain {
  margin-top: rem(30px);
}
.m-bannerMore {
  height: rem(416px);
  margin-bottom: rem(70px);
  position: relative;
}
.m-bannerMore-imageContainer {
  img {
    width: 100%;
  }
}
.m-bannerMore-title {
  margin-bottom: rem(18px);
}
.m-bannerMore-title .c-tp-headline {
  font-size: rem(38px) !important;
  text-transform: uppercase;
  line-height: rem(48px) !important;
  text-align: center;
}
.m-bannerMore-content {
  max-width: rem(337px);
  padding: rem(25px);
  position: absolute;
  top: rem(66px)
}
.m-bannerMore-1 .m-bannerMore-image--mobile {
  display: none;
  height: 100% ;
  width: 100%;
  object-fit: cover;
}
.m-bannerMore-content .c-tp-headline {
  font-size: rem(28px) !important;
  font-weight: normal !important;
  line-height: 1.29 !important;
  color: #ffffff !important;
  text-transform: uppercase;
  // margin-bottom: rem(90px);
}
.m-bannerMore-btn {
    position: absolute;
    bottom: rem(25px);
    left: rem(25px);
}

.m-bannerMore-btn .c-tp-simplebutton {
  width: rem(224px);
  line-height: rem(48px);
}
.m-bannerMore-btn .c-tp-simplebutton .c-tp-simplebutton-text {
  padding-left: 0;
  padding-right: 0;
  vertical-align: middle;
}

.g-tp-row--second
  .m-bannerMore {
    margin-bottom: rem(60px);
  }
.m-bannerMore-darklayout {
    position: absolute;
    top: rem(66px);
    width: 100%;
    height: calc(100% - rem(66px));
    background-color: black;
    opacity: 0.2;
}
