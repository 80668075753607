.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: rem(267px);
  padding: vh(15px) rem(10px) vh(40px);
  background: $beige;
  &.product-third-width {
    width: 32%;
  }
  &:not(:last-child) {
    margin-right: rem(20px);
  }
  &-img {
    max-width: 80%;
    height: vh(233px);
    margin-bottom: rem(18px);
    object-fit: contain;
  }
  &-name {
    margin-bottom: rem(6px);
    font-size: rem(14px);
    font-family: $alt-font;
    color: #828385;
  }
  &-type {
    margin-bottom: rem(15px);
    font-family: $alt-font;
    font-weight: 700;
  }
  &-saturation {
    display: flex;
    align-items: center;
    color: #8B8B8A;
    &-level {
      display: flex;
      align-items: center;
      margin-left: rem(10px);
      &-bean {
        width: rem(16px);
        height: rem(16px);
        @include bg-image('/products/bean-1.svg');
        .product-saturation-level--1 & {
          &:nth-child(-n+1) {
            @include bg-image('/products/bean-2.svg');
          }
        }
        .product-saturation-level--2 & {
          &:nth-child(-n+3) {
            @include bg-image('/products/bean-2.svg');
          }
        }
        .product-saturation-level--3 & {
          &:nth-child(-n+3) {
            @include bg-image('/products/bean-2.svg');
          }
        }
        .product-saturation-level--4 & {
          &:nth-child(-n+4) {
            @include bg-image('/products/bean-2.svg');
          }
        }
        .product-saturation-level--5 & {
          &:nth-child(-n+5) {
            @include bg-image('/products/bean-2.svg');
          }
        }
        .product-saturation-level--6 & {
          &:nth-child(-n+6) {
            @include bg-image('/products/bean-2.svg');
          }
        }
      }
    }
  }
  @media screen and (max-width: rem(768px)) {
    width: calc(100vw - #{rem(50px)});
    flex-shrink: 0;
    padding: rem(10px) rem(20px) rem(40px);
    &-img {
      height: rem(225px);
      margin-bottom: rem(20px);
    }
    &-name {
      font-size: rem(12px);
    }
    &-type {
      margin-bottom: rem(10px);
      font-size: rem(14px);
    }
    &-saturation {
      font-size: rem(12px);
    }
  }
}