.Roasting {
  position: relative;
  &::before {
		content: '';
		width: 100%;
		height: rem(510px);
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		background: #423C39;
		z-index: -1;
	}
  .slider-roasting.slick-dotted.slick-slider {
    display: none;
    @media screen and (max-width: rem(768px))  {
      display: block;
      margin-bottom: rem(45px);
    }
  }
  @media screen and (max-width: rem(768px))  {
    &::before {
      height: rem(115px);
    }
    .m-roasting-types-desktop-only {
      display: none;
    }
  }
}
.m-roasting {
  
  &-types {
    position: relative;
    margin-bottom: rem(31px);
    &::before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background: $beige;
    }
  }
  &-type {
    // height: rem(310px);
    padding: rem(250px) rem(30px) 0;
    text-align: center;
    background-position: right rem(-30px) top rem(5px) !important;
    background-size: 110% !important;
    &--light {
      @include bg-image('/page-roasting/type_1.png');
    }
    &--middle {
      @include bg-image('/page-roasting/type_2.png');
    }
    &--dark {
      @include bg-image('/page-roasting/type_3.png');
    }
    &--darken {
      @include bg-image('/page-roasting/type_4.png');
    }
    &-headline {
      margin-bottom: rem(12px) !important;
    }
    &-text {
      font-family: $alt-font-light;
      line-height: 1.15;
    }
  }
  &-navs {
    position: relative;
    padding-top: rem(47px);
    margin-bottom: rem(46px);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 42.2%;
      height: 59%;
      display: block;
      background: $beige;
    }
    .g-tp-flex {
      gap: 0 rem(25px)
    }
  }
  &-nav {
    position: relative;
    width: auto;
    flex: auto;
    padding: rem(245px) 0 0;
    text-align: center;
    background-size: cover !important;
    &--light {
      @include bg-image('/page-roasting/type_1-1.png');
    }
    &--middle {
      @include bg-image('/page-roasting/type_2-1.png');
    }
    &--dark {
      @include bg-image('/page-roasting/type_3-1.png');
    }
    &--darken {
      @include bg-image('/page-roasting/type_4-1.png');
    }
    &-headline {
      width: 100%;
      height: rem(132px);
      padding-bottom: rem(10px) !important;
      display: flex;
      font-size: rem(20px);
      align-items: center;
      justify-content: center;
      background: $beige;
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%);
        width: rem(80px);
        height: rem(80px);
        @include bg-image('icons/icon_arrow-round.svg');
        background-color: $beige;
        background-size: rem(60px);
        border-radius: 50%;
        z-index: 1;
      }
    }
  }
  @media screen and (max-width: rem(768px))  {
    &-types {
      &::before {
        height: rem(210px);
        top: rem(-45px);
      }
    }
    &-type,
    &-nav {
      display: block;
      width: 100%;
    }
    &-type {
      margin-bottom: rem(35px);
      background-position: right rem(-45px) top rem(7px) !important;
      background-size: 115% !important;
      &-headline {
        margin-bottom: rem(5px) !important;
      }
      &-text {
        font-size: rem(14px);
      }
    }
    &-nav {
      padding-top: rem(175px);
      &-headline {
        height: rem(88px);
        font-size: rem(16px);
        padding-top: rem(15px);
        padding-bottom: 0 !important;
      }
    }
  }
}