@media screen and (max-width: rem(1030px)) {
  .navLayer {
    display: none;
  }
}

@media screen and (max-width: rem(1030px)) {
  .mainPage .m-about-qs-headline .m-about-qs-years-switch::before {
    height: rem(171px);
  }

  .AboutCompany .m-tp-textblock,
  .ProductsBlock .m-tp-textblock,
  .NewsMain .m-tp-textblock {
    // padding-bottom: rem(19px);
    // margin-top: rem(-12px);
  }
  .AboutCompany { 
    padding-top: rem(22px);
    .c-tp-copytext {
      margin-top: rem(13px);
      margin-bottom: rem(18px);
      line-height: 1.11;
    }
    .m-tp-textblock {
      padding-right: 0;
    }
  }
  .g-tp-column--half {
    width: 100%;
  }
  .centerWrapper {
    padding: 0;
  }
  .m-home-nav {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 rem(16px);
    gap: rem(14px) rem(11px);
    padding: rem(20px) rem(10px) rem(18px);
  }
  .m-home-nav-link.c-tp-headline--arrow {
    padding: rem(7px) rem(6px) rem(6px) rem(8px) !important;
    font-size: rem(12px);
    border-radius: rem(6px) 0 rem(6px) 0;
    &::after {
      width: rem(6px);
      margin-left: rem(5px);
    }
  }
  .centerContent {
    width: 100%;
  }

  .navLayer {
    display: none;
  }

  .m-tp-swiper-container .swiper-slide img {
    height: rem(315px);
    object-fit: cover;
    object-position: right;
    &.swiper-slide-img-mobile {
      display: none;
    }
  }

  .m-swiperSlideContent {
    width: rem(340px);
    right: rem(90px);
    top: rem(75px);

    &-header {
      font-size: rem(36px) !important;
      padding-top: rem(3px) !important;
    }

    &-description {
      font-size: rem(18px) !important;
    }
  }

  .m-tp-swiper-container .swiper-button-next,
  .m-tp-swiper-container .swiper-button-prev {
    width: rem(49px);
    height: rem(49px);
    bottom: rem(133px);
  }

  .m-tp-swiper-container .swiper-button-prev {
    right: auto;
    left: 0;
  }

  .m-tp-swiper-container .swiper-button-next {
    right: 0;
  }

  .AboutCompany {
    padding-right: rem(28px);
    padding-left: rem(28px);

    .m-about-qs-headline {
      background-position: top center;
      background-size: auto;
    }
  }

  .AboutCompany .c-tp-headline,
  .ProductsBlock .c-tp-headline,
  .NewsMain .c-tp-headline {
    font-size: rem(24px) !important;
  }

  .ProductSlider {
    display: block;
  }

  .BannersMain {
    padding-right: rem(28px);
    padding-left: rem(28px);
    margin-top: rem(10px);

    .m-bannerMore-title .c-tp-headline {
      font-size: rem(32px) !important;
    }
  }

  .m-bannerMore {
    height: auto;
    margin-bottom: rem(50px);
  }

  .m-bannerMore-imageContainer {
    height: rem(252px);
  }

  .m-bannerMore-image {
    height: 100%;
  }

  .m-bannerMore-btn {
    bottom: rem(25px);
  }

  .NewsMain {
    padding-right: rem(28px);
    padding-left: rem(28px);
    margin-bottom: rem(50px);

    .m-bannerMore-btn {
      right: rem(19px);
    }
  }

  .m-news-qs .c-tp-headline--90 {
    font-size: rem(28px) !important;
  }
}

@media screen and (max-width: rem(768px)) {
  .mainPage .m-about-qs-descriptions-item {
    width: rem(320px);
  }

  // ---------- MAIN PAGE ----------
  .mainPage {
    padding: 0;
  }

  .centerContent {
    width: 100%;
    min-height: 0;
  }
  .g-tp-row {
    margin: 0;
  }
  .AboutCompany .m-tp-textblock,
  .ProductsBlock .m-tp-textblock,
  .NewsMain .m-tp-textblock {
    padding-bottom: rem(33px);
  }

  .NewsMain .m-news-qs--full {
    margin-bottom: rem(20px);
  }

  .ProductSlider .m-tp-teaser002 .c-tp-headline {
    font-size: rem(18px) !important;
    font-weight: bold !important;
    font-stretch: condensed !important;
    text-align: left !important;
    color: #373f44 !important;
    margin-top: rem(5px);
  }

  .adElementContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .Stagesliderfullwidth {
    .m-tp-swiper {
      padding-bottom: rem(0px);
    }
    .m-tp-swiper-container {
      margin-bottom: 0;
    }
  }
  .m-tp-swiper-container {
    margin-bottom: 0.571em;

    .swiper-slide img {
      width: 100%;
      height: auto;
      object-fit: cover;
      &.swiper-slide-img-mobile {
        display: block;
      }
      &.swiper-slide-img-desktop {
        display: none;
      }
    }
    .swiper-slide video {
      width: 100%;
      height: auto;
      object-fit: cover;
      &.swiper-slide-video-mobile {
        display: block;
      }
      &.swiper-slide-video-desktop {
        display: none;
      }
    }
  }

  .m-swiperSlideContent {
    display: none;
  }

  .m-tp-swiper-container .swiper-button-next,
  .m-tp-swiper-container .swiper-button-prev {
    width: 2.5em;
    height: 2.5em;
    background-size: 2.5em;
    bottom: 10.8em;
  }

  .m-tp-swiper-container .swiper-button-prev {
    right: auto;
    left: 0;
  }

  .m-tp-swiper-container .swiper-button-next {
    right: 0;
    left: auto;
  }

  .m-swiperSlideContentMobile {
    display: block;
  }

  .AboutCompany .c-tp-headline,
  .NewsMain .c-tp-headline {
    font-size: rem(24px) !important;
    line-height: rem(26px) !important;
    margin-bottom: rem(0px);
  }

  .AboutCompany {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: rem(25px);

    .m-about-qs-headline {
      min-height: auto;
      height: rem(240px);
      background-size: cover;
    }
  }

  .AboutSlider {
    display: none;
  }

  .AboutSliderMobile {
    display: block;
  }

  .m-about-qs {
    margin-bottom: 0;
  }
  .ProductsBlock {
    padding-top: 0;
    padding-bottom: rem(43px);
  }
  .ProductsBlock-content {
    // display: none;
    margin-left: rem(17px);
    margin-right: rem(17px);
    justify-content: flex-start;
    // overflow-x: scroll;
    &::-webkit-scrollbar {
      height: rem(7px);
    }
    &::-webkit-scrollbar-track {
      background: #CED0D1;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #22292E;
      border-radius: 10px;
    }
    .g-tp-column {
      flex-shrink: 0;
      padding: 0;
    }
    .m-tp-teaser002 {
      .m-tp-teaser002-image {
        height: rem(100px);
        margin-bottom: rem(6px);
      }
      .c-tp-headline {
        font-size: rem(14px) !important;
      }
      .c-tp-headline--arrow:after {
        width: rem(22px) !important;
        margin-top: rem(10px) !important;
      }
    }
  }

  .ProductSlider {
    display: block;

    .c-tp-headline {
      font-size: rem(18px) !important;
      padding: 0 2.66vw !important;
    }

    .m-tp-teaser002 {
      padding: 0;
    }

    .m-tp-teaser002-content {
      padding-left: 0;
    }

    .m-tp-teaser002 .m-tp-teaser002-image {
      height: rem(177px);
    }

    .m-tp-swiper-container .swiper-button-next,
    .m-tp-swiper-container .swiper-button-prev {
      bottom: 8.7em;
    }

    .m-tp-swiper-container .swiper-slide img {
      height: rem(150px);
      bottom: rem(-14px);
    }

    &__container {
      position: relative;
    }

    &_desk {
      display: block;

      @media(max-width: rem(768px)) {
        display: none;
      }
    }

    &_mob {
      display: none;

      @media(max-width: rem(768px)) {
        display: block;
      }
    }

    &__image {
      width: 100%;

      &-container {
        height: rem(177px);
        width: unset;
        display: block;
        @include bg-image('/page-main/main-page-product-bg-mobile.png');
        background-size: cover;
        text-align: center;

        img {
          margin-top: 2.5%;
          height: 90%;
          width: unset;
        }
      }
    }

    &__content {
      display: inline-block;
      padding-top: rem(20px);

      &-header.c-tp-headline {
        font-weight: 700 !important;
        text-transform: none !important;

        &::after {
          margin: 0.2em 0 0 0.7em;
        }
      }
    }

    &__button {
      position: absolute;
      bottom: rem(107px);
      width: rem(33px);
      height: rem(33px);
      background-size: contain;
      overflow: visible;

      &_next {
        right: 0;
        background: #ffffff url(../img/page-about/right_icon.svg) center center no-repeat;
      }

      &_prev {
        left: 0;
        background: #ffffff url(../img/page-about/left_icon.svg) center center no-repeat;
      }
    }

    .slick {
      &-track {
        display: flex !important;
      }

      &-slide {
        height: inherit !important;
        width: 60vw;
        margin-left: rem(10px);

        img {
          display: inline-block;
        }
      }
    }
  }

  .BannersMain {
    margin-top: rem(22px);
    padding-right: 0;
    padding-left: 0;

    .g-tp-column--half {
      width: 100%;
    }

    .m-bannerMore {
      height: rem(213px);
      margin-bottom: rem(32px);
    }

    .m-bannerMore-title {
      margin-bottom: rem(12px);

      .c-tp-headline {
        font-size: rem(24px) !important;
        line-height: normal !important;
      }
    }

    .m-bannerMore-1 .m-bannerMore-image {
      display: none;
    }

    .m-bannerMore .m-bannerMore-image {
      // height: rem(170px);
      // object-fit: cover;
      width: 100%;
      height: auto;
    }

    .m-bannerMore-1 .m-bannerMore-image--mobile {
      display: block;
    }

    .m-bannerMore-content {
      max-width: rem(215px);
      padding: rem(20px);
      top: rem(43px);

      .c-tp-headline {
        font-size: rem(18px) !important;
        line-height: 1.44 !important;
      }
    }

    .m-bannerMore-btn .c-tp-simplebutton {
      width: rem(170px);
      line-height: rem(33px);
    }

    .m-bannerMore-btn .c-tp-simplebutton .c-tp-simplebutton-text {
      font-size: rem(12px) !important;
      padding: rem(5px) 0;
    }

    .m-bannerMore-btn {
      bottom: rem(7px);
      left: rem(20px);
    }

    .m-bannerMore-darklayout {
      top: rem(44px);
      height: calc(100% - rem(43px));
    }

    .m-bannerMore-imageContainer {
      height: rem(170px);
      width: 100%;
      overflow: hidden;
    }

    .m-bannerMore-2 .m-bannerMore-image {
      margin-top: rem(-35px);
    }

    .m-bannerMore-3 .m-bannerMore-image {
      margin-top: rem(-70px);
    }

    .m-bannerMore-4 .m-bannerMore-image {
      margin-top: rem(-76px);
    }
  }

  .m-bannerMore-btn .c-tp-simplebutton {
    width: rem(170px);
    line-height: rem(33px);
  }

  .m-bannerMore-btn .c-tp-simplebutton .c-tp-simplebutton-text {
    font-size: rem(12px) !important;
    padding: rem(5px) 0;
  }

  .NewsMain {
    margin-bottom: rem(32px);
    padding-right: 0;
    padding-left: 0;

    .m-news-qs-headline {
      min-height: rem(170px);
      padding-top: rem(20px);
      padding-left: rem(20px);
      background-size: cover;
      background-position: 80% center;

      .m-news-qs-header {
        max-width: rem(177px);
      }

      .c-tp-headline {
        font-size: rem(18px) !important;
        line-height: 1.44;
      }

    }

    .m-bannerMore-btn {
      left: rem(20px);
      bottom: rem(7px);
    }
  }

  .mainPage {
    .slider-header {
      min-height: rem(171px);
      width: 100%;
      background-image: url(../img/page-about/main-banner-slider.jpg);
      background-size: 163%;
      background-repeat: no-repeat;
      z-index: 10;
      background-position: 47% 0%;
    }

    .data-link-text {
      text-align: center;
      display: inline-block;
      padding-bottom: .25em;
      border-bottom: solid transparent rem(2px);
      font-family: 'Roboto Condensed', Arial, sans-serif;
      font-size: 2.1rem;
      font-weight: bold;
      color: #373f44;
      margin-bottom: rem(5px);
      width: 100%;
      margin: 0 auto;
      margin-top: rem(25px);
      // margin-left: rem(20px);
    }

    .data-link:before {
      content: "";
      display: block;
      box-sizing: content-box;
      width: rem(50px);
      height: rem(50px);
      margin: 0 auto;
      margin-bottom: rem(12px);
      border: rem(1px) solid #ccc;
      border-radius: 50%;
      background-color: #ffffff;
      transition: all 0.3s;
      background-image: url(../img/icon_plus.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 44%;
      background-position: center;
      background-repeat: no-repeat;
    }

    .slick-slider {}

    .copytext {
      font-size: rem(18px) !important;
      font-weight: 300 !important;
      font-stretch: condensed !important;
      line-height: 1.33 !important;
      text-align: left !important;
      color: #373f44;
      padding-right: rem(10px);
      padding-left: rem(10px);
      text-align: left;
      padding-bottom: rem(38px);
    }

    .slick-dotted.slick-slider {}

    .m-about-qs-descriptions-item {
      padding-top: 0 !important;
      width: 100%;
    }

    .js-afterelement-block {
      display: none;
    }

    .data-link--visible:before {
      background-image: url(../img/js_tp_button_close-gold.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: 38%;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.2s;
    }

    .data-link--visible:after {
      content: '';
      position: absolute;
      width: rem(35px);
      height: rem(2px);
      top: rem(89px);
      left: rem(8px);
      background-color: #837650;
      transition: all 0.3s ease;
    }

    .slick-next {
      right: rem(0px) !important;
      top: rem(-125px) !important;
      width: rem(33px) !important;
      height: rem(33px) !important;
      background: url(../img/page-about/right_icon.svg) right center no-repeat !important;

      &:before {
        content: '' !important;
      }
    }

    .slick-prev {
      transform: rotate(360deg);
      left: rem(0px) !important;
      top: rem(-125px) !important;
      opacity: 1 !important;
      width: rem(33px) !important;
      height: rem(33px) !important;
      z-index: 10;
      background: url(../img/page-about/left_icon.svg) right center no-repeat !important;
    }

    .m-about-qs-descriptions-item--img {
      padding-top: rem(37px);
    }
  }
}


@media screen and (max-width: rem(320px)) {
  .BannersMain {
    .m-bannerMore-3 .m-bannerMore-image {
      margin-top: rem(-52px);
    }

    .m-bannerMore-4 .m-bannerMore-image {
      margin-top: rem(-46px);
    }
  }
}

// @media screen  and (min-device-width: rem(768px)) and (min-device-height: rem(1024px)) and (orientation:landscape) {
//   @viewport { 
//     zoom: 1;
//   }
// }
@media screen and (max-width: rem(322px)) {

  .l-tp-footer-content .m-flags-item {
    margin-left: 15%;
  }

}
