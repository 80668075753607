.m-inner-page-nav {
  // margin-bottom: rem(18px);

  &-image {
    width: 100%;
    height: rem(220px);
    object-fit: cover;
    .g-tp-column--quarter & {
      height: rem(288px);
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(15px) rem(15px) rem(28px);
    background: $beige;

    .m-info--full & {
      padding: rem(27px) rem(15px) rem(23px);
    }
    .g-tp-column--third & {
      padding-bottom: rem(22px);
    }
  }

  &-header {
    display: block;
    margin-bottom: rem(5px) !important;
    font-family: $alt-font;
    font-weight: 700;
    font-size: rem(18px);
    .g-tp-column--third & {
      margin-bottom: rem(10px) !important;
      letter-spacing: 0.02em;
    }
  }

  &-link {
    position: relative;
    color: $light-grey;
    font-size: rem(16px);
    font-family: $alt-font-light;
    &::before {
      content: '';
      position: absolute;
      bottom: rem(-2px);
      left: 0;
      display: block;
      width: calc(100% - #{rem(22px)});
      height: 1px; 
      background: #65696D4D;
    }
    &::after {
      top: 50%;
      height: rem(12px);
      width: rem(8px);
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%2365696D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
  &-blocks {
    margin-bottom: rem(34px);
  }
  &-block {
    margin-bottom: rem(46px);
    &-image {
      width: 100%;
      height: rem(330px);
      object-fit: cover;
      object-position: top center;
    }
    &-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(140px);
      background: $beige;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%) rotate(-90deg);
        display: block;
        width: rem(80px);
        height: rem(80px);
        @include bg-image('/icons/icon_accordion.svg');
      }
    }
    &-header {
      color: $brown;
    }
  }
}
@media screen and (max-width: rem(768px)) {
  .m-inner-page-nav {
    margin-bottom: rem(20px);
    &-image {
      width: 100%;
      height: rem(136px);
      object-fit: cover;
      .g-tp-column--quarter & {
        height: rem(137px);
      }
    }

    &-content {
      padding: rem(13px) rem(16px) rem(25px);
      background: $beige;
      .g-tp-column--third & {
        padding: rem(13px) rem(16px) rem(25px) !important;
      }
      .m-info--full & {
        padding: rem(27px) rem(16px) rem(23px);
      }
    }

    &-header {
      margin-bottom: rem(0px) !important;
      font-size: rem(16px);
      font-family: $alt-font;
      font-weight: 700;
      .g-tp-column--third & {
        margin-bottom: rem(0px) !important;
      }
    }

    &-link {
      position: relative;
      color: $light-grey;
      font-size: rem(12px);
      line-height: 1.5;

      &::after {
        top: 50%;
        height: rem(12px);
        width: rem(8px);
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%2365696D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
      &::before {
        width: calc(100% - #{rem(17px)});
      }
    }
    &-blocks {
      .m-cooking-methods & {
        .g-tp-column {
          padding: 0;
        }
      }
    }
    &-block {
      margin-bottom: rem(27px);
      &-image {
        height: rem(160px);
      }
      &-content {
        height: rem(74px);
        padding-top: rem(17px);
        &::before {
          width: rem(64px);
          height: rem(64px);
        }
        .headline {
          font-size: rem(22px);
          letter-spacing: -0.02em;
        }
      }
    }
  }
}