.faqPage {
  &.centerWrapper {
    @media screen and (max-width: rem(768px)) {
      padding: 0 rem(30px);
    }
  }
  .faq {
    &-title {
      font-weight: 300;
      font-size: rem(32px);
      line-height: rem(30px);
      margin: rem(70px) 0 rem(33px);
    }
    &-btns {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: rem(50px) 0 rem(90px);
    }
  }
}
.accordion {
  background: #fff;
  width: 100%;
  border-bottom: rem(1px) solid #A6A9AC;
  a {
    color: red;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  table {
    margin-top: rem(20px);
    tr {
      td {
        padding: rem(5px) rem(20px);
        border: rem(1px) solid red;
      }
      th {
        border: rem(1px) solid red;
        padding: rem(5px) rem(20px);
      }
    }
  }
  &__panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: rem(34px) rem(60px) rem(33px) 0;
    cursor: pointer;
    box-sizing: border-box;
    @media screen and (max-width: rem(768px)) {
      padding: rem(40px) rem(60px) rem(20px) 0;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: rem(8px);
    width: rem(26px);
    height: rem(14px);
    transform: translateY(-50%);
    transform-origin: 50% 50%;
    transition: all .3s ease-in-out;
    .accordion.active & {
      transform: translateY(-50%) rotate(180deg);
    }
    svg {
      display: block;
      width: 100%;
      height: 100%;
      color: #C0A468;
      transition: all .3s ease-in-out;
      .accordion.active & {
        color: #000;
      }
    }
  }
  &__title {
    width: 100%;
    font-size: rem(18px);
    line-height: rem(25px);
    letter-spacing: 0.02em;
    @media screen and (max-width: rem(768px)) {
      font-size: rem(20px);
      line-height: rem(25px);
    }
    .accordion.active & {

    }
  }
  &__content {
    display: none;
  }
  &__content-inner {
    font-family: "Roboto Condensed", Arial, sans-serif !important;
    font-size: rem(18px) !important;
    font-weight: 300 !important;
    line-height: 1.33 !important;
    letter-spacing: rem(0.3px);
    color: #373f44;
    padding: rem(12px) rem(87px) rem(40px) 0;
    box-sizing: border-box;
    background: #fff;
    @media screen and (max-width: rem(768px)) {
      padding-right: 0;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
