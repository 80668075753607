@charset "UTF-8";
/* font-family: "RobotoCondensedRegular"; */
@font-face {
  font-family: "InterstateWGL";
  src: url("../fonts/InterstateWGL/InterstateWGL-Regular.eot");
  src: url("../fonts/InterstateWGL/InterstateWGL-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/InterstateWGL/InterstateWGL-Regular.woff") format("woff"), url("../fonts/InterstateWGL/InterstateWGL-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "InterstateWGL-Light";
  src: url("../fonts/InterstateWGL/InterstateWGL-Light.eot");
  src: url("../fonts/InterstateWGL/InterstateWGL-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/InterstateWGL/InterstateWGL-Light.woff") format("woff"), url("../fonts/InterstateWGL/InterstateWGL-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensedRegular.eot");
  src: url("../fonts/RobotoCondensedRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoCondensedRegular.woff") format("woff"), url("../fonts/RobotoCondensedRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensedLight.eot");
  src: url("../fonts/RobotoCondensedLight.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoCondensedLight.woff") format("woff"), url("../fonts/RobotoCondensedLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensedBold.eot");
  src: url("../fonts/RobotoCondensedBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoCondensedBold.woff") format("woff"), url("../fonts/RobotoCondensedBold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/roboto-regular-webfont.woff") format("woff"), url("../fonts/roboto-regular-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  /* normal */
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"), url("../fonts/roboto-bold-webfont.woff") format("woff"), url("../fonts/roboto-bold-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  /* bold */
}
/* fallback cluster headlines */
@font-face {
  font-family: "tp-icon";
  src: url("../fonts/tp-icons.ttf?68f703f6863dc59a03b856f539ceeff5") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*# sourceMappingURL=std.css.map */
* {
  box-sizing: border-box;
}

ul, li, h1, h2, h3, h4, h5, p {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button, a {
  border: none;
  cursor: pointer;
}

input[type=range] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:active, input[type=range]:focus, button:active, button:focus {
  outline: none !important;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]:-moz-focusring {
  border: 0rem !important;
  outline: none !important;
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

textarea {
  resize: none;
}

.c-tp-copytext {
  font-family: "InterstateWGL-Light", Arial, Helvetica, Verdana, sans-serif !important;
  color: #21292F;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.15;
}
@media screen and (max-width: 48rem) {
  .c-tp-copytext {
    line-height: 1.4;
  }
}

.c-tp-simplebutton--white {
  color: #ffffff;
  border-color: #ffffff;
}

.c-tp-simplebutton--white .c-tp-simplebutton-text {
  color: #ffffff;
}

.c-tp-simplebutton--white:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}

.c-tp-simplebutton--white:after {
  width: 1.875rem;
  right: -0.3125rem;
  background-image: url("/img/c_tp_simplebutton_icon_standard.png");
}

.c-tp-simplebutton--white:hover:after {
  background-image: url("/img/c_tp_simplebutton_icon.png");
}

.c-tp-simplebutton--white:hover .c-tp-simplebutton-text {
  color: #373e44;
}

.c-tp-simplebutton--blue {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #284178;
  border-color: #284178;
}
.c-tp-simplebutton--blue:hover .c-tp-simplebutton-text {
  color: #fff !important;
}
.c-tp-simplebutton--blue .c-tp-simplebutton-text {
  padding: 0.62em 3.68em;
  color: #284178;
}
.c-tp-simplebutton--blue::after {
  display: none;
}

.c-tp-simplebutton--primary {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #284178;
  color: #fff;
  border-color: #284178;
}
.c-tp-simplebutton--primary:hover {
  background: #fff;
}
.c-tp-simplebutton--primary:hover .c-tp-simplebutton-text {
  color: #284178 !important;
}
.c-tp-simplebutton--primary .c-tp-simplebutton-text {
  padding: 0.625rem 1.25rem;
  color: #fff;
}
.c-tp-simplebutton--primary::after {
  display: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  font-size: 4.9vw;
}
@media (max-width: 821px) and (orientation: landscape) {
  html {
    font-size: 1.8vw;
  }
}
@media (min-width: 767px) and (orientation: portrait) {
  html {
    font-size: 1.3vw;
  }
}
@media (min-width: 821px) and (orientation: landscape) {
  html {
    font-size: 1.111111vw;
  }
}
@media (min-width: 1670px) {
  html {
    font-size: 1.111111vw;
  }
}

body {
  font-family: "InterstateWGL", Arial, Verdana, Geneva, Helvetica, sans-serif;
  font-size: 1rem;
}

.clear {
  clear: both;
}

.no-scroll {
  overflow: hidden;
}

@media (max-width: 821px) and (orientation: portrait) {
  .full-width-img {
    width: calc(100% + 2.125rem) !important;
    margin-left: -1.0625rem;
  }

  .g-tp-column {
    padding: 0 1.0625rem;
  }

  .m-tp-textblock {
    padding-left: 0;
    padding-right: 0;
  }
}
.slick-dotted.slick-slider .slick-dots {
  bottom: -2.1875rem;
}
.slick-dotted.slick-slider .slick-dots li {
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.5rem;
}
.slick-dotted.slick-slider .slick-dots li button {
  padding: 0;
  width: 100%;
  height: 100%;
}
.slick-dotted.slick-slider .slick-dots li button::before {
  font-size: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.6);
  transition: 0.4s;
  transform-origin: center;
  border: 1px solid #423C39;
  border-radius: 50%;
  opacity: 1;
}
.slick-dotted.slick-slider .slick-dots li.slick-active button::before {
  transform: scale(1);
  background: #423C39;
}
@media screen and (max-width: 48rem) {
  .slick-dotted.slick-slider .slick-dots {
    bottom: -1.6875rem;
  }
  .slick-dotted.slick-slider .slick-dots li {
    margin: 0 0.6875rem;
  }
}

.mCSB_horizontal {
  width: 100%;
}
.mCSB_horizontal .mCSB_container {
  display: flex;
  padding-bottom: 1.8125rem;
  justify-content: center;
}
@media screen and (max-width: 48rem) {
  .mCSB_horizontal .mCSB_container {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 48.0625rem) {
  .mCSB_horizontal .mCSB_container {
    width: 100% !important;
    padding-bottom: 0;
  }
}
.mCSB_horizontal .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  height: 0.4375rem;
  opacity: 1;
}
@media screen and (min-width: 48.0625rem) {
  .mCSB_horizontal .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    display: none;
  }
}
.mCSB_horizontal .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  margin: 0;
  height: 100%;
  background: #22292E;
  border: 2px solid #CED0D1;
  opacity: 1 !important;
}
.mCSB_horizontal .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 100%;
  margin: 0;
  background: #CED0D1;
}

html.popup-opened {
  overflow: hidden;
}

.popup-opened body {
  overflow: hidden;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 99;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: hidden;
}
@supports (-ms-ime-align: auto) {
  .popup {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 48rem) {
  .popup ._desktop {
    display: none;
  }
}
.popup__inner {
  background: #fff;
}
.popup__window {
  width: 100%;
  max-height: 100vh;
  max-width: 49rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
@media screen and (max-width: 48rem) {
  .popup__window {
    position: relative;
    transform: none;
    max-width: calc(100vw - 2.5rem);
    margin-left: 1.25rem;
    top: auto;
    left: auto;
    margin-top: 3.125rem;
    min-height: 100vh;
  }
}
.popup__close {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  z-index: 5;
  width: 1.1875rem;
  height: 1.1875rem;
  cursor: pointer;
  background: url("/img/cross.svg") 0 0 no-repeat;
  background-size: contain;
}
.popup__logo {
  width: 4.3125rem;
  height: 4.25rem;
  background: url("/img/msg.svg") 0 0 no-repeat;
  background-size: contain;
  margin: 0 auto 0.6875rem;
}
@media screen and (max-width: 48rem) {
  .popup__logo {
    width: 2.375rem;
    height: 2.375rem;
    margin: 0 auto 0.3125rem;
  }
}
.popup__heading {
  font-size: 2rem;
  line-height: 2.8125rem;
  font-weight: 300;
  font-family: "Roboto Condensed";
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 48rem) {
  .popup__heading {
    margin-bottom: 0.8125rem;
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
}
.popup__content {
  width: 100%;
  padding: 1.9375rem 8.4375rem 0;
}
@media screen and (max-width: 48rem) {
  .popup__content {
    padding: 1.9375rem 1.5625rem 0;
  }
}
.popup__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 48rem) {
  .popup__row {
    flex-direction: column;
  }
}
.popup__text {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
@media screen and (max-width: 48rem) {
  .popup__text {
    font-size: 0.625rem;
    line-height: 0.875rem;
    font-family: "InterstateWGL-Light";
  }
}
.popup__text--center {
  text-align: center;
}
.popup__text--big {
  font-family: "InterstateWGL-Light";
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-bottom: 3.125rem;
}
@media screen and (max-width: 48rem) {
  .popup__text--big {
    font-size: 0.75rem;
    line-height: 1.1;
  }
}
.popup__list {
  padding: 0;
}
.popup__list-item {
  list-style-type: disc;
  list-style-position: inside;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
@media screen and (max-width: 48rem) {
  .popup__list-item {
    font-size: 0.625rem;
    line-height: 0.875rem;
    font-family: "InterstateWGL-Light";
  }
}
.popup__img {
  display: block;
}
.popup__img--1 {
  width: 17.3125rem;
  height: auto;
}
@media screen and (max-width: 48rem) {
  .popup__img--1 {
    width: 100%;
    margin-top: 0.625rem;
  }
}
.popup__tip {
  color: #858585;
  font-weight: 300;
  font-family: "InterstateWGL-Light";
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-top: 2.5625rem;
}
@media screen and (max-width: 48rem) {
  .popup__tip {
    margin-top: 0.625rem;
    font-size: 0.625rem;
  }
}
.popup__step {
  position: relative;
  padding-bottom: 1.25rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.popup__step.active {
  opacity: 1;
  pointer-events: auto;
  position: relative;
  transition: opacity 0.3s ease-in-out;
}
.popup__step--1 {
  padding-bottom: 1.25rem;
  margin-top: 1.375rem;
}
.popup__step--1 .form__btns {
  margin-top: 3.1875rem;
}
@media screen and (max-width: 48rem) {
  .popup__step--1 {
    padding-bottom: 2.0625rem;
  }
}
.popup__step--3 {
  padding-bottom: 4.625rem;
}
.popup__step--3 .form__btns {
  margin-top: 2.0625rem;
}
@media screen and (max-width: 48rem) {
  .popup__step--3 {
    padding-bottom: 2.0625rem;
  }
}
.popup__step--4 {
  padding-bottom: 4rem;
}
.popup__step--4 .form__btns {
  margin-top: 3.3125rem;
}
@media screen and (max-width: 48rem) {
  .popup__step--4 {
    padding-bottom: 2.0625rem;
  }
  .popup__step--4 .form__btns {
    margin-top: 1.5rem;
  }
}
.popup__step-title {
  line-height: 1.875rem;
  font-size: 1.625rem;
  font-weight: 300;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 48rem) {
  .popup__step-title {
    margin-bottom: 0.375rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-family: "InterstateWGL-Light";
  }
}
.popup__step .c-tp-simplebutton {
  width: 14.8125rem;
}
@media screen and (max-width: 48rem) {
  .popup__step .c-tp-simplebutton {
    width: 10.9375rem;
  }
}
.popup__step .c-tp-simplebutton[disabled=disabled] {
  opacity: 0.5;
  pointer-events: none;
}
@media screen and (max-width: 48rem) {
  .popup__step .c-tp-simplebutton .c-tp-simplebutton-text {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.popup__counter {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2.875rem;
}
@media screen and (max-width: 48rem) {
  .popup__counter {
    margin-bottom: 1.625rem;
  }
}
.popup__counter-item {
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  color: #fff;
  background: #D8D8D8;
  text-align: center;
  line-height: 2.25rem;
  font-size: 0.9375rem;
  font-weight: 600;
}
@media screen and (max-width: 48rem) {
  .popup__counter-item {
    width: 1.3125rem;
    height: 1.3125rem;
    font-size: 0.5rem;
    line-height: 1.3125rem;
  }
}
.popup__counter-item.active {
  background: #C0A468;
}
.popup__counter-item.done {
  background: #E4CB94;
}
.popup__counter-item + .popup__counter-item {
  margin-left: 2.8125rem;
}
.popup__counter-item + .popup__counter-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -2.8125rem;
  width: 2.8125rem;
  height: 0.0625rem;
  background: #D8D8D8;
}
@media screen and (max-width: 48rem) {
  .popup__counter-item + .popup__counter-item {
    margin-left: 1.625rem;
  }
  .popup__counter-item + .popup__counter-item::before {
    width: 1.625rem;
    left: -1.625rem;
  }
}
.popup-accordion.active .popup-accordion__icon::after {
  transform: translate(-50%, -50%) rotate(180deg);
}
.popup-accordion__top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.6875rem;
}
.popup-accordion__icon {
  position: relative;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 0.25rem;
  margin-left: 0.75rem;
  background: #C0A468;
}
.popup-accordion__icon::after, .popup-accordion__icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);
  width: 0.6875rem;
  height: 0.125rem;
  background: #fff;
  transition: transform 0.3s ease-in-out;
}
.popup-accordion__icon::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
@media screen and (max-width: 48rem) {
  .popup-accordion__icon {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.125rem;
  }
  .popup-accordion__icon::after, .popup-accordion__icon::before {
    width: 0.4375rem;
    height: 0.0625rem;
  }
}
.popup-accordion__title {
  line-height: 1.25rem;
  font-size: 1rem;
  font-family: "InterstateWGL-Light";
  font-weight: 300;
}
@media screen and (max-width: 48rem) {
  .popup-accordion__title {
    font-size: 0.625rem;
  }
}
.popup-accordion__content {
  display: none;
  position: relative;
  padding: 3.3125rem 8.4375rem;
  margin: 0 -8.4375rem 2.5rem;
  background: #F8F6F2;
}
@media screen and (max-width: 48rem) {
  .popup-accordion__content {
    padding: 1.4375rem 1.625rem;
    margin: 0 -1.5625rem 1.25rem;
  }
}
.popup-accordion__content .form__field:last-child {
  margin-bottom: 0;
}
.popup-accordion__content .form__field .field__input {
  background: #fff;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100%;
  font-size: 0.9375rem;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  padding-left: 1.5625rem;
  padding-right: 3.4375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 1.5625rem;
  padding-left: 3.4375rem;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 2rem;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 0.3125rem;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-container--open, .select2-container--default .select2-selection {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-dropdown {
  background-color: white;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -6250rem;
  width: 100%;
  z-index: 9;
  font: "Roboto Condensed", Arial, Verdana, Geneva, Helvetica, sans-serif;
  border-radius: 1.5625rem;
  box-shadow: 0 0.5rem 1.125rem rgba(154, 128, 201, 0.3);
  margin-top: -0.0625rem;
  padding: 0.625rem 0 0.875rem;
}

.select2-results {
  display: block;
  border-top: 0.0625rem solid transparent;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 0.5em 1.5625rem;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 0.25rem;
}
.select2-search--dropdown .select2-search__field {
  padding: 0.25rem;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 0.0625rem !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0.0625rem !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: white;
  border: 0;
  border-radius: 1.5625rem;
  box-shadow: 0 0.5rem 1.125rem rgba(154, 128, 201, 0.3);
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #5e5e5e;
  font-family: "Roboto Condensed", Arial, Verdana, Geneva, Helvetica, sans-serif;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: black;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 1.875rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  width: 0.9375rem;
  height: 0.9375rem;
  background: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.71045L8.5 7.71045L2 1.71045' stroke='%23C0A468' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
  background-size: contain;
  transform-origin: center;
  transition: transform 0.3s;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 0.0625rem;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotate(-180deg);
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 0.0625rem solid #aaa;
  border-radius: 0.25rem;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 0.3125rem;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 0.3125rem;
  float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 0.3125rem;
  margin-right: 0.625rem;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 0.0625rem solid #aaa;
  border-radius: 0.25rem;
  cursor: default;
  float: left;
  margin-right: 0.3125rem;
  margin-top: 0.3125rem;
  padding: 0 0.3125rem;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 0.125rem;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 0.3125rem;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 0.125rem;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 0.0625rem;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 0.0625rem solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 12.5rem;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: transparent;
  color: black;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 0.375rem;
}

.select2-hidden-accessible {
  position: absolute;
  top: 0;
  left: 100000;
  opacity: 0;
  z-index: -100;
  display: none !important;
}

.select2.select2-container--open .select2-selection.select2-selection--single {
  border-color: #284178;
}
.select2 .select2-selection.select2-selection--single {
  background-color: transparent;
  border: none;
  border: 0.0625rem solid #B9B9B9;
  box-shadow: none;
  border-radius: 0.25rem;
}
.select2 .select2-selection.select2-selection--single .select2-selection__rendered {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.375rem;
  padding: 0.75rem 2.5rem 0.8125rem 1.25rem;
  letter-spacing: 0.02em;
  color: #000 !important;
}
@media screen and (max-width: 48rem) {
  .select2 .select2-selection.select2-selection--single .select2-selection__rendered {
    font-size: 0.875rem;
    line-height: 1.375rem;
    padding: 0.5rem 2.5rem 0.5625rem 0.9375rem;
  }
}
.select2 .select2-selection.select2-selection--single .select2-selection__placeholder {
  color: #858585;
}
.select2 .select2-selection.select2-selection--single .select2-selection__arrow {
  width: 1.0625rem;
  height: 0.625rem;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
.select2 .select2-selection.select2-selection--single .select2-selection__arrow b {
  width: 1.0625rem;
  height: 0.625rem;
  background-size: contain;
}

.select2-dropdown {
  background: #fff;
  color: #000;
  box-shadow: none;
  border-radius: 0 0 0.25rem 0.25rem;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 0.375rem 0;
  border: 0.0625rem solid #284178;
  margin-top: -0.625rem;
}
.select2-dropdown .select2-results__option {
  padding: 0.5rem 1.25rem 0.5625rem 1.25rem;
  transition: all 0.2s ease-in-out;
}
.select2-dropdown .select2-results__option:hover {
  background: rgba(200, 175, 123, 0.2) !important;
}
.select2-dropdown .select2-results__option[aria-selected=true] {
  background: transparent;
}

body > .select2-container {
  width: auto;
  z-index: 99;
}

.field {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.field--select.has-error .select2-selection {
  border-color: #FF0000;
}
@media screen and (max-width: 48rem) {
  .field--select .select2 .select2-selection.select2-selection--single .select2-selection__rendered {
    padding: 0.8125rem 0.625rem 0.75rem 0.75rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
}
.field--checkbox + .field--checkbox {
  margin-top: 0.5rem;
}
.field__filezone {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 48rem) {
  .field__filezone {
    align-items: center;
  }
}
.field--file.field {
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 48rem) {
  .field--file.field {
    margin-bottom: 0.9375rem;
  }
}
.field--file-1.field {
  margin-bottom: 2.5625rem;
}
@media screen and (max-width: 48rem) {
  .field--file-1.field {
    margin-bottom: 0.9375rem;
  }
}
.field--file .field__label {
  background: #C0A468;
  padding: 0.5625rem 2.8125rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}
.field--file .field__label.has-error {
  border-color: #FF0000;
}
.field--file .field__label-text {
  position: static;
}
.field--file .field__label .field__input {
  padding: 0;
  position: absolute;
}
@media screen and (max-width: 48rem) {
  .field--file .field__label {
    padding: 0.375rem 1.5625rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}
.field--file .field__title {
  padding-top: 0.4375rem;
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: 300;
}
.field--file .field__title span {
  display: block;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #828282;
}
@media screen and (max-width: 48rem) {
  .field--file .field__title {
    padding-top: 0;
    font-size: 0.625rem;
    line-height: 0.875rem;
    font-family: "InterstateWGL-Light";
  }
  .field--file .field__title span {
    font-size: 0.5625rem;
    line-height: 0.75rem;
  }
}
.field__input, .field__placeholder, .field__file, .field__file .selected {
  font-weight: 300;
  font-family: "Roboto Condensed";
  font-size: 1.125rem;
  line-height: 1.375rem;
  padding: 0.8125rem 0.625rem 0.75rem 1.1875rem;
}
@media screen and (max-width: 48rem) {
  .field__input, .field__placeholder, .field__file, .field__file .selected {
    padding: 0.5625rem 0.625rem 0.5625rem 0.75rem;
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}
.field__input[type=file] {
  width: 0rem;
  height: 0rem;
  position: absolute;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  border: none;
}
.field__input, .field__file {
  display: block;
  width: 100%;
  border: none;
  border: 0.0625rem solid #B9B9B9;
  color: #373f44;
  border-radius: 0.25rem;
  box-sizing: border-box;
  background: transparent;
  appearance: none;
  outline: none;
}
.has-error .field__input, .has-error .field__file {
  border-color: #FF0000;
}
.field__input:focus, .field__file:focus {
  border-color: #284178;
}
.field__input:focus ~ .field__placeholder, .field__file:focus ~ .field__placeholder {
  opacity: 0;
  transform: translateX(20%);
}
.field__input[disabled], .field__file[disabled] {
  opacity: 0.5;
}
.field__placeholder, .field--file .selected {
  position: absolute;
  top: 0;
  left: 0;
  color: #858585;
  transition: opacity 0.3s, transform 0.3s;
  cursor: text;
}
.field--textarea {
  margin-bottom: 2.25rem;
}
.field--textarea textarea {
  height: 9.3125rem;
  width: 100%;
  border: 0.0625rem solid #B9B9B9;
  border-radius: 0.25rem;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 0.875rem 1.25rem 1.25rem 1.1875rem;
  color: #000;
  box-sizing: border-box;
  resize: none;
}
@media screen and (max-width: 48rem) {
  .field--textarea textarea {
    height: 4.0625rem;
    padding: 0.5625rem 1.25rem 1.25rem 0.6875rem;
  }
}
.field__error {
  position: absolute;
  left: 0;
  bottom: -1rem;
  width: 100%;
  display: none;
  opacity: 0;
  pointer-events: none;
  color: #FF0000;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  transition: opacity 0.2s ease-in-out;
}
@media screen and (max-width: 48rem) {
  .field__error {
    bottom: -0.875rem;
    font-size: 0.625rem;
  }
}
.field__text {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}
.field__label {
  position: relative;
  display: block;
}
.field__label.has-focus .field__placeholder, .field__label.has-value .field__placeholder {
  opacity: 0;
  pointer-events: none;
  transform: translateX(20%);
}
.field__label.has-error ~ .field__error {
  opacity: 1;
  display: block;
}
.field__label.has-error .field__error {
  opacity: 1;
}
.field__label--phone:hover .field__placeholder, .field__label--phone .field__input:hover .field__placeholder {
  display: none;
}
.field__label--phone .field__placeholder {
  width: 100%;
}
.field__label-text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
}

.field--radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.field--radio .field__wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.field--radio .field__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1.875rem;
  cursor: pointer;
}
.field--radio .field__label-text {
  margin-bottom: 0.875rem;
  position: static;
}
.field--radio .field__text {
  margin-top: 0;
}
.field--radio .field__box {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 0.0625rem solid #000;
  margin-right: 0.625rem;
}
.field--radio .field__box::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
}
.field--radio .field__input {
  border: none;
  position: absolute;
  opacity: 0;
}
.field--radio .field__input:checked + .field__label .field__box::before {
  background-color: #000;
}

.field--textarea .field__label-text, .field--textblock .field__label-text {
  position: static;
  margin-bottom: 0.5rem;
}

.field--checkbox.form__field {
  margin-bottom: 0;
}
.field--checkbox .field__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 48rem) {
  .field--checkbox .field__label {
    align-items: flex-start;
  }
}
.field--checkbox .field__check {
  width: 0.875rem;
  height: 0.6875rem;
  position: absolute;
  top: 0.375rem;
  left: 0.25rem;
  background: url("/img/check-thin.svg") 0 0 no-repeat;
  background-size: contain;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s ease-in-out;
}
.field--checkbox .field__input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.field--checkbox .field__input:checked ~ .field__check {
  opacity: 1;
}
.field--checkbox .field__box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 1.375rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid #284178;
  color: #000;
  background: transparent;
  margin-right: 1.0625rem;
  cursor: pointer;
}
.field--checkbox .field__box.has-error {
  border-color: #FF0000;
}
.field--checkbox .field__text {
  font-weight: 300;
  font-size: 0.875rem;
  font-family: "InterstateWGL-Light";
  line-height: 1.25rem;
  color: #000;
  margin-top: 0;
}
.field--checkbox .field__text a {
  color: inherit;
  text-decoration: underline;
}
.field--checkbox .field__text a:hover {
  text-decoration: none;
}
@media screen and (max-width: 48rem) {
  .field--checkbox .field__check {
    width: 0.625rem;
    height: 0.5rem;
    top: 0.1875rem;
    left: 0.125rem;
  }
  .field--checkbox .field__box {
    width: 0.875rem;
    height: 0.875rem;
    flex: 0 0 0.875rem;
    margin-right: 0.5rem;
  }
  .field--checkbox .field__text {
    font-size: 0.625rem;
    line-height: 0.75rem;
  }
}
.field--checkbox.is-checked .icon-check {
  opacity: 1;
}

.form__btns {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
@media screen and (max-width: 48rem) {
  .form__btns .btn {
    width: calc(50% - 0.1875rem) !important;
  }
  .form__btns .btn + .btn {
    margin-left: 0.375rem;
  }
}
.form__btns--center {
  justify-content: center;
}
.form__field {
  position: relative;
  display: block;
  margin-bottom: 1.8125rem;
}
@media screen and (max-width: 48rem) {
  .form__field {
    margin-bottom: 1.1875rem;
  }
}

.field-path {
  display: none;
}
._show-path .field-path {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.field-path__img {
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 1.25rem;
  border-radius: 0.25rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.field-path__path {
  max-width: 13.125rem;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 48rem) {
  .field-path__path {
    font-size: 0.625rem;
  }
}
.field-path__remove {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #000;
  margin-left: auto;
  cursor: pointer;
}

.c-tp-headline.headline,
.c-tp-headline--90,
.match .c-tp-headline {
  font-family: "InterstateWGL", Arial, Helvetica, Verdana, sans-serif !important;
  line-height: 1.45 !important;
  font-weight: 700;
  text-transform: none;
  font-size: 2.25rem;
  color: #21292F;
}

.c-tp-headline--30 {
  font-size: 1.125rem;
  font-weight: 700;
}

p {
  font-family: "InterstateWGL-Light";
  font-size: 1rem;
  line-height: 1.40625rem;
}
p:not(:last-child) {
  margin-bottom: 1.25rem;
}

.l-header-meta:after, .m-benefit:after, .m-metanav-list:after, .mainNav,
.m-sociallinks {
  content: "";
  display: table;
  clear: both;
}

.wrapper {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

.btn {
  display: inline-block;
  align-items: center;
  padding: 1rem 0.5rem 1rem 0.75rem !important;
  color: #21292F;
  font-family: "InterstateWGL";
  font-weight: 400;
  font-size: 1.25rem;
  background: #F8F6F2;
  border: 1px solid #DFDDD9;
  border-radius: 0.625rem 0 0.625rem 0;
  cursor: pointer;
}
.btn::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.625rem;
  height: 0.9375rem;
  margin: 0 0 0 0.3125rem;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat;
  background-size: contain;
}
.btn._no-arrow::after {
  display: none;
}
.btn._big {
  width: 14.8125rem;
  max-width: 100%;
  padding: 0.875rem !important;
  font-size: 1rem;
}
.btn._dark {
  background: #423C39;
  color: #F8F6F2;
}
@media screen and (max-width: 48rem) {
  .btn {
    padding: 0.5rem 0.5625rem 0.5rem 0.75rem !important;
    font-size: 1rem;
  }
  .btn._big {
    padding: 0.625rem !important;
    font-size: 0.75rem;
  }
}

.l-header-meta .h-gold.navItem a {
  color: #857650;
}
.l-header-meta .h-gold.navItem a:hover::after {
  background-color: #857650;
}
.l-header-meta .h-gold .active_menu:after {
  content: "";
  width: calc(100% - 3.125rem);
  background-color: #857650;
  height: 0.0625rem;
  position: absolute;
  bottom: 0;
  left: 1.625rem;
}
.l-header-meta .h-gold .active_menu:after:hover .h-gold .active_menu:after {
  content: "";
  width: calc(100% - 3.125rem);
  background-color: rgba(133, 118, 80, 0.32);
  height: 0.0625rem;
  position: absolute;
  bottom: 0;
  left: 1.625rem;
}
.l-header-meta .active_menu:after {
  content: "";
  width: calc(100% - 3.125rem);
  background-color: #373f44;
  height: 0.0625rem;
  position: absolute;
  bottom: 0;
  left: 1.625rem;
}
.l-header-meta .active_menu:after:hover .active_menu:after {
  content: "";
  width: calc(100% - 3.125rem);
  background-color: rgba(55, 63, 68, 0.32);
  height: 0.0625rem;
  position: absolute;
  bottom: 0;
  left: 1.625rem;
}

.headerWrapper {
  position: relative;
  min-width: 63.125rem;
  z-index: 11;
}

.js-tp-simplelist-item {
  position: absolute;
  top: 6.25rem;
  left: 1.25rem;
  width: 12.5rem;
  height: 1.25rem;
  background-color: pink;
}

.l-header {
  position: relative;
  z-index: 1000;
  background-color: #fff;
  padding-bottom: 0.3125rem;
}

.l-header-main {
  position: relative;
  background-color: #F8F6F2;
}

.l-header-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-top: 2.5rem;
}

.l-header-width {
  width: 81.25rem;
  max-width: 100%;
  margin: 0 auto;
}

.c-hamburger {
  display: none;
  width: 1.5625rem;
  height: 1.125rem;
  float: left;
  margin-top: 1rem;
}

.c-hamburger-btn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  padding: 0;
}

.c-hamburger-line {
  display: block;
  width: 100%;
  height: 0.1875rem;
  transform-origin: left;
  background: #373f44;
}

.c-hamburger-line:last-child {
  margin-bottom: 0.3125rem;
}

.l-logo {
  text-indent: -6249.9375rem;
  float: left;
  position: relative;
  z-index: 1;
  background-image: url("/img/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  display: block;
  width: 7.375rem;
  height: 2.6875rem;
  margin-right: 2.8125rem;
  margin-bottom: 1.8125rem;
}

.l-header-meta {
  min-height: 3.0625rem;
}

.m-metanav {
  position: relative;
  font-size: 1rem;
  margin-left: auto;
  color: #373f44;
  float: right;
}
.m-metanav__popup {
  display: none;
  position: absolute;
  top: 1.875rem;
  right: 0;
  justify-content: flex-end;
  z-index: 10;
}
.m-metanav__popup-container {
  position: relative;
  right: -0.375rem;
  width: 8.125rem;
  padding: 0.625rem 0.625rem 0.625rem 1rem;
  background: #FFF;
  line-height: 1.75rem;
  box-shadow: 0px -3px 14px 0px rgba(66, 60, 57, 0.24);
}
.m-metanav__popup-link {
  display: block;
  font-family: "InterstateWGL";
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #64696D;
}
.m-metanav__popup-link:hover {
  opacity: 0.7;
}
.m-metanav__popup-link > img {
  height: 100%;
  border: none;
}
.m-metanav__popup-item:not(:last-child) {
  margin-bottom: 0.4375rem;
}

.m-metanav-item {
  float: left;
  margin-left: 0.875rem;
  margin-bottom: 1.5625rem;
}

.m-metanav-link {
  display: flex;
  color: #000;
  padding-left: 1.5625rem;
  font-size: 1.125rem;
  font-family: "InterstateWGL-Light";
  line-height: 1.3;
  position: relative;
  cursor: pointer;
}

.m-metanav-link:hover {
  opacity: 0.8;
}

.m-metanav-link--contact::before {
  content: "";
  display: block;
  margin-bottom: 0.1875rem;
  width: 1.5rem;
  height: 1.375rem;
  background-image: url("/img/icons/icon-pin.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-metanav-link--buyonline::before {
  content: "";
  width: 1.25rem;
  height: 1.375rem;
  background-image: url("/img/icons/icon-buyonline.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0rem;
  top: -0.1875rem;
}

.m-metanav-link--faq::before {
  content: "";
  width: 1.5rem;
  height: 1.25rem;
  background-image: url("/img/icons/icon-faq.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0.25rem;
  top: 0.625rem;
}

.l-header-action {
  position: relative;
  clear: right;
}

.m-searchbar {
  padding: 0.25rem 0 0 12.375rem;
}

.m-searchbar-form {
  position: relative;
}

.headerSearchBox {
  width: 25.1875rem;
  position: relative;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.25rem;
  overflow: hidden;
}

.headerSearchBox fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.headerSearchKeywords {
  height: 2.8125rem;
  font-size: 1rem;
  width: 100%;
  padding: 0 0 0 1.25rem;
  background-color: transparent;
  line-height: 2.6875rem;
  border: 0;
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif;
}

.headerSearchButtonSubmit {
  width: 2.8125rem;
  height: 2.8125rem;
  text-indent: -9999em;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 0.0625rem solid #d9d9d9;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-image: url("/img/icons/icon-search.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 60%;
}

.block-title-price > a {
  transition: all 1s ease;
}

.block-title-price > a:hover {
  text-decoration: none;
  color: #6b6765;
  transition: all 1s ease;
}

.m-navigation {
  clear: left;
  height: 1.875rem;
}

.navLayer {
  position: static !important;
}

.mainNav .navItem {
  margin-bottom: 0;
}

.navItem {
  float: left;
  position: relative;
}

.navItem a,
.navItem span.switchOnHover {
  position: relative;
  display: block;
  padding: 0.0625rem 0 1.5625rem;
  margin-right: 2rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
  letter-spacing: 0.006em;
  color: #21292F;
  font-family: "InterstateWGL";
  font-weight: 400;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.navItem a.active,
.navItem.opened a,
.navItem.opened span,
.navItem a:hover,
.navItem span:hover,
.navItem span.active {
  text-shadow: 0.5px 0.5px 0px #21292F;
}

.navItem a.active::after,
.navItem a:hover::after,
.navItem.opened span::after,
.navItem.opened a::after,
.navItem span.switchOnHover:hover::after {
  content: "";
  width: 100%;
  background-color: #21292F;
  height: 0.1875rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.h-gold.navItem a:hover::after {
  background-color: #857650;
}

.navItem-break {
  clear: left;
}

.mobile-menu {
  display: none;
  position: absolute;
  width: 100%;
  background: #F8F6F2;
  z-index: 10;
  top: 0;
}
.mobile-menu__close {
  position: absolute;
  display: block;
  top: 8.3125rem;
  right: 2.5625rem;
  width: 1.3125rem;
  height: 1.3125rem;
  background-image: url("/img//icons/icon_close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  z-index: 100;
}
.mobile-menu__content {
  padding: 10.375rem 8.5rem 6.25rem;
  width: 100%;
  position: relative;
  display: flex;
  z-index: 2;
}
.mobile-menu__content .mainNav {
  width: 100%;
  padding: 0 2.3125rem;
  margin: 0 0 0.875rem;
  border-bottom: 1px solid #A6A9AC;
}
.mobile-menu__content .navItem {
  float: none;
  margin-bottom: 1.625rem;
  background: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 1L9 9L1 1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat top 0.8125rem right;
}
.mobile-menu__content .no-arrow {
  display: none;
}
.mobile-menu__content .navItem ul {
  display: none;
  padding-bottom: 0.9375rem;
}
.mobile-menu__content .navItem ul li {
  margin-bottom: 0.3125rem;
}
.mobile-menu__content .navItem ul a {
  font-size: 0.75rem;
  line-height: 1.3;
}
.mobile-menu__content .navItem ul a:hover {
  text-decoration: underline;
}
.mobile-menu__content .navItem a,
.mobile-menu__content .navItem span.switchOnHover {
  font-size: 1rem;
  padding-bottom: 0;
  line-height: 1.125rem;
  font-family: "InterstateWGL";
  color: #373f44;
}
.mobile-menu__content .navItem a.active::after,
.mobile-menu__content .navItem a:hover::after,
.mobile-menu__content .navItem span.switchOnHover:hover::after {
  content: none;
}
.mobile-menu .menu {
  flex: 0 0 40%;
  padding-right: 7.1875rem;
}
.mobile-menu .menu-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 1.625rem 2.1875rem;
}
.mobile-menu .menu-block {
  display: flex;
  flex-direction: column;
  width: 17.5rem;
  color: #FFF;
  font-size: 1.125rem;
  font-family: "InterstateWGL";
}
.mobile-menu .menu-block span {
  display: block;
  margin-bottom: 1rem;
}
.mobile-menu .menu-block img {
  max-height: 25vh;
  object-fit: cover;
}

/*-----block-input-search-----*/
#block-search {
  margin: 0 auto;
  width: 26.4375rem;
  height: 2.0625rem;
}

#input-search {
  height: 1.8125rem;
  width: 22.1875rem;
  border: 0.0625rem solid #D8D8D8;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

#input-search:focus {
  border: 0.0625rem solid #75D9FF;
}

#form-search {
  background: url(button-search-norm.png) no-repeat;
  width: 3.375rem;
  height: 2.0625rem;
  border: none;
  cursor: pointer;
  position: absolute;
  margin-left: -0.3125rem;
}

#form-search:hover {
  background: url(button-search-hover.png) no-repeat;
  width: 3.375rem;
  height: 2.0625rem;
}

/* Результат поиска */
#block-search {
  margin: 0 auto;
  width: 26.4375rem;
  height: 2.0625rem;
}

#input-search {
  height: 1.8125rem;
  width: 22.1875rem;
  border: 0.0625rem solid #D8D8D8;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

#input-search:focus {
  border: 0.0625rem solid #75D9FF;
}

#form-search {
  background: url(button-search-norm.png) no-repeat;
  width: 3.375rem;
  height: 2.0625rem;
  border: none;
  cursor: pointer;
  position: absolute;
  margin-left: -0.3125rem;
}

#form-search:hover {
  background: url(button-search-hover.png) no-repeat;
  width: 3.375rem;
  height: 2.0625rem;
}

/* Результат поиска */
#block-search-result {
  border-left: 0.0625rem solid #E1DFDD;
  border-right: 0.0625rem solid #E1DFDD;
  border-bottom: 0.0625rem solid #E1DFDD;
  width: 22.375rem;
  height: auto;
  background-color: white;
  position: absolute;
  z-index: 9999;
  display: none;
}

#list-search-result > li {
  border-bottom: 0.0625rem solid #EAEAEA;
  min-height: 6.25rem;
  height: auto;
  padding: 0.875rem;
  vertical-align: middle;
  transition: all 1s ease;
}

.block-title-price {
  width: 8.5rem;
  float: left;
  max-height: 4.375rem;
  height: 4.375rem;
  vertical-align: middle;
  line-height: 4.375rem;
  position: relative;
}

.block-title-price > a {
  display: block;
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.13;
  text-align: left;
  color: #b2aeac;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 8.5rem;
  height: 50%;
  margin: auto;
  overflow: hidden;
}

.block-title-price > a:hover {
  text-decoration: none;
}

.block-title-price > p {
  font: 0.9375rem sans-serif;
  color: black;
  margin: 0.625rem 0;
  display: block;
}

#search-noresult {
  color: #E96767;
  font: 0.8125rem sans-serif;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: inline-block;
}

.search-result-image {
  width: 4.375rem;
  float: right;
  max-height: 4.375rem;
  height: 4.375rem;
  vertical-align: middle;
}

.search-result-image img {
  max-height: 4.375rem;
  margin-right: 0.875rem;
  max-width: 4.375rem;
}

#search-more {
  color: #287FC3;
  font: 0.8125rem sans-serif;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: inline-block;
}

.title-price--gray {
  font-weight: bold;
}

.block-search-result--error {
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.13;
  text-align: left;
  color: #b2aeac;
  vertical-align: middle;
  margin-top: 1.25rem;
}

/*-----block-input-search-----*/
@media screen and (max-width: 64.375rem) {
  .navItem a.active::after,
.navItem a:hover::after,
.navItem span.switchOnHover:hover::after {
    content: none;
  }

  .m-tp-base-row {
    margin: 0;
  }

  .headerWrapper {
    min-width: 0;
    padding: 0 1.4375rem;
    background: #F8F6F2;
    border-top: 0.0625rem solid #e9e9e9;
  }

  .l-header-container {
    padding: 1.875rem 0 2.25rem;
    align-items: center;
  }

  .l-header-main .l-header-width {
    background: transparent;
    width: 100%;
  }

  .m-searchbar {
    display: none;
  }

  .c-hamburger {
    display: block;
    width: 2.3125rem;
    height: 1.875rem;
    margin-left: auto;
  }

  .l-logo {
    width: 7.375rem;
    height: 2.6875rem;
    margin: 0;
  }

  .m-metanav {
    display: none !important;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    margin-bottom: 0.3125rem;
  }

  .m-metanav-item {
    margin-left: 0;
  }
  .m-metanav-item:last-child {
    margin-left: 0.8vw;
  }

  .m-metanav-link {
    padding-left: 2.4375rem;
  }

  .m-metanav-link--contact::before {
    width: 1.6875rem;
    height: 1.4375rem;
    left: 0;
    top: 0.625rem;
  }

  .m-metanav-link--buyonline::before {
    width: 1.125rem;
    height: 1.625rem;
    left: 0.75rem;
    top: 0.1875rem;
  }

  .m-navigation {
    display: none;
  }

  .mobile-menu__close {
    display: none;
  }
  .mobile-menu__content {
    flex-direction: column;
    padding: 7.1875rem 1.4375rem 1.875rem;
  }
  .mobile-menu__content .no-arrow {
    display: block;
    background: none;
  }
  .mobile-menu__content .m-metanav {
    display: block !important;
    flex-direction: column;
    padding: 0 2.3125rem;
    margin-left: 0;
    float: left;
  }
  .mobile-menu__content .m-metanav-list {
    display: flex;
    flex-direction: column;
  }
  .mobile-menu__content .m-metanav-item {
    margin-bottom: 1.125rem;
  }
  .mobile-menu__content .m-metanav-link {
    padding-left: 1.875rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .mobile-menu__content .m-metanav-link--contact::before {
    position: absolute;
    left: 0rem;
    top: 0;
    height: 1.25rem;
  }
  .mobile-menu__content .m-metanav-link--buyonline::before {
    top: 0;
    left: 0;
    height: 1.25rem;
  }
  .mobile-menu__content .menu {
    flex: 0;
    width: 100%;
    padding-right: 0;
  }
}
.l-tp-footer {
  background: #fff;
}
.l-tp-footer-logo {
  height: 2.3125rem;
}
.l-tp-footer .footer-accordion {
  display: none;
}
.l-tp-footer .g-tp-flex {
  overflow: visible;
}

.l-tp-footer .g-tp-column--last {
  margin-top: auto;
  margin-left: auto;
  flex: 0 0 auto !important;
  overflow: visible;
  padding-bottom: 1.6875rem;
}

.l-tp-footer .m-tp-base-column {
  padding: 0 0.1875rem;
}

.l-tp-footer .m-tp-base {
  padding-bottom: 1.5625rem;
}

.l-tp-footer-gray {
  background: #F8F6F2;
  padding-top: 3.75rem;
  padding-bottom: 2.1875rem;
}

.l-tp-footer-content {
  width: 61.5rem;
  margin: 0 auto;
}

.l-tp-footer-content .g-tp-column--first {
  position: relative;
  flex: 0 0 17%;
}

.m-linklist {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "InterstateWGL";
  line-height: 1.4;
}

.c-tp-headline + .m-linklist {
  margin-top: 0.9375rem;
}

.l-tp-footer .g-tp-column--first .c-tp-headline + .m-linklist {
  padding-left: 0;
}

.m-linklist-item {
  margin-bottom: 0.25rem;
}

.l-tp-footer .m-tp-base-column--service .c-tp-headline {
  font-size: 1.125rem;
  font-family: "InterstateWGL-Light";
}

.l-tp-footer .m-tp-base-column--range {
  padding-left: 1.6875rem;
  padding-right: 0;
}

.l-tp-footer .m-tp-base-column--range .c-tp-headline {
  font-size: 1.125rem;
  font-family: "InterstateWGL-Light";
}

.m-linklist-link {
  text-decoration: none;
  color: #64696D;
  position: relative;
  padding-left: 1.125rem;
  display: inline-block;
}

.m-linklist-link:hover {
  text-decoration: underline;
}

.m-linklist-link::before {
  font-family: tp-icon;
  font-style: normal;
  speak: none;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  left: 0rem;
  top: 0.125rem;
  content: "\f107";
  font-size: 0.6875rem;
  font-weight: bold;
  text-decoration: none;
}

.m-sociallinks {
  float: left;
  margin-top: 0.7145em;
  margin-right: 1.5rem;
}

.m-sociallinks-item {
  float: left;
  margin-right: 1.1875rem;
}

.m-sociallinks-link {
  width: 2.1875rem;
  height: 2.1875rem;
  text-decoration: none;
  display: block;
  position: relative;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: #ffffff;
}

.m-sociallinks-link::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.m-sociallinks-link--facebook::before {
  width: 0.5625rem;
  height: 1.1875rem;
  background-image: url("/img/icons/icon-facebook.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: -0.25rem;
  margin-top: -0.5625rem;
}

.m-sociallinks-link--facebook:hover::before {
  background-image: url("/img/icons/icon-facebook-hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-sociallinks-link--vk::before {
  width: 1.1875rem;
  height: 0.6875rem;
  background-image: url("/img/icons/icon-vk.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: -0.5625rem;
  margin-top: -0.3125rem;
}

.m-sociallinks-link--vk:hover::before {
  background-image: url("/img/icons/icon-vk-hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-sociallinks-link--odnoklassniki::before {
  width: 0.75rem;
  height: 1.25rem;
  background-image: url("/img/icons/icon-odnoklassniki.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: -0.375rem;
  margin-top: -0.625rem;
}

.m-sociallinks-link--odnoklassniki:hover::before {
  background-image: url("/img/icons/icon-odnoklassniki-hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-sociallinks-link--instagram::before {
  width: 1.0625rem;
  height: 1.0625rem;
  background-image: url("/img/icons/icon-instagram.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: -0.5rem;
  margin-top: -0.5625rem;
}

.m-sociallinks-link--instagram:hover::before {
  background-image: url("/img/icons/icon-instagram-hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-sociallinks-link--youtube::before {
  width: 1.1875rem;
  height: 1.4375rem;
  background-image: url("/img/icons/icon-youtube.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: -0.5625rem;
  margin-top: -0.75rem;
}

.m-sociallinks-link--youtube:hover::before {
  background-image: url("/img/icons/icon-youtube-hover.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.m-metanav-list--footer {
  float: left;
}
.m-metanav-list--footer .m-metanav-item {
  position: relative;
  font-size: 0.875rem;
  margin-left: 0;
  margin-bottom: 0;
}
.m-metanav-list--footer .m-metanav-item:last-of-type {
  margin-left: 1.75rem;
}
.m-metanav-list--footer .m-metanav__popup {
  top: auto;
  bottom: 100%;
}
.m-metanav-list--footer .m-metanav-link {
  position: relative;
  display: flex;
  align-items: center;
  color: #64696D;
  font-weight: 400;
  font-size: 0.875rem;
  padding-left: 0rem;
  letter-spacing: 0.02em;
  position: relative;
}
.m-metanav-list--footer .m-metanav-link:hover {
  opacity: 0.8;
}
.m-metanav-list--footer .m-metanav-link--contact::before {
  content: "";
  width: 1.75rem;
  height: 1.5625rem;
  margin-top: 0.1875rem;
  margin-right: 0.375rem;
  margin-bottom: 0;
  background-image: url("/img/icons/icon-contacts-black.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-metanav-list--footer .m-metanav-link--buyonline::before {
  content: "";
  width: 1.5625rem;
  height: 1.75rem;
  margin-right: 0.625rem;
  position: relative;
  left: 0;
  top: 0;
  background-image: url("/img/icons/icon-buyonline-black.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.l-tp-footer .c-tp-separatorline {
  padding-bottom: 0.75rem;
}

.m-copyrights {
  color: #64696D;
  font-family: "InterstateWGL";
  font-size: 0.875rem !important;
  float: right;
  margin: 0.5rem 0 auto auto;
}

.m-tchibointernational {
  background: #ffffff;
}
.m-tchibointernational select {
  font-family: "Roboto Condensed" !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  padding-top: 0.25rem;
  padding-bottom: 0.375rem;
  padding-left: 1rem;
}

.l-tp-footer .m-tp-base .c-tp-headline + .m-tchibointernational {
  margin-top: 0.5rem;
}

.c-tp-formelement-select-container:after {
  background-color: #ffffff;
  right: 0.375rem;
}

.l-tp-footer .g-tp-column--international .m-tp-base {
  padding-bottom: 0.875rem;
}

.m-flags-item {
  float: left;
  margin-left: 0.6875rem;
}
.m-flags-item:first-of-type {
  margin-left: 0;
}
.m-flags-link {
  display: block;
  width: 3.1875rem;
  font-size: 1rem !important;
  color: #64696D;
  padding-left: 2rem;
  position: relative;
}
.m-flags-link:before {
  content: "";
  width: 1.625rem;
  height: 0.9375rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0.225rem;
  left: 0.0625rem;
}
.m-flags-link--de:before {
  background-image: url("/img/icon-de.png");
}
.m-flags-link--cz:before {
  background-image: url("/img/icon-cz.png");
}
.m-flags-link--ch:before {
  background-image: url("/img/icon-ch.png");
}
.m-flags-link--hu:before {
  background-image: url("/img/icon-hu.png");
}
.m-flags-link--pl:before {
  background-image: url("/img/icon-pl.png");
}
.m-flags-link--sk:before {
  background-image: url("/img/icon-sk.png");
}
.m-flags-link--at:before {
  background-image: url("/img/icon-at.png");
}
.m-flags-link--tr:before {
  background-image: url("/img/icon-tr.png");
}

.g-tp-row--footerBottom .c-tp-headline--70 {
  margin-bottom: 0.375rem;
}

@media screen and (max-width: 48rem) {
  .l-tp-footer-content {
    width: 100%;
  }
  .l-tp-footer-content .g-tp-column {
    width: 100%;
  }
  .l-tp-footer-content .g-tp-row {
    margin: 0;
  }
  .l-tp-footer .footer-nav-desk {
    display: none;
  }
  .l-tp-footer .footer-accordion {
    display: block;
  }
  .l-tp-footer .footer-accordion ul {
    display: none;
  }
  .l-tp-footer .g-tp-flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .l-tp-footer .c-tp-separatorline {
    max-width: 44.25rem;
    padding-bottom: 1.75rem;
    margin: 0 auto;
  }
  .l-tp-footer .l-tp-footer-logo {
    margin-bottom: 0.5rem;
  }
  .l-tp-footer .m-tp-base {
    padding-bottom: 1rem;
  }
  .l-tp-footer .m-flags-item {
    margin-left: 13%;
    margin-bottom: 0.625rem;
  }
  .l-tp-footer .m-flags-item:nth-of-type(5n), .l-tp-footer .m-flags-item:first-of-type {
    margin-left: 0;
  }
  .l-tp-footer .m-tp-base-column--range {
    padding-left: 0;
  }

  .l-tp-footer-gray {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
}
@media screen and (max-width: 48rem) {
  .l-tp-footer-content {
    padding: 0 0.75rem;
  }
  .l-tp-footer-content .g-tp-column--quarter {
    width: 48%;
    padding-top: 0.4375rem;
  }
  .l-tp-footer-content .g-tp-column--quarter:first-of-type {
    width: 52%;
  }
  .l-tp-footer-content .c-tp-headline + .m-linklist {
    padding-left: 0.5rem;
  }
  .l-tp-footer-content .m-flags {
    margin-top: 0.3125rem;
    margin-bottom: 0.9375rem;
  }
  .l-tp-footer-content .m-flags-item {
    margin-left: 10% !important;
  }
  .l-tp-footer-content .m-tp-base-column {
    padding: 0;
    text-align: left;
  }
  .l-tp-footer-content .c-tp-headline {
    max-width: 100%;
    line-height: 1.1;
  }
  .l-tp-footer-content .g-tp-column--quarter .c-tp-headline {
    padding-left: 1.9375rem;
  }
  .l-tp-footer-content .c-tp-headline::before {
    font-family: tp-icon;
    font-style: normal;
    speak: none;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 0rem;
    top: 0rem;
    transform: rotate(90deg);
    content: "\f107";
    font-size: 1.0625rem;
    font-weight: bold;
    text-decoration: none;
  }
  .l-tp-footer-content .m-linklist-link {
    padding-left: 1.25rem;
  }
  .l-tp-footer-content .c-tp-headline + .m-linklist {
    margin-bottom: 1.25rem;
  }
  .l-tp-footer-content .g-tp-column--half {
    width: 100%;
  }
  .l-tp-footer-content .g-tp-column--last {
    margin-left: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .l-tp-footer-content .m-sociallinks {
    float: none;
    margin-top: 1rem;
  }
  .l-tp-footer-content .m-metanav-list--footer {
    display: flex;
    width: 100%;
    float: none;
    margin-top: 1.5625rem;
    margin-left: 0;
    margin-bottom: 0.875rem;
  }
  .l-tp-footer-content .m-metanav-list--footer .m-metanav-link {
    padding-left: 0px;
    font-family: "InterstateWGL";
  }
  .l-tp-footer-content .m-metanav-list--footer .m-metanav-link--contact::before {
    width: 1.25rem;
  }
  .l-tp-footer-content .m-metanav-list--footer .m-metanav-link--buyonline::before {
    top: 0;
    width: 1.0625rem;
  }
  .l-tp-footer-content .m-metanav-item:last-child {
    margin-left: auto;
  }
  .l-tp-footer-content .m-copyrights {
    width: 100%;
    float: none;
    text-align: left;
    margin-top: 0.3125rem;
  }

  .l-tp-footer-gray {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 1.125rem;
  }
}
@media screen and (max-width: 23.4375rem) {
  .l-tp-footer-content .m-flags-item {
    margin-left: 10% !important;
  }
}
@media screen and (max-width: 48rem) and (min-width: 23.4375rem) {
  .l-tp-footer-content .m-flags-item {
    margin-left: 18%;
  }
}
.m-inner-page-nav-image {
  width: 100%;
  height: 13.75rem;
  object-fit: cover;
}
.g-tp-column--quarter .m-inner-page-nav-image {
  height: 18rem;
}
.m-inner-page-nav-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.9375rem 0.9375rem 1.75rem;
  background: #F8F6F2;
}
.m-info--full .m-inner-page-nav-content {
  padding: 1.6875rem 0.9375rem 1.4375rem;
}
.g-tp-column--third .m-inner-page-nav-content {
  padding-bottom: 1.375rem;
}
.m-inner-page-nav-header {
  display: block;
  margin-bottom: 0.3125rem !important;
  font-family: "InterstateWGL";
  font-weight: 700;
  font-size: 1.125rem;
}
.g-tp-column--third .m-inner-page-nav-header {
  margin-bottom: 0.625rem !important;
  letter-spacing: 0.02em;
}
.m-inner-page-nav-link {
  position: relative;
  color: #64696D;
  font-size: 1rem;
  font-family: "InterstateWGL-Light";
}
.m-inner-page-nav-link::before {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  left: 0;
  display: block;
  width: calc(100% - 1.375rem);
  height: 1px;
  background: #65696D4D;
}
.m-inner-page-nav-link::after {
  top: 50%;
  height: 0.75rem;
  width: 0.5rem;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%2365696D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.m-inner-page-nav-blocks {
  margin-bottom: 2.125rem;
}
.m-inner-page-nav-block {
  margin-bottom: 2.875rem;
}
.m-inner-page-nav-block-image {
  width: 100%;
  height: 20.625rem;
  object-fit: cover;
  object-position: top center;
}
.m-inner-page-nav-block-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8.75rem;
  background: #F8F6F2;
}
.m-inner-page-nav-block-content::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: block;
  width: 5rem;
  height: 5rem;
  background-image: url("/img//icons/icon_accordion.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-inner-page-nav-block-header {
  color: #423C39;
}

@media screen and (max-width: 48rem) {
  .m-inner-page-nav {
    margin-bottom: 1.25rem;
  }
  .m-inner-page-nav-image {
    width: 100%;
    height: 8.5rem;
    object-fit: cover;
  }
  .g-tp-column--quarter .m-inner-page-nav-image {
    height: 8.5625rem;
  }
  .m-inner-page-nav-content {
    padding: 0.8125rem 1rem 1.5625rem;
    background: #F8F6F2;
  }
  .g-tp-column--third .m-inner-page-nav-content {
    padding: 0.8125rem 1rem 1.5625rem !important;
  }
  .m-info--full .m-inner-page-nav-content {
    padding: 1.6875rem 1rem 1.4375rem;
  }
  .m-inner-page-nav-header {
    margin-bottom: 0rem !important;
    font-size: 1rem;
    font-family: "InterstateWGL";
    font-weight: 700;
  }
  .g-tp-column--third .m-inner-page-nav-header {
    margin-bottom: 0rem !important;
  }
  .m-inner-page-nav-link {
    position: relative;
    color: #64696D;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  .m-inner-page-nav-link::after {
    top: 50%;
    height: 0.75rem;
    width: 0.5rem;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%2365696D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  .m-inner-page-nav-link::before {
    width: calc(100% - 1.0625rem);
  }
  .m-cooking-methods .m-inner-page-nav-blocks .g-tp-column {
    padding: 0;
  }
  .m-inner-page-nav-block {
    margin-bottom: 1.6875rem;
  }
  .m-inner-page-nav-block-image {
    height: 10rem;
  }
  .m-inner-page-nav-block-content {
    height: 4.625rem;
    padding-top: 1.0625rem;
  }
  .m-inner-page-nav-block-content::before {
    width: 4rem;
    height: 4rem;
  }
  .m-inner-page-nav-block-content .headline {
    font-size: 1.375rem;
    letter-spacing: -0.02em;
  }
}
.text-block {
  position: relative;
  display: flex;
  align-items: stretch;
  margin-bottom: 5rem;
}
.text-block::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  display: block;
  z-index: -1;
}
.text-block.text-block--background::before {
  height: calc(100% - 5.375rem);
}
.text-block._dark::before {
  background: #423C39;
}
.text-block._light::before {
  background: #F8F6F2;
}
.text-block-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 50%;
  padding-left: 14.6875rem;
  padding-right: 3.75rem;
}
.text-block--background .text-block-text {
  padding-top: 2.6875rem;
  padding-bottom: 2.6875rem;
  margin-top: 2.0625rem;
  margin-bottom: 2.0625rem;
}
._right .text-block-text {
  padding-left: 5rem;
  padding-right: 9.375rem;
}
.text-block-text p.d-flex, .text-block-text li.d-flex {
  display: flex;
  align-items: flex-start;
}
.text-block-text p.d-flex img, .text-block-text li.d-flex img {
  width: 0.75rem;
  margin-right: 0.3125rem;
  margin-top: 0.1875rem;
  flex-shrink: 0;
}
.text-block-text p.d-flex:not(:last-child), .text-block-text li.d-flex:not(:last-child) {
  margin-bottom: 2.375rem;
}
._dark .text-block-text p, ._dark .text-block-text li {
  color: #E9D397;
}
.text-block-text li {
  font-family: "InterstateWGL-Light";
  font-size: 1rem;
  line-height: 1.375rem;
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 0 !important;
}
.text-block-text li::marker {
  font-size: 0.625rem;
}
.text-block-text li:not(:last-child) {
  margin-bottom: 1.25rem;
}
.text-block-headline {
  margin-bottom: 2.1875rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: 0.04em;
  font-weight: 700;
  color: #000;
}
._dark .text-block-headline {
  color: #FFF;
}
.text-block-img {
  display: block;
  flex: auto;
  min-height: 26.5625rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 48rem) {
  .text-block {
    flex-direction: column;
    padding: 0 1.625rem 0;
    margin-bottom: 0;
  }
  .text-block:last-of-type {
    margin-bottom: 2.5rem;
  }
  .text-block + .centerContent {
    padding-top: 2.5rem !important;
  }
  .text-block.text-block--background::before {
    height: 100%;
  }
  .text-block:first-of-type {
    margin-top: -2.75rem;
  }
  .text-block-text {
    padding-left: 0;
    padding-right: 0;
    padding-top: 2.5rem;
    margin-bottom: 1.5625rem;
  }
  .text-block-text p {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  .text-block-text p:empty {
    display: none;
  }
  .text-block-text p.d-flex:not(:last-child) {
    margin-bottom: 0.75rem;
  }
  .text-block-text p.d-flex img {
    width: 0.625rem;
    margin-right: 0.75rem;
  }
  .text-block--background .text-block-text {
    padding-top: 1.375rem;
    margin-bottom: 1.1875rem;
    margin-top: 0;
    padding-bottom: 0;
  }
  ._right .text-block-text {
    padding-left: 0px;
    padding-right: 0px;
  }
  .text-block-headline {
    margin-bottom: 0.9375rem;
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: 0.02em;
  }
  .text-block-img {
    order: 1;
    width: calc(100% + 1.625rem * 2);
    margin-left: -1.625rem;
    min-height: auto;
    height: 84vw;
    flex-shrink: 0;
  }
  .CoffeeBeans .text-block-img, ._img-order-first-mob .text-block-img {
    order: -1;
  }
}

.news-block {
  position: relative;
  display: flex;
  align-items: stretch;
  margin-bottom: 5rem;
}
.news-block::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  background: #F8F6F2;
  display: block;
  z-index: -1;
}
.news-block-text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 52%;
  padding-top: 5.375rem;
  padding-bottom: 5.375rem;
  padding-left: 14.6875rem;
  padding-right: 3.75rem;
}
._right .news-block-text {
  padding-left: 5rem;
  padding-right: 9.375rem;
}
.news-block-text::after {
  content: "";
  position: absolute;
  top: -1rem;
  left: 14.6875rem;
  transform: translateX(-100%);
  display: block;
  width: 4.875rem;
  height: 4.875rem;
}
.news-block--new .news-block-text::after {
  background-image: url("/img//page-news/new.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.news-block--promotion .news-block-text::after {
  background-image: url("/img//page-news/promotion.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.news-block-text p.d-flex, .news-block-text li.d-flex {
  display: flex;
  align-items: flex-start;
}
.news-block-text p.d-flex img, .news-block-text li.d-flex img {
  margin-right: 0.3125rem;
  margin-top: 0.1875rem;
  flex-shrink: 0;
}
.news-block-text p.d-flex:not(:last-child), .news-block-text li.d-flex:not(:last-child) {
  margin-bottom: 2.375rem;
}
._dark .news-block-text p, ._dark .news-block-text li {
  color: #E9D397;
}
.news-block-text li {
  font-family: "InterstateWGL-Light";
  font-size: 1rem;
  line-height: 1.375rem;
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 0 !important;
}
.news-block-text li::marker {
  font-size: 0.625rem;
}
.news-block-text li:not(:last-child) {
  margin-bottom: 1.25rem;
}
.news-block-date {
  margin-bottom: 0.625rem;
  font-size: 1rem;
  font-family: "InterstateWGL-Light";
  color: #9F9A97;
}
.news-block-headline {
  margin-bottom: 2.1875rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: 0.04em;
  font-weight: 700;
  color: #000;
}
._dark .news-block-headline {
  color: #FFF;
}
.news-block-img {
  display: block;
  flex: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 48rem) {
  .news-block {
    flex-direction: column;
  }
  .news-block-date {
    font-size: 0.75rem;
  }
  .news-block-text {
    padding: 1.5625rem 1.6875rem 1.875rem;
  }
  .news-block-text p, .news-block-text li {
    font-size: 0.875rem;
    line-height: 1.3;
  }
  .news-block-text p:not(:last-child), .news-block-text li:not(:last-child) {
    margin-bottom: 0.9375rem;
  }
  .news-block-text::after {
    top: -1.625rem;
    left: auto;
    right: 2.0625rem;
    transform: translateX(0);
    display: block;
    width: 3.5rem;
    height: 3.5rem;
  }
  .news-block--new .news-block-text::after {
    background-image: url("/img//page-news/new-reverse.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .news-block--promotion .news-block-text::after {
    background-image: url("/img//page-news/promotion-reverse.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .news-block-headline {
    margin-bottom: 0.9375rem;
    font-size: 1.5rem;
    line-height: 1.6875rem;
  }
  .news-block-img {
    height: 15rem;
  }
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16.6875rem;
  padding: 1.875vh 0.625rem 5vh;
  background: #F8F6F2;
}
.product.product-third-width {
  width: 32%;
}
.product:not(:last-child) {
  margin-right: 1.25rem;
}
.product-img {
  max-width: 80%;
  height: 29.125vh;
  margin-bottom: 1.125rem;
  object-fit: contain;
}
.product-name {
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  font-family: "InterstateWGL";
  color: #828385;
}
.product-type {
  margin-bottom: 0.9375rem;
  font-family: "InterstateWGL";
  font-weight: 700;
}
.product-saturation {
  display: flex;
  align-items: center;
  color: #8B8B8A;
}
.product-saturation-level {
  display: flex;
  align-items: center;
  margin-left: 0.625rem;
}
.product-saturation-level-bean {
  width: 1rem;
  height: 1rem;
  background-image: url("/img//products/bean-1.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.product-saturation-level--1 .product-saturation-level-bean:nth-child(-n+1) {
  background-image: url("/img//products/bean-2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.product-saturation-level--2 .product-saturation-level-bean:nth-child(-n+3) {
  background-image: url("/img//products/bean-2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.product-saturation-level--3 .product-saturation-level-bean:nth-child(-n+3) {
  background-image: url("/img//products/bean-2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.product-saturation-level--4 .product-saturation-level-bean:nth-child(-n+4) {
  background-image: url("/img//products/bean-2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.product-saturation-level--5 .product-saturation-level-bean:nth-child(-n+5) {
  background-image: url("/img//products/bean-2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.product-saturation-level--6 .product-saturation-level-bean:nth-child(-n+6) {
  background-image: url("/img//products/bean-2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 48rem) {
  .product {
    width: calc(100vw - 3.125rem);
    flex-shrink: 0;
    padding: 0.625rem 1.25rem 2.5rem;
  }
  .product-img {
    height: 14.0625rem;
    margin-bottom: 1.25rem;
  }
  .product-name {
    font-size: 0.75rem;
  }
  .product-type {
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
  }
  .product-saturation {
    font-size: 0.75rem;
  }
}

.InnerPage {
  position: relative;
}
.InnerPage.CoffeeBerries .m-inner-page-header {
  margin-bottom: 0;
}
.InnerPage.Roasting .m-inner-page-header {
  margin-bottom: 2.5rem;
}
.InnerPage .BannersNav {
  margin-bottom: 5rem;
}
.InnerPage .centerContent {
  position: relative;
  width: 70.875rem;
}
.InnerPage .m-inner-page {
  padding-bottom: 7.5rem;
}
.InnerPage .m-inner-page-header {
  position: relative;
  width: 100%;
  height: 31.75rem;
  margin-bottom: 5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  z-index: 2;
}
.InnerPage .m-inner-page-header::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(360deg, rgba(17, 12, 0, 0.71) -18.95%, rgba(58, 42, 7, 0) 113.02%);
}
.InnerPage .m-inner-page-header-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.InnerPage .m-inner-page-header-decor {
  position: absolute;
  bottom: 1.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 52%;
  height: 3.375rem;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3.375rem;
}
.InnerPage .m-inner-page-header-decor::before, .InnerPage .m-inner-page-header-decor::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 40%;
  height: 1px;
  background: #FFF;
}
.InnerPage .m-inner-page-header-decor::after {
  right: 0;
}
.InnerPage .m-inner-page-header .centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.InnerPage .m-inner-page-title {
  margin-bottom: 1.0625rem;
  font-size: 2.25rem;
  line-height: 2.4375rem !important;
  letter-spacing: 0.03em;
  color: #000;
  font-weight: 700;
  text-align: center;
}
.InnerPage .m-inner-page-subtitle {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 1.625rem;
  font-family: "InterstateWGL";
  text-align: center;
}
.InnerPage .m-inner-page-headline {
  color: #FFF;
  font-family: "InterstateWGL";
}
.InnerPage .m-inner-page-back {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.3125rem;
  top: 2.8125rem;
  width: 4.75rem;
  padding-bottom: 0.75rem !important;
  transition: 0.4s;
  border-radius: 0 0.625rem 0 0.625rem;
}
.InnerPage .m-inner-page-back::after {
  width: 2.6875rem;
  height: 1.125rem;
  margin: 0;
  background-image: url("/img//icons/icon_arrow-back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.InnerPage .m-inner-page-back:hover {
  background: #E9D397;
}
.InnerPage .m-inner-page-variants {
  text-align: center;
}
.InnerPage .m-inner-page-variants-list {
  margin-top: 3.4375rem;
}
.InnerPage .m-inner-page-variant {
  display: flex;
  flex: 0 0 25%;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 5rem;
}
.InnerPage .m-inner-page-variant-icon {
  margin-right: 1rem;
  flex-shrink: 0;
}
.InnerPage .m-inner-page-variant-title {
  margin-bottom: 0.4375rem;
  font-size: 1.125rem;
  font-family: "InterstateWGL";
}
.InnerPage .m-inner-page-variant-text {
  font-family: "InterstateWGL-Light";
  font-size: 1rem;
}
.InnerPage .m-inner-page-note {
  padding: 1.875rem 0;
  margin-bottom: 5rem;
  color: #E9D397;
  background: #423C39;
  font-family: "InterstateWGL-Light";
  font-size: 1rem;
}
.InnerPage .m-inner-page-note-row {
  display: flex;
  align-items: center;
}
.InnerPage .m-inner-page-note-img {
  flex-shrink: 0;
  width: 3.125rem;
  margin-right: 1.875rem;
}
.InnerPage .m-inner-page-note-text {
  padding-right: 1.875rem;
  font-size: 1rem;
  line-height: 1.25rem;
}
@media screen and (max-width: 48rem) {
  .InnerPage .g-tp-column {
    padding: 0 1.875rem;
  }
  .InnerPage .centerContent {
    position: relative;
    width: 100%;
    padding: 0 1.625rem;
  }
  .InnerPage .m-inner-page {
    padding-bottom: 5.1875rem;
  }
  .InnerPage .m-inner-page-back {
    top: 2.5625rem;
    left: 0.6875rem;
  }
  .InnerPage .m-inner-page-back::after {
    width: 3rem;
    height: 1.5625rem;
  }
  .InnerPage .m-inner-page-header {
    height: 28rem;
    margin-bottom: 2.9375rem;
  }
  .InnerPage .m-inner-page-header-decor {
    width: 85%;
    height: 4.375rem;
    bottom: 1rem;
    background-size: 3rem;
  }
  .InnerPage .m-inner-page-header-decor::before, .InnerPage .m-inner-page-header-decor::after {
    top: 65%;
    width: 35%;
  }
  .InnerPage .m-inner-page-headline {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: 700;
    max-width: 80%;
    margin: auto;
  }
  .InnerPage .m-inner-page-title {
    margin-bottom: 0.9375rem;
    font-size: 1.4375rem;
    line-height: 1.625rem !important;
    letter-spacing: 0.05em;
  }
  .InnerPage .m-inner-page-title br {
    display: none;
  }
  .InnerPage .m-inner-page-subtitle {
    margin-bottom: 0.9375rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  .InnerPage .m-inner-page-subtitle br {
    display: none;
  }
  .InnerPage .m-inner-page-variants {
    padding-top: 1.5625rem;
    padding-bottom: 1.25rem;
  }
  .InnerPage .m-inner-page-variants.flexbox .g-tp-flex {
    flex-direction: column;
  }
  .InnerPage .m-inner-page-variants-list {
    margin-top: 0.8125rem;
  }
  .InnerPage .m-inner-page-variant {
    width: 100%;
    margin-bottom: 1.25rem;
  }
  .InnerPage .m-inner-page-variant-icon {
    width: 3.0625rem;
  }
  .InnerPage .m-inner-page-variant-title {
    margin-bottom: 0.625rem;
  }
  .InnerPage .m-inner-page-variant-text {
    font-size: 1rem;
    line-height: 1.09;
    letter-spacing: -0.05em;
  }
  .InnerPage .m-inner-page-note {
    padding: 2.0625rem 0;
    margin-bottom: 0;
  }
  .InnerPage .m-inner-page-note-row {
    flex-direction: column;
  }
  .InnerPage .m-inner-page-note-img {
    width: 3.25rem;
    margin-right: 0;
    margin-bottom: 1.5625rem;
  }
  .InnerPage .m-inner-page-note-text {
    padding-right: 0;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02em;
  }
  .InnerPage .m-inner-page-note-text br {
    display: none;
  }
  .InnerPage .Banners .g-tp-row,
.InnerPage .BannersNav .g-tp-row {
    width: 100%;
    display: flex;
  }
  .InnerPage .Banners .g-tp-row .mCSB_container,
.InnerPage .BannersNav .g-tp-row .mCSB_container {
    gap: 0 1.25rem;
  }
  .InnerPage .Banners .g-tp-row .mCSB_container .m-inner-page-nav,
.InnerPage .BannersNav .g-tp-row .mCSB_container .m-inner-page-nav {
    margin-bottom: 0;
  }
  .InnerPage .Banners .g-tp-row::-webkit-scrollbar,
.InnerPage .BannersNav .g-tp-row::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 0.4375rem;
  }
  .InnerPage .Banners .g-tp-row::-webkit-scrollbar:horizontal,
.InnerPage .BannersNav .g-tp-row::-webkit-scrollbar:horizontal {
    -webkit-appearance: none;
    width: 7px;
    height: 0.4375rem;
  }
  .InnerPage .Banners .g-tp-row::-webkit-scrollbar-track,
.InnerPage .BannersNav .g-tp-row::-webkit-scrollbar-track {
    background: #CED0D1 !important;
    border-radius: 10px;
    height: 0.4375rem !important;
  }
  .InnerPage .Banners .g-tp-row::-webkit-scrollbar-thumb,
.InnerPage .BannersNav .g-tp-row::-webkit-scrollbar-thumb {
    background: #22292E;
    border-radius: 10px;
    height: 0.4375rem !important;
    border: 1px solid #CED0D1;
  }
  .InnerPage .Banners .g-tp-column,
.InnerPage .BannersNav .g-tp-column {
    width: calc(100vw - 3.125rem);
    flex-shrink: 0;
    padding: 0;
  }
  .InnerPage .Banners .g-tp-column--quarter,
.InnerPage .BannersNav .g-tp-column--quarter {
    width: 100%;
  }
}

.Roasting {
  position: relative;
}
.Roasting::before {
  content: "";
  width: 100%;
  height: 31.875rem;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #423C39;
  z-index: -1;
}
.Roasting .slider-roasting.slick-dotted.slick-slider {
  display: none;
}
@media screen and (max-width: 48rem) {
  .Roasting .slider-roasting.slick-dotted.slick-slider {
    display: block;
    margin-bottom: 2.8125rem;
  }
}
@media screen and (max-width: 48rem) {
  .Roasting::before {
    height: 7.1875rem;
  }
  .Roasting .m-roasting-types-desktop-only {
    display: none;
  }
}

.m-roasting-types {
  position: relative;
  margin-bottom: 1.9375rem;
}
.m-roasting-types::before {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #F8F6F2;
}
.m-roasting-type {
  padding: 15.625rem 1.875rem 0;
  text-align: center;
  background-position: right -1.875rem top 0.3125rem !important;
  background-size: 110% !important;
}
.m-roasting-type--light {
  background-image: url("/img//page-roasting/type_1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-type--middle {
  background-image: url("/img//page-roasting/type_2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-type--dark {
  background-image: url("/img//page-roasting/type_3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-type--darken {
  background-image: url("/img//page-roasting/type_4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-type-headline {
  margin-bottom: 0.75rem !important;
}
.m-roasting-type-text {
  font-family: "InterstateWGL-Light";
  line-height: 1.15;
}
.m-roasting-navs {
  position: relative;
  padding-top: 2.9375rem;
  margin-bottom: 2.875rem;
}
.m-roasting-navs::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 42.2%;
  height: 59%;
  display: block;
  background: #F8F6F2;
}
.m-roasting-navs .g-tp-flex {
  gap: 0 1.5625rem;
}
.m-roasting-nav {
  position: relative;
  width: auto;
  flex: auto;
  padding: 15.3125rem 0 0;
  text-align: center;
  background-size: cover !important;
}
.m-roasting-nav--light {
  background-image: url("/img//page-roasting/type_1-1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-nav--middle {
  background-image: url("/img//page-roasting/type_2-1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-nav--dark {
  background-image: url("/img//page-roasting/type_3-1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-nav--darken {
  background-image: url("/img//page-roasting/type_4-1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-roasting-nav-headline {
  width: 100%;
  height: 8.25rem;
  padding-bottom: 0.625rem !important;
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  background: #F8F6F2;
}
.m-roasting-nav-headline::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  height: 5rem;
  background-image: url("/img/icons/icon_arrow-round.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F8F6F2;
  background-size: 3.75rem;
  border-radius: 50%;
  z-index: 1;
}
@media screen and (max-width: 48rem) {
  .m-roasting-types::before {
    height: 13.125rem;
    top: -2.8125rem;
  }
  .m-roasting-type, .m-roasting-nav {
    display: block;
    width: 100%;
  }
  .m-roasting-type {
    margin-bottom: 2.1875rem;
    background-position: right -2.8125rem top 0.4375rem !important;
    background-size: 115% !important;
  }
  .m-roasting-type-headline {
    margin-bottom: 0.3125rem !important;
  }
  .m-roasting-type-text {
    font-size: 0.875rem;
  }
  .m-roasting-nav {
    padding-top: 10.9375rem;
  }
  .m-roasting-nav-headline {
    height: 5.5rem;
    font-size: 1rem;
    padding-top: 0.9375rem;
    padding-bottom: 0 !important;
  }
}

.m-varieties-type {
  position: relative;
  padding-top: 0.8125rem;
  padding-bottom: 8.125rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.m-varieties-type._full-width-bg {
  background-size: 100%;
}
.m-varieties-type-headline {
  margin-bottom: 0.4375rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.25rem;
  line-height: 2.4375rem;
  font-weight: 700;
}
.m-varieties-type-img-mobile {
  display: none;
}
.m-varieties-type-text {
  max-width: 55%;
  margin: auto;
  text-align: center;
}
.m-varieties-type-list {
  margin-top: 2.5rem;
  flex: 0 0 32%;
}
.m-varieties-type-list-wrapper {
  min-height: 16.25rem;
  align-items: center;
}
.m-varieties-type-list-icon {
  width: 3rem;
  margin-right: 1rem;
  flex-shrink: 0;
}
.m-varieties-type-list-item {
  display: flex;
  margin-bottom: 1.0625rem;
  align-items: flex-start;
}
.m-varieties-type-list-text span {
  display: block;
  margin: 0.1875rem 0 0.3125rem;
  font-weight: 400;
  font-family: "InterstateWGL";
  line-height: 1.2;
  font-size: 1.125rem;
}
.m-varieties-type-list-text p {
  font-size: 1rem;
  font-family: "InterstateWGL-Light";
}
.m-varieties-beans-about {
  width: 100%;
  padding: 6.25rem 0 15.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/img/page-varieties/beans.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100%;
}
.m-varieties-beans-about-text {
  width: 22.625rem;
  max-width: 100%;
  margin-left: 10%;
}
.m-varieties-beans-text-block .text-block-img {
  min-height: 39.6875rem;
}
@media screen and (max-width: 48rem) {
  .m-varieties-type {
    padding-top: 0;
    padding-bottom: 0.9375rem;
    background: none !important;
  }
  .m-varieties-type-img-mobile {
    position: absolute;
    left: 0;
    top: 3.125rem;
    width: calc(100% + 1.625rem * 2);
    left: -1.625rem;
    display: block;
    z-index: -1;
  }
  .m-varieties-type-text {
    max-width: 100%;
  }
  .m-varieties-type-headline {
    margin-bottom: 0.9375rem;
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
  .m-varieties-type-list {
    width: 100%;
    flex: auto;
    margin-top: 0;
  }
  .m-varieties-type-list-wrapper {
    padding-top: 16.25rem;
    flex-direction: column !important;
  }
  .m-varieties-type-list-item {
    margin-bottom: 0.9375rem;
  }
  .m-varieties-type-list-item:empty {
    display: none;
  }
  .m-varieties-type-list-text span {
    margin-top: 0;
    font-size: 1rem;
  }
  .m-varieties-type-list-text p {
    line-height: 1.1;
  }
  .m-varieties-beans-about {
    padding-top: 0;
    background-image: url("/img/page-varieties/beans-mob.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
  }
  .m-varieties-beans-text-block {
    padding-top: 2.5rem;
  }
  .m-varieties-beans-text-block .text-block-img {
    min-height: 25rem;
    background-image: url("/img/page-varieties/img-7-mob.png") !important;
    background-size: 100%;
  }
}

.m-grain-processing-headline.headline {
  margin-bottom: 1rem;
  line-height: 2.4375rem !important;
  text-align: center;
  color: #000;
}
.m-grain-processing-subtitle {
  margin-bottom: 4.0625rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.625rem;
  font-family: "InterstateWGL";
}
.m-grain-processing-accordion-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 1.4375rem;
  width: 100%;
  height: 17.5625rem;
  background-size: cover;
  background-position: center;
  text-align: center;
  cursor: pointer;
}
.m-grain-processing-accordion-label::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(87, 56, 28, 0) 0%, #57381C 100%);
}
.m-grain-processing-accordion-label span {
  position: relative;
  margin-bottom: 1.875rem;
  font-size: 2.25rem;
  color: #FFF;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-family: "InterstateWGL";
  z-index: 1;
}
.m-grain-processing-accordion-label img {
  position: relative;
  z-index: 1;
  transition: 0.4s;
}
.active .m-grain-processing-accordion-label img {
  transform: rotate(180deg);
}
.m-grain-processing-accordion-trigger {
  display: none;
}
.m-grain-processing-accordion-trigger:checked + div {
  display: block;
}
.m-grain-processing-accordion-content {
  display: none;
  padding: 3.4375rem 0;
  background: #F8F6F2;
}
.m-grain-processing-accordion-content-text {
  width: 65%;
  margin: auto;
}
.m-grain-processing-stages {
  display: flex;
  justify-content: center;
  padding: 2.875rem 0 5rem;
}
.m-grain-processing-stage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m-grain-processing-stage:not(:last-of-type) {
  margin-right: 4.375rem;
}
.m-grain-processing-stage:not(:last-of-type)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% + 2.1875rem);
  transform: translate(-50%, -50%);
  display: block;
  width: 1rem;
  height: 2rem;
  background-image: url("/img//icons/icon_arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.m-grain-processing-stage-image {
  width: 10.75rem;
  margin-bottom: 1.25rem;
}
.m-grain-processing-stage span {
  font-size: 1.5rem;
}
@media screen and (max-width: 48rem) {
  .m-grain-processing-accordion-label {
    height: 13.75rem;
    padding-top: 1.875rem;
    padding-bottom: 1.625rem;
  }
  .m-grain-processing-accordion-label span {
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.02em;
  }
  .m-grain-processing-accordion-label img {
    width: 4.125rem;
  }
  .m-grain-processing-accordion-content {
    padding: 2rem 1.6875rem;
  }
  .m-grain-processing-accordion-content-text {
    width: 100%;
  }
  .m-grain-processing-accordion-content-text p {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
  .m-grain-processing-stages {
    flex-direction: column;
    padding: 1.5rem 0 3.875rem;
  }
  .m-grain-processing-stage-image {
    width: 11rem;
  }
  .m-grain-processing-stage span {
    font-size: 1.125rem;
  }
  .m-grain-processing-stage:not(:last-of-type) {
    margin-right: 0;
    padding-bottom: 3.375rem;
  }
  .m-grain-processing-stage:not(:last-of-type)::before {
    width: 1.875rem;
    height: 2.5rem;
    bottom: 0.3125rem;
    left: 50%;
    top: auto;
    transform: translateX(-50%) rotate(90deg);
  }
}

.m-cooking-methods-top {
  position: relative;
}
.m-cooking-methods-top::before {
  content: "";
  display: block;
  width: 100%;
  height: calc(100% + 5rem + 2.6875rem);
  position: absolute;
  top: -5rem;
  left: 0;
  background: #F8F6F2;
  z-index: -1;
}

.centerWrapper {
  position: relative;
}

.centerContent {
  width: 60.9375rem;
  overflow: hidden;
  padding-top: 0;
}

.Stagesliderfullwidth .m-tp-swiper {
  padding-bottom: 0rem;
}
.Stagesliderfullwidth .m-tp-swiper-container {
  margin-bottom: 0;
}
.Stagesliderfullwidth .g-tp-row {
  margin: 0;
}
.Stagesliderfullwidth .g-tp-column {
  padding: 0;
}
.Stagesliderfullwidth .swiper-slide-img-mobile,
.Stagesliderfullwidth .swiper-slide-img-small {
  display: none;
}
.Stagesliderfullwidth .swiper-slide video {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.Stagesliderfullwidth .swiper-slide video.swiper-slide-video-mobile {
  display: none;
}
.Stagesliderfullwidth .swiper-slide video.swiper-slide-video-desktop {
  display: block;
}

@media screen and (max-height: 660px), screen and (orientation: landscape) {
  .Stagesliderfullwidth .swiper-slide-img-small {
    display: block !important;
  }

  .Stagesliderfullwidth .swiper-slide-img-desktop,
.swiper-slide-video-desktop {
    display: none;
  }
}
.AboutCompany {
  padding: 6rem 0 2.75rem;
  background: #F8F6F2;
}
.AboutCompany .c-tp-headline.headline {
  line-height: 1.1 !important;
  letter-spacing: 0.03em;
}
.AboutCompany .c-tp-copytext {
  margin-top: 1.5625rem;
  margin-bottom: 2.5rem;
}

.ProductsBlock {
  padding: 4.6875rem 0 4.8125rem;
}
.ProductsBlock-content {
  display: flex;
  justify-content: center;
}
.ProductsBlock-content .m-tp-teaser002 .c-tp-headline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  font-size: 1rem !important;
  font-weight: 300 !important;
  text-transform: none;
}
.ProductsBlock-content .m-tp-teaser002 .c-tp-headline.c-tp-headline--arrow:after {
  position: static;
  height: 0.9375rem;
  width: 1.375rem;
  margin-left: 0;
  margin-top: 0.9375rem;
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='14' viewBox='0 0 25 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.6006 0.999924L12.4756 12.7104L1.35059 0.999924' stroke='black' stroke-width='1.17105' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-size: contain;
}

.m-tp-teaser002 .c-tp-headline {
  font-size: 1.125rem !important;
  font-weight: bold !important;
  text-transform: none;
}

.AboutCompany .m-tp-textblock,
.NewsMain .m-tp-textblock {
  padding-bottom: 0.625rem;
  padding-left: 0.375rem;
  padding-right: 3.125rem;
}

.ProductsBlock .m-tp-textblock {
  padding-bottom: 1.5625rem;
  letter-spacing: 0.05em;
}

.m-home-nav {
  justify-content: center;
  gap: 0 1rem;
  padding: 2.875rem 0 2.25rem;
}
.m-home-nav-link.c-tp-headline--arrow {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem 1rem 0.75rem !important;
  color: #21292F;
  font-family: "InterstateWGL";
  font-weight: 400;
  font-size: 1.25rem;
  background: #F8F6F2;
  border: 1px solid #DFDDD9;
  border-radius: 0.625rem 0 0.625rem 0;
}
.m-home-nav-link.c-tp-headline--arrow::after {
  position: relative !important;
  width: 0.625rem;
  height: 0.9375rem;
  margin: 0.125rem 0 0 0.375rem;
}

.m-about-qs {
  line-height: 1.429;
  font-weight: normal;
  margin: 0;
  color: #21292F;
  padding: 0;
}

.m-about-qs-headline {
  display: table;
  table-layout: fixed;
  min-height: 16rem;
  width: 100%;
  background-image: url("/img//page-main/main-page-about.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
}

.m-about-qs-content {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.m-about-qs-headline .m-about-qs-years-switch {
  display: table-cell;
  position: relative;
  width: 20%;
  padding: 0;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
}

.m-about-qs-headline .m-about-qs-years-switch::before {
  display: block;
  content: "";
  background-position: center center;
  background-repeat: no-repeat;
  height: 9.0625rem;
}

.m-about-qs-headline .m-about-qs-years-switch span {
  display: inline-block;
  margin: 0.5em;
  margin-top: 0;
  padding-bottom: 0.25em;
  border-bottom: solid transparent 0.125rem;
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  color: #373f44;
  text-align: center;
}

.m-about-qs-headline .m-about-qs-years-switch span:before {
  content: "";
  display: block;
  box-sizing: content-box;
  width: 2rem;
  height: 2rem;
  margin: -1.0625rem auto;
  margin-bottom: 0.75rem;
  border: 0.0625rem solid #ccc;
  border-radius: 50%;
  background-color: #ffffff;
  transition: all 0.2s;
  background-image: url("/img/icon_plus.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 46%;
  background-position: center;
  background-repeat: no-repeat;
}

.m-about-qs-years-switch--visible {
  transition: all 1s;
}

.m-about-qs-headline .m-about-qs-years-switch--visible span:before {
  background-image: url("/img/js_tp_button_close-gold.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 38%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s;
}

.m-about-qs-headline .m-about-qs-years-switch--visible span {
  border-bottom: 0.125rem solid #837650;
  transition: all 1s;
}

.m-about-qs-descriptions .copytext {
  padding-top: 0.625rem;
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  line-height: 1.33 !important;
  color: #373f44;
}

.AboutSlider .m-tp-swiper-container .swiper-button-next,
.AboutSlider .m-tp-swiper-container .swiper-button-prev {
  width: 0.9375rem;
  height: 1.5rem;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat;
  background-size: cover;
  top: 10.8125rem;
  bottom: auto;
  right: 0.9375rem;
  overflow: visible;
}
.AboutSlider .m-tp-swiper-container .swiper-button-next:before,
.AboutSlider .m-tp-swiper-container .swiper-button-prev:before {
  content: "";
  display: block;
  width: 300%;
  height: 300%;
  margin-top: -1.4375rem;
  margin-left: -0.875rem;
}
.AboutSlider .m-tp-swiper-container .swiper-button-next:hover,
.AboutSlider .m-tp-swiper-container .swiper-button-prev:hover {
  border: none;
}
.AboutSlider .m-tp-swiper-container .swiper-button-prev {
  transform: rotate(180deg);
  right: none;
  left: 0.9375rem;
}

.AboutSliderMobile {
  display: none;
}
.AboutSliderMobile .m-about-qs-years-switch {
  display: block;
  margin: 0 auto;
  transition: all 1s;
}
.AboutSliderMobile .m-about-qs-years-switch:before {
  height: 10.625rem;
}
.AboutSliderMobile .m-about-qs-years-switch span {
  font-size: 2rem;
  margin: 0;
}
.AboutSliderMobile .m-about-qs-years-switch span:before {
  width: 3.125rem;
  height: 3.125rem;
  margin: -1.6875rem auto;
  margin-bottom: 0.3125rem;
}
.AboutSliderMobile .m-about-qs-descriptions {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.AboutSliderMobile .m-about-qs-descriptions .copytext {
  font-size: 1.125rem !important;
}
.AboutSliderMobile .m-tp-swiper-container .swiper-button-next,
.AboutSliderMobile .m-tp-swiper-container .swiper-button-prev {
  top: 5.3em;
}

.m-tp-teaser002 .c-tp-headline--arrow:after {
  margin-top: 0.15em;
}

.m-tp-teaser002 {
  position: relative !important;
  padding: 0 0 0;
  width: 100%;
  overflow: hidden;
}

.m-tp-teaser002 .m-tp-teaser002-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.4375rem;
  height: 6.25rem;
  position: relative !important;
  margin-bottom: 0.625rem;
}

.m-tp-teaser002 .c-tp-fleximage {
  width: 90%;
  margin: 0;
}

.m-tp-teaser002 .m-tp-teaser002-content {
  margin: 0;
  padding: 0 0.125rem 0 0.125rem;
}

.m-tp-teaser002-headline {
  margin: 0 0 0;
  overflow: hidden;
}

.m-tp-teaser002-content:hover {
  opacity: 0.8;
}

.g-tp-column--2 .c-tp-fleximage {
  width: 100%;
}

.g-tp-column--3 .c-tp-fleximage {
  width: 87%;
}

.g-tp-column--4 .c-tp-fleximage {
  width: 87%;
}

.g-tp-column--5 .c-tp-fleximage {
  width: 80%;
  left: 10%;
  bottom: 0.9375rem;
}

.ProductSlider {
  display: none;
}
.ProductSlider .m-tp-teaser002 .m-tp-teaser002-image {
  width: 100%;
  height: 16.875rem;
  background-image: url("/img//page-main/main-page-product-bg-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
}
.ProductSlider .m-tp-teaser002-content {
  padding-left: 1.75rem;
}
.ProductSlider .m-tp-swiper-container .swiper-button-next,
.ProductSlider .m-tp-swiper-container .swiper-button-prev {
  bottom: 12.375rem;
}
.ProductSlider .m-tp-swiper-container .swiper-slide img {
  width: auto;
  height: 12.5rem;
  position: relative;
  left: auto;
  bottom: -2.25rem;
  margin: 0 auto;
}
.ProductSlider .m-tp-teaser002 .c-tp-headline {
  font-size: 1.625rem !important;
}

.NewsMain {
  margin-bottom: 2.8125rem;
}

.m-news-qs-headline {
  display: table;
  table-layout: fixed;
  min-height: 9.0625rem;
  width: 100%;
  background-size: inherit;
  background-position: center -3.875rem;
  background-repeat: no-repeat;
  padding-top: 2.375rem;
  padding-right: 2rem;
  padding-bottom: 1.8125rem;
  padding-left: 1.625rem;
  position: relative;
}

.m-news-qs-headline .c-tp-headline {
  max-width: 17.25rem;
  color: #f8f8f8 !important;
  font-size: 1.75rem !important;
  line-height: 1.29 !important;
  font-weight: normal !important;
}

.NewsMain .m-bannerMore-btn {
  position: absolute;
  right: 2rem;
  bottom: 1.8125rem;
  left: auto;
}

.Stagesliderfullwidth .m-tp-swiper {
  padding-bottom: 0rem;
}

.m-swiperSlideContent {
  max-width: 24.375rem;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 5.1875rem;
  right: 5rem;
}

.m-swiperSlideContent--2 {
  max-width: 24.375rem;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 5.1875rem;
  right: 35.375rem;
  color: #002a5c;
}

.m-swiperSlideContent-line {
  width: 99%;
  height: 0.125rem;
  background-color: #ffffff;
}

.m-swiperSlideContent-header {
  font-weight: bold !important;
  color: #ffffff !important;
  line-height: 3.25rem !important;
  margin-top: 0.8125rem !important;
}

.m-swiperSlideContent-line--2 {
  background-color: #002a5c;
  color: #002a5c !important;
}

.m-swiperSlideContent-header--2 {
  color: #002a5c !important;
}

.m-swiperSlideContent-description--2 {
  color: #002a5c !important;
}

.m-swiperSlideContent-description {
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  color: #ffffff;
  margin-top: 0.625rem;
  background: transparent;
}

.m-swiperSlideContentMobile {
  display: none;
  text-align: center;
  margin-top: 0.9375rem;
}

.m-swiperSlideContentMobile-header {
  line-height: 1.2 !important;
  text-transform: uppercase;
  margin-bottom: 0.5rem !important;
}

.m-swiperSlideContentMobile-personName {
  margin-bottom: 0.1875rem;
}

.m-swiperSlideContentMobile-personDescription {
  font-weight: 300;
  color: #21292F;
}

.mainPage .m-about-qs-headline .m-about-qs-years-switch--visible span {
  transition: all 0.5s;
}
.mainPage .AboutSlider .m-tp-swiper-container .swiper-button-next, .mainPage .AboutSlider .m-tp-swiper-container .swiper-button-prev {
  width: 1.1875rem;
  height: 1.625rem;
  top: 10.4375rem;
  bottom: auto;
  right: 0.6875rem;
  overflow: visible;
}
.mainPage .m-about-qs-headline .m-about-qs-years-switch::before {
  display: block;
  content: "";
  background-position: center center;
  background-repeat: no-repeat;
  height: 9.125rem;
}
.mainPage .m-about-qs-headline {
  display: table;
  table-layout: fixed;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.mainPage .m-about-qs-descriptions {
  margin-top: 0.375rem;
}
.mainPage .h-tp-visuallyhidden {
  width: 61.25rem;
  min-height: 0rem !important;
  margin: 0;
  max-height: 0rem !important;
}
.mainPage .m-tp-swiper-container .swiper-wrapper {
  height: auto !important;
}
.mainPage .m-tp-swiper-container .swiper-slide {
  height: auto !important;
  position: initial !important;
}
.mainPage .m-about-qs-descriptions-item {
  width: 61.25rem;
  margin: 0;
  position: relative !important;
  max-height: 31.25rem;
}
.mainPage .m-about-controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainPage .copytext {
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif !important;
  font-size: 1.375rem !important;
  font-weight: 200 !important;
  font-stretch: condensed !important;
  line-height: 1.45 !important;
  letter-spacing: -0.06875rem !important;
  font-size: 1.5rem;
  font-weight: 300;
  font-stretch: condensed;
  line-height: 1.33;
  text-align: left;
  color: #373f44;
}
.mainPage .slider-header {
  min-height: 9.1875rem;
  width: 100%;
  background-image: url(../img/page-about/main-banner-slider.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 10;
}
.mainPage .data-link-icon {
  width: 3.125rem;
  height: 2rem;
  margin: 0 auto;
}
.mainPage .slick-slider-items {
  position: relative;
}
.mainPage .slick-slider {
  margin: 1.25rem 0;
}
.mainPage .slick-dots {
  display: none !important;
}
.mainPage .slick-next {
  position: absolute;
  right: 0rem !important;
  top: 1.0625rem !important;
  width: 1.875rem !important;
  height: 2.0625rem !important;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
}
.mainPage .slick-prev {
  position: absolute;
  transform: rotate(180deg);
  left: 0rem !important;
  top: 1.0625rem !important;
  opacity: 1 !important;
  width: 1.875rem !important;
  height: 2.0625rem !important;
  z-index: 10;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
}
.mainPage .data-link {
  width: 2rem;
  height: 2rem;
  position: relative;
  display: table-cell;
}
.mainPage .data-link:after {
  content: "";
  position: absolute;
  width: 2.1875rem;
  height: 0.125rem;
  top: 4.8125rem;
  background-color: transparent;
  transition: all 0.3s ease;
}
.mainPage .data-link:before {
  content: "";
  display: block;
  box-sizing: content-box;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  margin-bottom: 0.75rem;
  border: 0.0625rem solid #ccc;
  border-radius: 50%;
  background-color: #ffffff;
  transition: all 0.3s;
  background-image: url(../img/icon_plus.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 46%;
  background-position: center;
  background-repeat: no-repeat;
}
.mainPage .data-link--visible {
  width: 2rem;
  height: 2rem;
  position: absolute;
}
.mainPage .data-link--visible:after {
  content: "";
  position: absolute;
  width: 2.1875rem;
  height: 0.125rem;
  top: 4.8125rem;
  background-color: #837650;
  transition: all 0.3s ease;
}
.mainPage .data-link--visible:before {
  background-image: url(../img/js_tp_button_close-gold.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 38%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.mainPage .data-link-text {
  text-align: center;
  display: inline-block;
  margin: 4em;
  margin-top: 0.8125rem;
  padding-bottom: 0.25em;
  border-bottom: solid transparent 0.125rem;
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  color: #373f44;
  margin-bottom: 0.3125rem;
}
.mainPage .data-content .m-about-qs-descriptions-item {
  margin-top: -3.3125rem;
}

.m-about-qs-descriptions-item--anim {
  animation: ANIMATION_IMG 800ms forwards;
}

@keyframes ANIMATION_IMG {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.BannersMain {
  margin-top: 1.875rem;
}

.m-bannerMore {
  height: 26rem;
  margin-bottom: 4.375rem;
  position: relative;
}

.m-bannerMore-imageContainer img {
  width: 100%;
}

.m-bannerMore-title {
  margin-bottom: 1.125rem;
}

.m-bannerMore-title .c-tp-headline {
  font-size: 2.375rem !important;
  text-transform: uppercase;
  line-height: 3rem !important;
  text-align: center;
}

.m-bannerMore-content {
  max-width: 21.0625rem;
  padding: 1.5625rem;
  position: absolute;
  top: 4.125rem;
}

.m-bannerMore-1 .m-bannerMore-image--mobile {
  display: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.m-bannerMore-content .c-tp-headline {
  font-size: 1.75rem !important;
  font-weight: normal !important;
  line-height: 1.29 !important;
  color: #ffffff !important;
  text-transform: uppercase;
}

.m-bannerMore-btn {
  position: absolute;
  bottom: 1.5625rem;
  left: 1.5625rem;
}

.m-bannerMore-btn .c-tp-simplebutton {
  width: 14rem;
  line-height: 3rem;
}

.m-bannerMore-btn .c-tp-simplebutton .c-tp-simplebutton-text {
  padding-left: 0;
  padding-right: 0;
  vertical-align: middle;
}

.g-tp-row--second .m-bannerMore {
  margin-bottom: 3.75rem;
}

.m-bannerMore-darklayout {
  position: absolute;
  top: 4.125rem;
  width: 100%;
  height: calc(100% - 4.125rem);
  background-color: black;
  opacity: 0.2;
}

@media screen and (min-width: 48rem) {
  .InfoBlock .g-tp-column--first {
    padding-right: 0.5625rem;
  }
  .InfoBlock .g-tp-column--last {
    padding-left: 0.5625rem;
  }
}

.m-info {
  display: block;
  margin-bottom: 1.125rem;
}
.m-info-banner {
  height: 16.75rem;
  background-size: cover;
  background-position: center;
}
.m-info--full .m-info-banner {
  height: 20.375rem;
}
.m-info-header {
  padding: 0.9375rem 0.625rem 1.5625rem;
  background: #F8F6F2;
}
.m-info--full .m-info-header {
  padding: 1.6875rem 0.625rem 1.25rem;
}
.m-info-headline {
  font-size: 1.125rem;
  margin-bottom: 0.4375rem !important;
  font-family: "InterstateWGL";
}
.m-info-link {
  display: inline-block;
  position: relative;
  color: #64696D;
  font-family: "InterstateWGL-Light";
  font-size: 1rem;
}
.m-info-link::before {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  left: 0;
  display: block;
  width: calc(100% - 1.375rem);
  height: 1px;
  background: #65696D4D;
}
.m-info-link::after {
  top: 50%;
  height: 0.75rem;
  width: 0.5rem;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%2365696D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
@media screen and (max-width: 48rem) {
  .m-info {
    display: inline-block;
    width: 100%;
    margin-bottom: 1.25rem;
  }
  .m-info-banner {
    height: 9.9375rem;
  }
  .m-info--full .m-info-banner {
    height: 9.9375rem;
  }
  .m-info-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.125rem 0.875rem 0.9375rem 1.0625rem;
  }
  .m-info--full .m-info-header {
    padding: 1.125rem 0.875rem 0.9375rem 1.0625rem;
  }
  .m-info-headline {
    line-height: 0.8;
    font-size: 1rem;
    font-family: "InterstateWGL-Light";
  }
  .m-info-link {
    display: block;
    padding-right: 0.625rem;
    font-size: 0.75rem;
  }
}

.m-faq {
  width: 100%;
  padding-top: 1.75rem;
  padding-bottom: 2.5rem;
}
.m-faq-icon {
  display: block;
  width: 4.3125rem;
  margin: 0 auto 0.875rem;
}
.m-faq-headline {
  margin-bottom: 2rem !important;
  font-size: 2.25rem;
  font-family: "Roboto Condensed";
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.m-faq .g-tp-flex {
  flex-direction: column;
  align-items: center;
}
.m-faq-select {
  margin-bottom: 2.8125rem;
}
.m-faq-select .select {
  width: 31.875rem;
}
.m-faq-select .c-tp-formelement-select-container {
  width: 31.875rem;
  background: #FFF;
  border-color: #B9B9B9;
}
.m-faq-select .c-tp-formelement-select-container::after {
  top: 50%;
  transform: translateY(-50%);
  right: 1.3125rem;
  background: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.71045L8.5 7.71045L2 1.71045' stroke='%23C0A468' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
  background-size: contain;
}
.m-faq-select .c-tp-formelement-select-container select {
  font-size: 1rem;
  font-family: "InterstateWGL-Light";
}
.m-faq .btn {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}
@media screen and (max-width: 48rem) {
  .m-faq form {
    width: 100%;
  }
  .m-faq-select {
    margin-bottom: 0rem;
  }
  .m-faq-select .select {
    width: 100%;
  }
  .m-faq-headline {
    margin-bottom: 0.8125rem !important;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 64.375rem) {
  .navLayer {
    display: none;
  }
}
@media screen and (max-width: 64.375rem) {
  .mainPage .m-about-qs-headline .m-about-qs-years-switch::before {
    height: 10.6875rem;
  }

  .AboutCompany {
    padding-top: 1.375rem;
  }
  .AboutCompany .c-tp-copytext {
    margin-top: 0.8125rem;
    margin-bottom: 1.125rem;
    line-height: 1.11;
  }
  .AboutCompany .m-tp-textblock {
    padding-right: 0;
  }

  .g-tp-column--half {
    width: 100%;
  }

  .centerWrapper {
    padding: 0;
  }

  .m-home-nav {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 1rem;
    gap: 0.875rem 0.6875rem;
    padding: 1.25rem 0.625rem 1.125rem;
  }

  .m-home-nav-link.c-tp-headline--arrow {
    padding: 0.4375rem 0.375rem 0.375rem 0.5rem !important;
    font-size: 0.75rem;
    border-radius: 0.375rem 0 0.375rem 0;
  }
  .m-home-nav-link.c-tp-headline--arrow::after {
    width: 0.375rem;
    margin-left: 0.3125rem;
  }

  .centerContent {
    width: 100%;
  }

  .navLayer {
    display: none;
  }

  .m-tp-swiper-container .swiper-slide img {
    height: 19.6875rem;
    object-fit: cover;
    object-position: right;
  }
  .m-tp-swiper-container .swiper-slide img.swiper-slide-img-mobile {
    display: none;
  }

  .m-swiperSlideContent {
    width: 21.25rem;
    right: 5.625rem;
    top: 4.6875rem;
  }
  .m-swiperSlideContent-header {
    font-size: 2.25rem !important;
    padding-top: 0.1875rem !important;
  }
  .m-swiperSlideContent-description {
    font-size: 1.125rem !important;
  }

  .m-tp-swiper-container .swiper-button-next,
.m-tp-swiper-container .swiper-button-prev {
    width: 3.0625rem;
    height: 3.0625rem;
    bottom: 8.3125rem;
  }

  .m-tp-swiper-container .swiper-button-prev {
    right: auto;
    left: 0;
  }

  .m-tp-swiper-container .swiper-button-next {
    right: 0;
  }

  .AboutCompany {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .AboutCompany .m-about-qs-headline {
    background-position: top center;
    background-size: auto;
  }

  .AboutCompany .c-tp-headline,
.ProductsBlock .c-tp-headline,
.NewsMain .c-tp-headline {
    font-size: 1.5rem !important;
  }

  .ProductSlider {
    display: block;
  }

  .BannersMain {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    margin-top: 0.625rem;
  }
  .BannersMain .m-bannerMore-title .c-tp-headline {
    font-size: 2rem !important;
  }

  .m-bannerMore {
    height: auto;
    margin-bottom: 3.125rem;
  }

  .m-bannerMore-imageContainer {
    height: 15.75rem;
  }

  .m-bannerMore-image {
    height: 100%;
  }

  .m-bannerMore-btn {
    bottom: 1.5625rem;
  }

  .NewsMain {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
    margin-bottom: 3.125rem;
  }
  .NewsMain .m-bannerMore-btn {
    right: 1.1875rem;
  }

  .m-news-qs .c-tp-headline--90 {
    font-size: 1.75rem !important;
  }
}
@media screen and (max-width: 48rem) {
  .mainPage .m-about-qs-descriptions-item {
    width: 20rem;
  }

  .mainPage {
    padding: 0;
  }

  .centerContent {
    width: 100%;
    min-height: 0;
  }

  .g-tp-row {
    margin: 0;
  }

  .AboutCompany .m-tp-textblock,
.ProductsBlock .m-tp-textblock,
.NewsMain .m-tp-textblock {
    padding-bottom: 2.0625rem;
  }

  .NewsMain .m-news-qs--full {
    margin-bottom: 1.25rem;
  }

  .ProductSlider .m-tp-teaser002 .c-tp-headline {
    font-size: 1.125rem !important;
    font-weight: bold !important;
    font-stretch: condensed !important;
    text-align: left !important;
    color: #373f44 !important;
    margin-top: 0.3125rem;
  }

  .adElementContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .Stagesliderfullwidth .m-tp-swiper {
    padding-bottom: 0rem;
  }
  .Stagesliderfullwidth .m-tp-swiper-container {
    margin-bottom: 0;
  }

  .m-tp-swiper-container {
    margin-bottom: 0.571em;
  }
  .m-tp-swiper-container .swiper-slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .m-tp-swiper-container .swiper-slide img.swiper-slide-img-mobile {
    display: block;
  }
  .m-tp-swiper-container .swiper-slide img.swiper-slide-img-desktop {
    display: none;
  }
  .m-tp-swiper-container .swiper-slide video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .m-tp-swiper-container .swiper-slide video.swiper-slide-video-mobile {
    display: block;
  }
  .m-tp-swiper-container .swiper-slide video.swiper-slide-video-desktop {
    display: none;
  }

  .m-swiperSlideContent {
    display: none;
  }

  .m-tp-swiper-container .swiper-button-next,
.m-tp-swiper-container .swiper-button-prev {
    width: 2.5em;
    height: 2.5em;
    background-size: 2.5em;
    bottom: 10.8em;
  }

  .m-tp-swiper-container .swiper-button-prev {
    right: auto;
    left: 0;
  }

  .m-tp-swiper-container .swiper-button-next {
    right: 0;
    left: auto;
  }

  .m-swiperSlideContentMobile {
    display: block;
  }

  .AboutCompany .c-tp-headline,
.NewsMain .c-tp-headline {
    font-size: 1.5rem !important;
    line-height: 1.625rem !important;
    margin-bottom: 0rem;
  }

  .AboutCompany {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 1.5625rem;
  }
  .AboutCompany .m-about-qs-headline {
    min-height: auto;
    height: 15rem;
    background-size: cover;
  }

  .AboutSlider {
    display: none;
  }

  .AboutSliderMobile {
    display: block;
  }

  .m-about-qs {
    margin-bottom: 0;
  }

  .ProductsBlock {
    padding-top: 0;
    padding-bottom: 2.6875rem;
  }

  .ProductsBlock-content {
    margin-left: 1.0625rem;
    margin-right: 1.0625rem;
    justify-content: flex-start;
  }
  .ProductsBlock-content::-webkit-scrollbar {
    height: 0.4375rem;
  }
  .ProductsBlock-content::-webkit-scrollbar-track {
    background: #CED0D1;
    border-radius: 10px;
  }
  .ProductsBlock-content::-webkit-scrollbar-thumb {
    background: #22292E;
    border-radius: 10px;
  }
  .ProductsBlock-content .g-tp-column {
    flex-shrink: 0;
    padding: 0;
  }
  .ProductsBlock-content .m-tp-teaser002 .m-tp-teaser002-image {
    height: 6.25rem;
    margin-bottom: 0.375rem;
  }
  .ProductsBlock-content .m-tp-teaser002 .c-tp-headline {
    font-size: 0.875rem !important;
  }
  .ProductsBlock-content .m-tp-teaser002 .c-tp-headline--arrow:after {
    width: 1.375rem !important;
    margin-top: 0.625rem !important;
  }

  .ProductSlider {
    display: block;
  }
  .ProductSlider .c-tp-headline {
    font-size: 1.125rem !important;
    padding: 0 2.66vw !important;
  }
  .ProductSlider .m-tp-teaser002 {
    padding: 0;
  }
  .ProductSlider .m-tp-teaser002-content {
    padding-left: 0;
  }
  .ProductSlider .m-tp-teaser002 .m-tp-teaser002-image {
    height: 11.0625rem;
  }
  .ProductSlider .m-tp-swiper-container .swiper-button-next,
.ProductSlider .m-tp-swiper-container .swiper-button-prev {
    bottom: 8.7em;
  }
  .ProductSlider .m-tp-swiper-container .swiper-slide img {
    height: 9.375rem;
    bottom: -0.875rem;
  }
  .ProductSlider__container {
    position: relative;
  }
  .ProductSlider_desk {
    display: block;
  }
}
@media screen and (max-width: 48rem) and (max-width: 48rem) {
  .ProductSlider_desk {
    display: none;
  }
}
@media screen and (max-width: 48rem) {
  .ProductSlider_mob {
    display: none;
  }
}
@media screen and (max-width: 48rem) and (max-width: 48rem) {
  .ProductSlider_mob {
    display: block;
  }
}
@media screen and (max-width: 48rem) {
  .ProductSlider__image {
    width: 100%;
  }
  .ProductSlider__image-container {
    height: 11.0625rem;
    width: unset;
    display: block;
    background-image: url("/img//page-main/main-page-product-bg-mobile.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: cover;
    text-align: center;
  }
  .ProductSlider__image-container img {
    margin-top: 2.5%;
    height: 90%;
    width: unset;
  }
}
@media screen and (max-width: 48rem) {
  .ProductSlider__content {
    display: inline-block;
    padding-top: 1.25rem;
  }
  .ProductSlider__content-header.c-tp-headline {
    font-weight: 700 !important;
    text-transform: none !important;
  }
  .ProductSlider__content-header.c-tp-headline::after {
    margin: 0.2em 0 0 0.7em;
  }
}
@media screen and (max-width: 48rem) {
  .ProductSlider__button {
    position: absolute;
    bottom: 6.6875rem;
    width: 2.0625rem;
    height: 2.0625rem;
    background-size: contain;
    overflow: visible;
  }
  .ProductSlider__button_next {
    right: 0;
    background: #ffffff url(../img/page-about/right_icon.svg) center center no-repeat;
  }
  .ProductSlider__button_prev {
    left: 0;
    background: #ffffff url(../img/page-about/left_icon.svg) center center no-repeat;
  }
}
@media screen and (max-width: 48rem) {
  .ProductSlider .slick-track {
    display: flex !important;
  }
  .ProductSlider .slick-slide {
    height: inherit !important;
    width: 60vw;
    margin-left: 0.625rem;
  }
  .ProductSlider .slick-slide img {
    display: inline-block;
  }
}
@media screen and (max-width: 48rem) {
  .BannersMain {
    margin-top: 1.375rem;
    padding-right: 0;
    padding-left: 0;
  }
  .BannersMain .g-tp-column--half {
    width: 100%;
  }
  .BannersMain .m-bannerMore {
    height: 13.3125rem;
    margin-bottom: 2rem;
  }
  .BannersMain .m-bannerMore-title {
    margin-bottom: 0.75rem;
  }
  .BannersMain .m-bannerMore-title .c-tp-headline {
    font-size: 1.5rem !important;
    line-height: normal !important;
  }
  .BannersMain .m-bannerMore-1 .m-bannerMore-image {
    display: none;
  }
  .BannersMain .m-bannerMore .m-bannerMore-image {
    width: 100%;
    height: auto;
  }
  .BannersMain .m-bannerMore-1 .m-bannerMore-image--mobile {
    display: block;
  }
  .BannersMain .m-bannerMore-content {
    max-width: 13.4375rem;
    padding: 1.25rem;
    top: 2.6875rem;
  }
  .BannersMain .m-bannerMore-content .c-tp-headline {
    font-size: 1.125rem !important;
    line-height: 1.44 !important;
  }
  .BannersMain .m-bannerMore-btn .c-tp-simplebutton {
    width: 10.625rem;
    line-height: 2.0625rem;
  }
  .BannersMain .m-bannerMore-btn .c-tp-simplebutton .c-tp-simplebutton-text {
    font-size: 0.75rem !important;
    padding: 0.3125rem 0;
  }
  .BannersMain .m-bannerMore-btn {
    bottom: 0.4375rem;
    left: 1.25rem;
  }
  .BannersMain .m-bannerMore-darklayout {
    top: 2.75rem;
    height: calc(100% - 2.6875rem);
  }
  .BannersMain .m-bannerMore-imageContainer {
    height: 10.625rem;
    width: 100%;
    overflow: hidden;
  }
  .BannersMain .m-bannerMore-2 .m-bannerMore-image {
    margin-top: -2.1875rem;
  }
  .BannersMain .m-bannerMore-3 .m-bannerMore-image {
    margin-top: -4.375rem;
  }
  .BannersMain .m-bannerMore-4 .m-bannerMore-image {
    margin-top: -4.75rem;
  }
}
@media screen and (max-width: 48rem) {
  .m-bannerMore-btn .c-tp-simplebutton {
    width: 10.625rem;
    line-height: 2.0625rem;
  }
}
@media screen and (max-width: 48rem) {
  .m-bannerMore-btn .c-tp-simplebutton .c-tp-simplebutton-text {
    font-size: 0.75rem !important;
    padding: 0.3125rem 0;
  }
}
@media screen and (max-width: 48rem) {
  .NewsMain {
    margin-bottom: 2rem;
    padding-right: 0;
    padding-left: 0;
  }
  .NewsMain .m-news-qs-headline {
    min-height: 10.625rem;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    background-size: cover;
    background-position: 80% center;
  }
  .NewsMain .m-news-qs-headline .m-news-qs-header {
    max-width: 11.0625rem;
  }
  .NewsMain .m-news-qs-headline .c-tp-headline {
    font-size: 1.125rem !important;
    line-height: 1.44;
  }
  .NewsMain .m-bannerMore-btn {
    left: 1.25rem;
    bottom: 0.4375rem;
  }
}
@media screen and (max-width: 48rem) {
  .mainPage .slider-header {
    min-height: 10.6875rem;
    width: 100%;
    background-image: url(../img/page-about/main-banner-slider.jpg);
    background-size: 163%;
    background-repeat: no-repeat;
    z-index: 10;
    background-position: 47% 0%;
  }
  .mainPage .data-link-text {
    text-align: center;
    display: inline-block;
    padding-bottom: 0.25em;
    border-bottom: solid transparent 0.125rem;
    font-family: "Roboto Condensed", Arial, sans-serif;
    font-size: 2.1rem;
    font-weight: bold;
    color: #373f44;
    margin-bottom: 0.3125rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 1.5625rem;
  }
  .mainPage .data-link:before {
    content: "";
    display: block;
    box-sizing: content-box;
    width: 3.125rem;
    height: 3.125rem;
    margin: 0 auto;
    margin-bottom: 0.75rem;
    border: 0.0625rem solid #ccc;
    border-radius: 50%;
    background-color: #ffffff;
    transition: all 0.3s;
    background-image: url(../img/icon_plus.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 44%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .mainPage .copytext {
    font-size: 1.125rem !important;
    font-weight: 300 !important;
    font-stretch: condensed !important;
    line-height: 1.33 !important;
    text-align: left !important;
    color: #373f44;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    text-align: left;
    padding-bottom: 2.375rem;
  }
  .mainPage .m-about-qs-descriptions-item {
    padding-top: 0 !important;
    width: 100%;
  }
  .mainPage .js-afterelement-block {
    display: none;
  }
  .mainPage .data-link--visible:before {
    background-image: url(../img/js_tp_button_close-gold.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 38%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s;
  }
  .mainPage .data-link--visible:after {
    content: "";
    position: absolute;
    width: 2.1875rem;
    height: 0.125rem;
    top: 5.5625rem;
    left: 0.5rem;
    background-color: #837650;
    transition: all 0.3s ease;
  }
  .mainPage .slick-next {
    right: 0rem !important;
    top: -7.8125rem !important;
    width: 2.0625rem !important;
    height: 2.0625rem !important;
    background: url(../img/page-about/right_icon.svg) right center no-repeat !important;
  }
  .mainPage .slick-next:before {
    content: "" !important;
  }
  .mainPage .slick-prev {
    transform: rotate(360deg);
    left: 0rem !important;
    top: -7.8125rem !important;
    opacity: 1 !important;
    width: 2.0625rem !important;
    height: 2.0625rem !important;
    z-index: 10;
    background: url(../img/page-about/left_icon.svg) right center no-repeat !important;
  }
  .mainPage .m-about-qs-descriptions-item--img {
    padding-top: 2.3125rem;
  }
}
@media screen and (max-width: 20rem) {
  .BannersMain .m-bannerMore-3 .m-bannerMore-image {
    margin-top: -3.25rem;
  }
  .BannersMain .m-bannerMore-4 .m-bannerMore-image {
    margin-top: -2.875rem;
  }
}
@media screen and (max-width: 20.125rem) {
  .l-tp-footer-content .m-flags-item {
    margin-left: 15%;
  }
}
.ProductPage {
  padding-top: 7rem;
}
.ProductPage .centerContent {
  position: relative;
  width: 62.5rem;
  max-width: 100%;
}
@media screen and (max-width: 48rem) {
  .ProductPage {
    padding-top: 3.5625rem;
  }
}

.product-page-main-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.5rem;
  margin-bottom: 2.875rem;
  border-bottom: 1px solid #A6A9AC;
}
.product-page-main-info-slider, .product-page-main-info-content {
  width: 50%;
}
.product-page-slider-for {
  margin-bottom: 1.25rem;
}
.product-page-slider-for-slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.product-page-slider-for-img {
  height: 23.4375rem;
}
.product-page-slider-nav {
  width: 90%;
  margin: auto;
}
.product-page-slider-nav-slide {
  margin: auto;
  width: 3.875rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.375rem;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
.slick-current .product-page-slider-nav-slide {
  border-bottom-color: #000;
}
.product-page-slider-nav-img {
  height: 4rem;
}
.product-page-title {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: 0.04em;
  font-family: "InterstateWGL";
}
.product-page-descr {
  margin-bottom: 2rem;
  font-size: 2.25rem;
  line-height: 2.4375rem;
  letter-spacing: 0.04em;
  font-family: "InterstateWGL-Light";
}
.product-page-small-descr {
  margin-bottom: 1rem;
  font-family: "InterstateWGL";
  font-size: 1.125rem;
}
.product-page-saturation {
  padding: 2rem 0;
  margin-bottom: 1.75rem;
  border-top: 1px solid #A6A9AC;
  border-bottom: 1px solid #A6A9AC;
}
.product-page-list span {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-family: "InterstateWGL";
}
.product-page-list ul {
  padding-left: 0.9375rem;
}
.product-page-list ul li {
  margin-bottom: 0.75rem;
  padding-left: 0.5rem;
  font-size: 1rem;
  line-height: 1.1875rem;
  list-style: disc;
  font-family: "InterstateWGL-Light";
}
.product-page-info {
  margin-bottom: 6.25rem;
}
.product-page-info-title {
  margin-bottom: 2rem;
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-family: "InterstateWGL-Light";
}
.product-page-info-title br {
  display: none;
}
.product-page-info-row {
  display: flex;
  justify-content: space-between;
}
.product-page-info-row > div {
  flex: 0 0 50%;
}
.product-page-info-text {
  padding-right: 3.5rem;
}
.product-page-other-products {
  position: relative;
  padding-top: 3.75rem;
  margin-bottom: 5rem;
}
.product-page-other-products::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: block;
  background: #423C39;
}
.product-page-other-products-title {
  margin-bottom: 2.5rem;
  font-family: "InterstateWGL-Light" !important;
  font-weight: normal;
  color: #FFF;
}
.product-page-other-products-list {
  display: flex;
}
.product-page-cooking-recommendation {
  display: flex;
  align-items: center;
  margin-bottom: 6.25rem;
}
.product-page-cooking-recommendation-content {
  padding-right: 2.5rem;
  font-family: "InterstateWGL-Light";
}
.product-page-cooking-recommendation-title {
  margin-bottom: 1.75rem;
  font-size: 1.625rem;
}
.product-page-cooking-recommendation-text {
  width: 80%;
  line-height: 1.1;
}
.product-page-cooking-recommendation-img {
  width: 50%;
}
@media screen and (max-width: 48rem) {
  .product-page-main-info {
    width: 100%;
    flex-direction: column;
    padding: 0 1.625rem 0.625rem;
    margin-bottom: 2.8125rem;
  }
  .product-page-main-info-slider, .product-page-main-info-content {
    width: 100%;
  }
  .product-page-main-info-slider {
    margin-bottom: 2.3125rem;
  }
  .product-page-slider-for {
    margin-bottom: 1.25rem;
  }
  .product-page-title {
    margin-bottom: 0.1875rem;
  }
  .product-page-title, .product-page-descr {
    margin-bottom: 0.375rem;
    font-size: 1.75rem;
    line-height: 1.9375rem;
  }
  .product-page-descr {
    margin-bottom: 0.75rem;
  }
  .product-page-small-descr {
    margin-bottom: 0.75rem;
  }
  .product-page-saturation {
    padding: 1.25rem 0 1rem;
    margin-bottom: 1.3125rem;
  }
  .product-page-list span {
    margin-bottom: 1.25rem;
  }
  .product-page-info {
    padding: 0 1.625rem;
    margin-bottom: 3.5rem;
  }
  .product-page-info-title {
    margin-bottom: 1.4375rem;
    font-size: 1.75rem;
    line-height: 1.15;
  }
  .product-page-info-text {
    margin-bottom: 1.25rem;
    padding-right: 0.625rem;
  }
  .product-page-info-row {
    flex-direction: column;
  }
  .product-page-other-products {
    padding: 2.125rem 1.625rem 0;
    margin-bottom: 2.75rem;
  }
  .product-page-other-products-title {
    margin-bottom: 1.875rem;
    font-size: 1.75rem;
    line-height: 1.875rem;
  }
  .product-page-other-products-list {
    padding-bottom: 2rem;
    overflow-x: auto;
  }
  .product-page-other-products-list::-webkit-scrollbar {
    height: 0.4375rem;
  }
  .product-page-other-products-list::-webkit-scrollbar-track {
    background: #CED0D1;
    border-radius: 10px;
  }
  .product-page-other-products-list::-webkit-scrollbar-thumb {
    background: #22292E;
    border-radius: 10px;
  }
  .product-page-other-products-list .product {
    flex-shrink: 0;
    width: 100%;
  }
  .product-page-cooking-recommendation {
    padding: 0 1.625rem;
    margin-bottom: 2.5rem;
    flex-direction: column;
  }
  .product-page-cooking-recommendation-title {
    margin-bottom: 1.25rem;
    font-size: 1.75rem;
    line-height: 1.15;
  }
  .product-page-cooking-recommendation-text {
    width: 100%;
    margin-bottom: 1.125rem;
  }
  .product-page-cooking-recommendation-text p {
    font-size: 1rem;
    line-height: 1.25;
  }
  .product-page-cooking-recommendation-img {
    width: 100%;
  }
}

@media screen and (max-width: 48rem) {
  .Exclusive .productPage .Jumbotron .m-tp-singleimage img {
    height: 10.5rem;
    width: 112% !important;
    object-fit: cover;
    object-position: -rem(50px);
    padding: 0;
  }

  .productPage .Jumbotron .m-tp-singleimage img {
    height: 10.5rem;
    width: 112% !important;
    object-fit: cover;
    object-position: -rem(50px);
    padding: 0;
  }

  .ChainStores .Jumbotron .m-tp-singleimage img {
    height: 10.5rem;
    width: 100% !important;
    object-fit: cover;
    object-position: -rem(50px);
    padding: 0;
  }

  .productPage .Jumbotron .m-jumbotron-content-header--1 {
    font-size: 1rem;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 1.31;
    text-align: center;
    color: #002a5b;
  }

  .productPage .Jumbotron .m-jumbotron-content-line {
    width: 100%;
    height: 0rem;
    border-top: 0.0625rem solid transparent;
    border-color: #002a5c;
  }

  .productPage .Jumbotron .m-jumbotron-content-description--1 {
    font-size: 0.625rem;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 2.1;
    text-align: center;
    color: #002a5b;
  }

  .Exclusive .MainTitle {
    padding: 0 1.1875rem;
    margin-bottom: 3.8125rem;
  }

  .Exclusive .m-tp-productbox-info .c-tp-headline--60 {
    font-size: 1.125rem !important;
    line-height: 2.4 !important;
    font-weight: bold !important;
    letter-spacing: 0.01875rem;
    margin: 0;
    margin-bottom: -rem(20px);
  }

  .Exclusive .ItemBanner {
    margin-bottom: 1.25rem;
  }

  .productPage .m-tp-productbox-info .m-tp-productbox-description {
    font-size: 1.125rem !important;
    line-height: 2.4 !important;
  }

  .productPage .c-tp-intensity {
    letter-spacing: 0;
  }

  .productPage .m-tp-productbox-image {
    text-align: center;
    text-align: -webkit-center;
  }

  .Suggestions .m-tp-productbox-image {
    text-align: center;
    text-align: -webkit-center;
  }

  .productPage .m-tp-productbox-info {
    width: auto !important;
  }

  .productPage .m-tp-productbox-info .m-tp-productbox-description {
    margin-bottom: 1.0625rem;
  }

  .Exclusive .Suggestions .tp-component-productlistitem {
    margin-bottom: 1.125rem;
  }

  .Exclusive .Suggestions .tp-component-productlistitem .m-tp-productbox-description {
    min-height: auto;
    margin-bottom: 1.1875rem;
  }

  .Exclusive .ItemBanner {
    margin-bottom: -rem(13px);
  }

  .ItemBanner--2 .m-tp-mag1-image-hero img {
    width: 100%;
    height: auto !important;
  }

  .ItemBanner--2 .m-tp-mag1-image-hero {
    position: initial;
    right: 0;
  }

  .productPage .ItemBanner--2 .m-tp-mag1 .m-tp-mag1-image-hero {
    width: 100%;
    height: auto;
    margin-bottom: 2.375rem;
  }

  .ChainStores .Jumbotron .m-jumbotron-content {
    width: 15.625rem !important;
    position: absolute !important;
    top: 3.125rem !important;
    left: 0rem !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .ChainStores .Jumbotron .m-jumbotron-content-header--1 {
    font-size: 1.25rem !important;
    font-weight: bold !important;
    font-stretch: condensed !important;
    line-height: 1.05 !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 0.375rem !important;
  }

  .productPage .Jumbotron {
    margin-bottom: 3.625rem;
  }

  .productPage .MainTitle .c-tp-headline--120 {
    font-size: 2rem !important;
    font-weight: 300 !important;
    font-stretch: condensed !important;
    line-height: 0.75 !important;
    text-align: center !important;
    color: #373f44;
  }

  .Exclusive .MainTitle {
    padding: 0 1.1875rem;
    margin-bottom: 0rem;
  }

  .m-tp-productbox-image--custom {
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: contain;
    height: 21.25rem !important;
  }

  .m-tp-productbox-image--custom-1 {
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: contain;
    height: 29.5rem !important;
  }
}
.ChainStores .g-tp-colum--both {
  clear: both;
  height: 1.25rem;
}
.ChainStores .c-tp-headline--80 {
  font-size: 1.125rem !important;
  line-height: 1.5rem !important;
  font-weight: bold !important;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  letter-spacing: 0.025rem;
}
.ChainStores .c-tp-headline--70 {
  font-size: 1rem !important;
  line-height: 1.125rem !important;
  font-weight: bold !important;
  text-transform: uppercase;
  letter-spacing: 0.01875rem;
  margin-bottom: 1rem;
}
.ChainStores .Jumbotron {
  position: relative;
}
.ChainStores .Jumbotron .m-jumbotron-content {
  width: 23.5625rem;
  position: absolute;
  top: 2.5625rem;
  left: 2.8125rem;
}
.ChainStores .Jumbotron .m-jumbotron-content-line {
  width: 100%;
  height: 0.125rem;
  background-color: #ffffff;
}
.ChainStores .Jumbotron .m-jumbotron-content-header {
  font-weight: bold !important;
  color: #fff !important;
  line-height: 2.9375rem !important;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 0.8125rem;
}
.ChainStores .Jumbotron .m-jumbotron-content-header--1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.31;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.04375rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}
.ChainStores .Information {
  margin-bottom: 1.9375rem;
}
.ChainStores .Information .c-tp-copytext {
  font-size: 1.125rem !important;
  font-weight: 300 !important;
  line-height: 1.5rem !important;
  color: #373f44;
  letter-spacing: 0.01875rem;
  margin-bottom: 1.5625rem;
}
.ChainStores .Declarations {
  position: relative;
}
.ChainStores .Declarations .c-tp-headline--left {
  float: left;
  width: 37.5rem;
  clear: both;
}
.ChainStores .Declarations .c-tp-headline {
  padding-left: 2.375rem;
}
.ChainStores .Declarations .m-tp-declaration-list {
  margin-bottom: 3.5rem;
}
.ChainStores .Declarations .m-tp-declaration-item {
  margin-bottom: 0.9375rem;
}
.ChainStores .Declarations .m-tp-declaration-image {
  width: 20.4375rem;
  margin: 0 auto;
  margin-bottom: 3.125rem;
  position: absolute;
  top: 0;
  right: 0;
}
.ChainStores .Declarations .m-tp-declaration-link {
  text-decoration: underline;
  font-weight: 300 !important;
  font-size: 1rem !important;
  letter-spacing: 0.01875rem;
  color: #373f44;
  padding-left: 2.375rem;
  position: relative;
}
.ChainStores .Declarations .m-tp-declaration-link:hover {
  opacity: 0.8;
}
.ChainStores .Declarations .m-tp-declaration-link:before {
  content: "";
  width: 0.9375rem;
  height: 1.25rem;
  background-image: url("../img/page-chain-stores/cs-icon-doc.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 48rem) {
  .ChainStores .Information {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .ChainStores .Declarations {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
  .ChainStores .Declarations .m-tp-declaration-image {
    position: relative;
  }
}
@media screen and (max-width: 48rem) {
  .ChainStores .c-tp-headline--80 {
    font-size: 1rem !important;
    margin-bottom: 0.625rem;
  }
  .ChainStores .Jumbotron .m-tp-singleimage {
    height: 9.375rem;
  }
  .ChainStores .Jumbotron .m-tp-singleimage img {
    height: 100%;
    object-fit: cover;
    padding-bottom: 0;
  }
  .ChainStores .Jumbotron .m-jumbotron-content {
    width: auto;
    top: 1.8125rem;
    left: 1.375rem;
  }
  .ChainStores .Jumbotron .m-jumbotron-content-header {
    font-size: 1.5625rem !important;
    line-height: normal !important;
    padding-top: 0.3125rem;
    padding-bottom: 0.5625rem;
  }
  .ChainStores .Information {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .ChainStores .Information .c-tp-copytext {
    font-size: 1rem !important;
    margin-bottom: 0.9375rem;
  }
  .ChainStores .Declarations {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .ChainStores .Declarations .c-tp-headline {
    padding-left: 0rem;
  }
  .ChainStores .Declarations .m-tp-declaration-list {
    margin-bottom: 2.5rem;
  }
  .ChainStores .Declarations .m-tp-declaration-image {
    width: 90%;
  }
}
.Expertise {
  position: relative;
}
.Expertise::before {
  content: "";
  width: 100%;
  height: 20.5rem;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #423C39;
  z-index: -1;
}

@media screen and (max-width: 48rem) {
  .Expertise::before {
    height: 56%;
  }
  .Expertise .Banners .g-tp-row {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
}
.News .Jumbotron {
  position: relative;
}
.News .Jumbotron .m-jumbotron-content {
  max-width: 23.5625rem;
  position: absolute;
  top: 2.5625rem;
  left: 2.8125rem;
}
.News .Jumbotron .m-jumbotron-content-line {
  width: 100%;
  height: 0.125rem;
  background-color: #ffffff;
}
.News .Jumbotron .m-jumbotron-content-header {
  font-weight: bold !important;
  color: #fff !important;
  line-height: 2.9375rem !important;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 0.8125rem;
}
.News .Jumbotron .m-jumbotron-content-header--1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.31;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.04375rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}
.News .Jumbotron .m-jumbotron img {
  height: 15.875rem;
}
.News .Newslist .m-newsItem {
  position: relative;
}
.News .Newslist .m-newsItem .c-tp-headline--span {
  top: -rem(15px);
  left: 0.125rem;
  position: relative;
  color: #373f44;
  font-size: 0.875rem;
  font-stretch: condensed;
  line-height: 2;
  text-align: left;
  color: #373f44;
}
.News .Newslist .m-newsItem-content {
  max-width: 34.4375rem;
  position: absolute;
  top: 2.75rem;
  left: 3rem;
}
.News .Newslist .m-newsItem-content-header {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
  color: #373f44 !important;
  text-transform: uppercase;
  font-weight: bold !important;
  margin-top: -rem(1px);
}
.News .Newslist .m-newsItem-content-header-red {
  color: #d0011b !important;
  margin-top: -rem(10px);
}
.News .Newslist .m-newsItem-content-description {
  max-width: 98%;
  font-size: 1.375rem !important;
  line-height: 1.75rem !important;
  color: #373f44 !important;
  margin-top: 1.5rem;
}
.News .Newslist .g-tp-row-3 .m-newsItem-content-description {
  max-width: 75%;
}

.m-tp-singleimage img.img-mobile {
  display: none;
}

@media screen and (max-width: 48rem) {
  .News .Newslist {
    padding-top: 0rem;
  }
  .News .Newslist .m-newsItem-content {
    left: 0;
    top: 0.5rem;
    margin: 0 auto;
    width: 90%;
  }
  .News .Newslist .m-newsItem-content-header {
    font-size: 1.75rem !important;
  }
  .News .Newslist .m-newsItem-content-description {
    max-width: 100%;
    font-size: 1.125rem !important;
    margin-top: 0;
  }
  .News .Newslist .g-tp-row-3 .m-newsItem-content-description {
    max-width: 75%;
  }

  .m-tp-singleimage img.img-desk {
    display: none;
  }

  .m-tp-singleimage img.img-mobile {
    display: block;
  }
}
@media screen and (max-width: 48rem) {
  .News .Jumbotron .m-jumbotron img {
    height: 12.4375rem;
    width: auto;
    margin-bottom: -rem(20px);
  }

  .News .m-tp-singleimage {
    margin: 0;
  }

  .News .Newslist .m-newsItem .c-tp-headline--span {
    font-size: 0.6875rem;
    font-stretch: condensed;
    line-height: 2.55;
    text-align: left;
    color: #373f44;
  }

  .News .Newslist .m-newsItem-content-header {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    font-stretch: condensed !important;
    line-height: 1.42 !important;
    text-align: left !important;
  }

  .News .Newslist .m-newsItem-content-description {
    font-size: 1rem !important;
    font-stretch: condensed !important;
    line-height: 1.5 !important;
    text-align: left;
    color: #373f44;
    margin-top: 1.4375rem;
    margin-bottom: 2.8125rem;
  }

  .News .Newslist .m-newsItem-content {
    position: relative;
  }

  .News .Newslist .m-newsItem {
    padding-top: 0.4375rem;
  }

  .News .Newslist .m-newsItem .c-tp-headline--span {
    top: -rem(10px);
    left: 0;
  }

  .News .Newslist .m-newsItem .m-tp-singleimage img {
    height: 12.5rem;
    margin-bottom: 0.625rem;
  }

  .News .Jumbotron .m-jumbotron-content {
    max-width: 9.375rem;
    position: absolute;
    top: 3.75rem;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .News .Jumbotron .m-jumbotron-content-header--1 {
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 1.05;
    text-align: center;
    color: #ffffff;
    padding: 0.375rem;
  }
}
.ErrorPage .centerContent {
  min-height: 27rem;
}
.ErrorPage .ErrorTitle {
  margin-top: 1.125rem;
}
.ErrorPage .ErrorTitle h1 {
  font-size: 3.625rem;
  font-weight: 300;
  font-stretch: condensed;
  color: #373f44;
}
.ErrorPage .ErrorTitle h1:nth-child(2) {
  font-size: 2.375rem;
  font-weight: 300;
  font-stretch: condensed;
  color: #373f44;
}
.ErrorPage .ErrorTitle .g-tp-column {
  margin-top: 0.625rem;
}
.ErrorPage .ErrorTitle .g-tp-column .m-tp-textblock-headline {
  margin-top: -rem(15px);
}
.ErrorPage .ErrorTitle .g-tp-column .m-tp-singleimage {
  width: 83%;
  margin: 0 auto;
  margin-top: 1.75rem;
}

.Assortment .Stagesliderfullwidth {
  margin-bottom: 5.1875rem;
}
.Assortment .assortment-collection-block {
  position: relative;
  padding: 0 9.6875rem;
  margin-bottom: 5rem;
}
.Assortment .assortment-collection-block::before {
  content: "";
  position: absolute;
  top: 4.375rem;
  left: 0;
  width: 100%;
  height: 79.375vh;
  display: block;
  background: #423C39;
  z-index: -1;
}
.Assortment .assortment-collection-block-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 48.5%;
  height: 79vh;
  object-fit: cover;
  z-index: -1;
}
.Assortment .assortment-collection-block-content {
  width: 51.5%;
  margin-left: auto;
  padding-left: 3.8125rem;
  padding-top: 6.5625rem;
  margin-bottom: 2rem;
}
.Assortment .assortment-collection-block-title {
  margin-bottom: 1.6875rem;
  color: #FFF;
}
.Assortment .assortment-collection-block-text {
  min-height: 8.125rem;
  color: #E9D397;
}
.Assortment .assortment-collection-block-text p {
  line-height: 1.1;
}
.Assortment .assortment-collection-block-products {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 48rem) {
  .Assortment .Stagesliderfullwidth {
    margin-bottom: 0;
  }
  .Assortment .assortment-collection-block {
    padding: 0;
    margin-bottom: 2.1875rem;
  }
  .Assortment .assortment-collection-block::before {
    height: 80%;
  }
  .Assortment .assortment-collection-block-img {
    position: static;
    width: 100%;
    height: 18.9375rem;
  }
  .Assortment .assortment-collection-block-content {
    width: 100%;
    padding: 1.375rem 1.5625rem 1rem;
    margin-bottom: 0;
  }
  .Assortment .assortment-collection-block-title {
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    line-height: 1.1;
    -webkit-hyphens: auto;
    /* for Safari */
    hyphens: auto;
  }
  .Assortment .assortment-collection-block-text p {
    font-size: 0.875rem;
    letter-spacing: 0.015em;
    line-height: 1.23;
  }
  .Assortment .assortment-collection-block-text p:not(:last-child) {
    margin-bottom: 1.125rem;
  }
  .Assortment .assortment-collection-block-products {
    justify-content: flex-start;
    margin: 0 1.5625rem;
    padding: 0 0;
    padding-bottom: 1.5625rem;
  }
  .Assortment .assortment-collection-block-products::-webkit-scrollbar {
    height: 0.4375rem;
  }
  .Assortment .assortment-collection-block-products::-webkit-scrollbar-track {
    background: #CED0D1;
    border-radius: 10px;
  }
  .Assortment .assortment-collection-block-products::-webkit-scrollbar-thumb {
    background: #22292E;
    border-radius: 10px;
  }
}

@media screen and (max-width: 48rem) {
  .Assortment .m-swiperSlideContentMobile {
    display: none;
  }
  .Assortment .m-swiperSlideContent {
    display: none;
  }
  .Assortment .m-tp-swiper-container .swiper-button-next, .Assortment .m-tp-swiper-container .swiper-button-prev {
    bottom: 5.8em;
  }
  .Assortment .m-swiperSlideContent {
    width: 10.3125rem;
    right: 1.75rem;
    top: 0.6875rem;
  }
  .Assortment .m-swiperSlideContent-header {
    font-size: 1rem !important;
    font-weight: bold !important;
    font-stretch: condensed !important;
    line-height: 1.31 !important;
    text-align: center !important;
    padding: 0.125rem;
    margin-top: 0rem !important;
  }
  .Assortment .m-swiperSlideContent-description {
    font-size: 0.625rem !important;
    font-weight: bold !important;
    font-stretch: condensed !important;
    line-height: 2.1 !important;
    text-align: center !important;
  }
  .Assortment .m-swiperSlideContent-line--2 {
    background-color: #002a5c !important;
    color: #002a5c !important;
  }
  .Assortment .match_group_1 {
    padding-top: 0.1875rem;
  }
  .Assortment .m-tp-productbox-image img {
    width: 100% !important;
  }
  .Assortment .match_group_1 .m-tp-productbox-info,
.Assortment .match_group_2 .m-tp-productbox-info {
    padding-top: 0.8125rem;
    text-align: left;
    padding-left: 0;
    margin-left: 1.375rem;
    margin-bottom: 1.875rem;
  }
  .Assortment .match_group_1 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text,
.Assortment .match_group_2 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text {
    font-size: 1.125rem !important;
    font-weight: bold !important;
    font-stretch: condensed !important;
    text-align: left !important;
    color: #373f44 !important;
  }
  .Assortment .match_group_1 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text:after,
.Assortment .match_group_2 .tp-component-productlist .tp-component-productlistitem .c-tp-headline .c-tp-simplelink .c-tp-simplelink-text:after {
    position: absolute;
    content: "";
    top: 5%;
    right: -rem(24px);
    display: block;
    width: 1em;
    height: 100%;
    pointer-events: none;
    background-image: url(../img/c_tp_simplebutton_arrow_dark.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
  .Assortment .match_group_2 .tp-component-productlist .tp-component-productlistitem {
    width: 30rem;
    display: inline-block;
    text-align: center;
  }
  .Assortment .match_group_2 {
    padding-top: 0rem;
    padding-bottom: 0.9375rem;
  }
  .Assortment .match_group_1 .tp-component-productlist .tp-component-productlistitem,
.Assortment .match_group_2 .tp-component-productlist .tp-component-productlistitem {
    width: 100%;
  }
  .Assortment .m-tp-swiper-container .swiper-slide img,
.Assortment .m-tp-swiper-container .swiper-slide picture {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
  .Assortment .m-swiperSlideContent {
    width: 10.3125rem;
    left: 30%;
    top: 0.6875rem;
  }
  .Assortment .m-swiperSlideContent-description {
    margin-top: 0.125rem;
  }
}
.ContactsPage .m-inner-page {
  padding-bottom: 4.25rem;
}

.contactsPage {
  font-family: "Roboto Condensed";
}
@media screen and (max-width: 48rem) {
  .contactsPage .g-tp-row {
    margin: 0 -1.6875rem !important;
  }
}
.contactsPage .Jumbotron {
  position: relative;
}
.contactsPage .Jumbotron .m-jumbotron-content {
  max-width: 23.5625rem;
  position: absolute;
  top: 2.5625rem;
  left: 2.8125rem;
}
.contactsPage .Jumbotron .m-jumbotron-content-line {
  width: 100%;
  height: 0.125rem;
  background-color: #ffffff;
}
.contactsPage .Jumbotron .m-jumbotron-content-header {
  font-weight: bold !important;
  color: #fff !important;
  line-height: 2.125rem !important;
  font-size: 1.8125rem !important;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 0.4375rem;
}
.contactsPage .Jumbotron .m-jumbotron-content-header--1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.31;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.04375rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}
.contactsPage .Jumbotron .m-tp-singleimage {
  margin: 0 0 3.429em;
}
.contactsPage .Jumbotron .m-jumbotron img {
  height: 13.3125rem;
  width: 60.875rem;
  padding-bottom: 0;
}
.contactsPage .match_group_1 .tp-component-productlist .c-tp-headline--120 {
  font-size: 1.625rem;
  font-weight: 300;
  font-stretch: condensed;
  font-family: "Roboto Condensed";
  line-height: 1.23;
  text-align: left;
  color: #373f44;
  text-decoration: none !important;
  max-width: 38rem;
  display: block;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlist .c-tp-headline--120 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 21.25rem;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem {
  padding: 0 0 3.6875rem;
}
.contactsPage .match_group_1 .tp-component-productlistitem + .tp-component-productlistitem {
  padding-top: 3.75rem;
  border-top: 0.0625rem solid #A6A9AC;
}
.contactsPage .match_group_1 .tp-component-productlistitem:nth-child(1) .c-tp-headline--120 {
  margin-bottom: 1.1em;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem {
    padding: 0 0 2.3125rem;
  }
  .contactsPage .match_group_1 .tp-component-productlistitem + .tp-component-productlistitem {
    padding-top: 2.3125rem;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem:nth-child(2) .tp-component-product, .contactsPage .match_group_1 .tp-component-productlistitem:nth-child(3) .tp-component-product {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem:nth-child(2) .tp-component-product, .contactsPage .match_group_1 .tp-component-productlistitem:nth-child(3) .tp-component-product {
    display: block;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem:nth-child(2) .tp-component-product .c-tp-headline--120, .contactsPage .match_group_1 .tp-component-productlistitem:nth-child(3) .tp-component-product .c-tp-headline--120 {
  width: 28.5rem;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem:nth-child(2) .tp-component-product .c-tp-headline--120, .contactsPage .match_group_1 .tp-component-productlistitem:nth-child(3) .tp-component-product .c-tp-headline--120 {
    width: 100%;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem:nth-child(4) {
  padding-bottom: 1.5rem;
}
.contactsPage .match_group_1 .tp-component-productlistitem .c-tp-headline--small {
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  color: #8D8D8D;
  margin-top: 0.25rem;
  max-width: 17.5rem;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem .c-tp-headline--small {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem .c-tp-simplebutton {
  margin-right: auto;
  display: inline-block;
  margin-bottom: 0;
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--1 {
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: all 0.5s;
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--1:hover {
  opacity: 0.6;
  text-decoration: none;
  transition: all 0.5s;
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--1:hover:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  height: 0.125rem;
  opacity: 0.6;
  background-color: #373f44;
  transition: all 0.5s;
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--1:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  height: 0.125rem;
  background-color: #373f44;
  transition: all 0.5s;
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--2 {
  position: relative;
  text-decoration: underline;
  transition: all 0.5s;
  font-weight: 300;
  font-family: "Roboto Condensed";
  font-size: 1.625rem;
  line-height: 1.875rem;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--2 {
    margin-top: 0rem;
    display: inline-block;
    font-size: 1.25rem;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--2:hover {
  opacity: 0.6;
  text-decoration: none;
  transition: all 0.5s;
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--3 {
  position: relative;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--3 {
    margin-top: 0rem;
    margin-bottom: 0;
    font-size: 0.875rem;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--4 {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: -0.4375rem;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--4 {
    margin-top: 0rem;
    margin-bottom: 0;
    font-size: 0.875rem;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom {
  border-bottom: none;
  text-decoration: underline;
  transition: all 0.5s;
  font-weight: 300;
  font-family: "Roboto Condensed";
  font-size: 1.625rem;
  line-height: 1.875rem;
}
@media screen and (max-width: 48rem) {
  .contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom {
    margin-top: 0rem;
    display: inline-block;
    font-size: 1.25rem;
  }
}
.contactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom:hover {
  opacity: 0.6;
  border-bottom: none;
  text-decoration: none;
  transition: all 0.5s;
}
.contactsPage .match_group_1 .tp-component-productlistitem .tp-component-product {
  text-align: left;
}
.contactsPage .match_group_1 .m-tp-textblock--centered .c-tp-headline {
  margin: 0;
}
.contactsPage #map {
  height: 21.875rem;
  width: 100%;
}

@media screen and (max-width: 48rem) {
  .ontactsPage .Jumbotron .m-jumbotron img {
    height: auto;
    width: 100%;
  }
  .ontactsPage .Jumbotron .m-jumbotron-content {
    max-width: 8.875rem;
    position: absolute;
    top: 1.875rem;
    left: 1.1875rem;
  }
  .ontactsPage .Jumbotron .m-jumbotron-content-header--1 {
    font-size: 1.25rem;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 1.05;
    text-align: center;
    color: #ffffff;
    margin-top: 0.6875rem;
    margin-bottom: 0.8125rem;
  }
  .ontactsPage .match_group_1 span.c-tp-headline--120 {
    font-size: 1.25rem;
    font-weight: 300;
    font-stretch: condensed;
    line-height: 1.5;
    text-align: left;
    color: #373f44;
  }
  .ontactsPage .match_group_1 .tp-component-productlistitem .m-metanav-link--contact-custom--3:after {
    display: none;
  }
  .ontactsPage .match_group_1 .tp-component-productlist {
    margin-top: 0.5625rem;
    padding: 0 0.3125rem;
  }
  .ontactsPage .match_group_1 .tp-component-productlistitem {
    margin-bottom: 1.375rem;
  }
}
.aboutPage .js-afterelement-block {
  position: absolute;
  width: 0;
  height: 0;
  top: 22.625rem;
  left: 33.3125rem;
  opacity: 1;
  border-left: 1.25rem solid transparent;
  border-right: 1.25rem solid transparent;
  border-bottom: 1.5625rem solid #ebf3f6;
}
.aboutPage .js-afterelement-block--hidden {
  visibility: hidden;
}
.aboutPage .m-about-qs-headline .m-about-qs-years-switch--visible span {
  transition: all 0.5s;
}
.aboutPage .m-tp-swiper-container .swiper-button-prev {
  width: 1.1875rem;
  height: 1.625rem;
  top: 19.375rem;
  bottom: auto;
  right: 0.9375rem;
  overflow: visible;
}
.aboutPage .AboutSlider .m-tp-swiper-container .swiper-button-next, .aboutPage .AboutSlider .m-tp-swiper-container .swiper-button-prev {
  width: 1.1875rem;
  height: 1.625rem;
  top: 19.375rem;
  bottom: auto;
  right: 0.6875rem;
  overflow: visible;
}
.aboutPage .centerContent {
  min-height: 42.25rem;
}
.aboutPage .m-about-qs-headline .m-about-qs-years-switch::before {
  display: block;
  content: "";
  background-position: center center;
  background-repeat: no-repeat;
  height: 17.875rem;
}
.aboutPage .m-about-qs-headline {
  display: table;
  table-layout: fixed;
  min-height: 11.9375rem;
  width: 100%;
  background-image: url(../img/page-about/main-banner-slider.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
}
.aboutPage .m-about-qs-descriptions {
  margin-top: 1rem;
}
.aboutPage .h-tp-visuallyhidden {
  width: 61.25rem;
  min-height: 0rem !important;
  margin: 0;
  max-height: 0rem !important;
  transition: all 0.5s ease;
}
.aboutPage .m-tp-swiper-container .swiper-wrapper {
  height: auto !important;
}
.aboutPage .m-tp-swiper-container .swiper-slide {
  height: auto !important;
  position: initial !important;
}
.aboutPage .m-about-qs-descriptions-item {
  width: 61.25rem;
  min-height: 16.5rem;
  background-image: linear-gradient(to bottom, #ebf3f6, #d4dde5);
  margin: 0;
  transition: all 0.5s ease;
  position: relative !important;
  max-height: 31.25rem;
}
.aboutPage .js-afterelement-block--anim {
  animation: ANIMATION_NAME 0.8s forwards;
}
@keyframes ANIMATION_NAME {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.aboutPage .m-bannerMore-image {
  width: 10.3125rem !important;
  height: 10.3125rem !important;
  position: absolute;
  margin-top: 2.4375rem;
  margin-left: 2.1875rem;
  opacity: 0;
  transition: all 0.3s ease;
}
.aboutPage .m-bannerMore-image--visible {
  opacity: 1;
  transition: all 0.5s 0.4s ease;
}
.aboutPage .copytext {
  font-family: "Roboto", Arial, Verdana, Geneva, Helvetica, sans-serif !important;
  font-size: 1.375rem !important;
  font-weight: 200 !important;
  font-stretch: condensed !important;
  line-height: 1.45 !important;
  letter-spacing: -0.06875rem !important;
  color: #002a5b;
  padding-top: 2.5625rem;
  padding-right: 2.5rem;
  padding-left: 15.1875rem;
  text-align: left;
  opacity: 0;
  transition: all 0.3s ease;
}
.aboutPage .copytext--visible {
  opacity: 1;
  transition: all 0.5s 0.4s ease;
}
.aboutPage .m-about-qs-years-switch--opacity .m-bannerMore-image {
  opacity: 1;
}
.aboutPage .m-about-qs-years-switch--opacity .copytext {
  opacity: 1;
}
.aboutPage .slider-header {
  min-height: 18.125rem;
  width: 100%;
  background-image: url(../img/page-about/main-banner-slider.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 10;
}
.aboutPage .data-link-icon {
  width: 3.125rem;
  height: 2rem;
  margin: 0 auto;
}
.aboutPage .slick-slider-items {
  position: relative;
}
.aboutPage .slick-slider {
  top: -rem(20px);
}
.aboutPage .slick-dots {
  display: none !important;
}
.aboutPage .slick-next {
  right: 0.6875rem !important;
  top: 3.375rem !important;
  width: 5.3125rem !important;
  height: 2.0625rem !important;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
}
.aboutPage .slick-prev {
  transform: rotate(180deg);
  left: 1.0625rem !important;
  top: 2.3125rem !important;
  opacity: 1 !important;
  width: 5.3125rem !important;
  height: 2.0625rem !important;
  z-index: 10;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiB3aWR0aD0iMTYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNiAyNCI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+LmNscy0yIHsKICAgICAgICAgICAgZmlsbDogIzMzMzMzMzsKICAgICAgICAgICAgc3Ryb2tlOiAjMzMzMzMzOwogICAgICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOwogICAgICAgICAgICBzdHJva2Utd2lkdGg6IDFweDsKICAgICAgICB9PC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGQ9Ik0xMC42NTYgMTIuNDkxbC03IDYuOTk5LjY1NC42NTQgOC4xMTItOC4xMTEtOC4xNzctOC4xNzctLjY1NC42NTQgNy4xMyA3LjEzIiBpZD0icGF0aC0xIiBjbGFzcz0iY2xzLTIiCiAgICAgICAgICBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+) right center no-repeat !important;
}
.aboutPage .data-link {
  width: 2rem;
  height: 2rem;
  position: relative;
  display: table-cell;
}
.aboutPage .data-link:after {
  content: "";
  position: absolute;
  width: 2.1875rem;
  height: 0.125rem;
  top: 4.8125rem;
  background-color: transparent;
  transition: all 0.3s ease;
}
.aboutPage .data-link:before {
  content: "";
  display: block;
  box-sizing: content-box;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  margin-bottom: 0.75rem;
  border: 0.0625rem solid #ccc;
  border-radius: 50%;
  background-color: #ffffff;
  transition: all 0.3s;
  background-image: url(../img/icon_plus.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 46%;
  background-position: center;
  background-repeat: no-repeat;
}
.aboutPage .data-link--visible {
  width: 2rem;
  height: 2rem;
  position: absolute;
}
.aboutPage .data-link--visible:after {
  content: "";
  position: absolute;
  width: 2.1875rem;
  height: 0.125rem;
  top: 4.8125rem;
  background-color: #837650;
  transition: all 0.3s ease;
}
.aboutPage .data-link--visible:before {
  background-image: url(../img/js_tp_button_close-gold.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 38%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
}
.aboutPage .data-link-text {
  text-align: center;
  display: inline-block;
  margin: 4em;
  margin-top: 0.8125rem;
  padding-bottom: 0.25em;
  border-bottom: solid transparent 0.125rem;
  font-family: "Roboto Condensed", Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  color: #373f44;
  margin-bottom: 0.3125rem;
}
.aboutPage .data-content .m-about-qs-descriptions-item {
  margin-top: -rem(53px);
}

@media screen and (max-width: 21.875rem) {
  .m-about-qs-headline .m-about-qs-years-switch::before {
    display: block;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    height: 12.5rem;
  }
}
@media screen and (max-width: 48rem) {
  .aboutPage .m-about-qs-headline {
    display: table;
    table-layout: fixed;
    min-height: 11.9375rem;
    width: 100%;
    background-image: url(../img/page-about/main-banner-slider.jpg);
    background-size: 153%;
    background-repeat: no-repeat;
  }
  .aboutPage .m-tp-swiper-container .swiper-button-prev {
    top: 5.3em;
    right: auto;
    left: 0;
    width: 2.5em;
    height: 2.5em;
    background-size: 2.5em;
    bottom: 10.8em;
  }
  .aboutPage .m-about-qs-headline .m-about-qs-years-switch::before {
    display: block;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    height: 14.875rem;
  }
  .aboutPage .m-about-qs-descriptions .copytext {
    font-size: 1.5rem !important;
    font-weight: 300 !important;
    font-stretch: condensed !important;
    line-height: 1.33 !important;
    text-align: left !important;
    color: #373f44;
    padding-top: 2.125rem;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    text-align: left;
    padding-bottom: 2.375rem;
  }
  .aboutPage .m-about-qs-descriptions-item {
    width: 100%;
  }
  .aboutPage .m-bannerMore-image {
    width: 10.3125rem !important;
    height: 10.3125rem !important;
    position: initial;
    margin-top: 0rem;
    margin-left: 0rem;
    margin: 0 auto;
  }
  .aboutPage .m-about-qs-descriptions-item {
    padding-top: 2.3125rem;
  }
  .aboutPage .m-about-qs-descriptions {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .aboutPage .m-about-qs-headline .m-about-qs-years-switch--visible span:before {
    background-color: #373f44;
    box-shadow: 0 0 0.125rem 0 rgba(0, 0, 0, 0.5);
  }
  .aboutPage .m-about-qs-headline .m-about-qs-years-switch--visible span {
    border: none;
  }
  .aboutPage .slider-header {
    min-height: 10.6875rem;
    width: 100%;
    background-image: url(../img/page-about/main-banner-slider.jpg);
    background-size: 163%;
    background-repeat: no-repeat;
    z-index: 10;
    background-position: 47% 0%;
  }
  .aboutPage .data-link-text {
    text-align: center;
    display: inline-block;
    padding-bottom: 0.25em;
    border-bottom: solid transparent 0.125rem;
    font-family: "Roboto Condensed", Arial, sans-serif;
    font-size: 2.1rem;
    font-weight: bold;
    color: #373f44;
    margin-bottom: 0.3125rem;
    width: 100%;
    margin: 0 auto;
    margin-top: 1.5625rem;
  }
  .aboutPage .data-link:before {
    content: "";
    display: block;
    box-sizing: content-box;
    width: 3.125rem;
    height: 3.125rem;
    margin: 0 auto;
    margin-bottom: 0.75rem;
    border: 0.0625rem solid #ccc;
    border-radius: 50%;
    background-color: #ffffff;
    transition: all 0.3s;
    background-image: url(../img/icon_plus.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 44%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .aboutPage .slick-slider {
    top: -rem(27px);
  }
  .aboutPage .copytext {
    font-size: 1.5rem !important;
    font-weight: 300 !important;
    font-stretch: condensed !important;
    line-height: 1.33 !important;
    text-align: left !important;
    color: #373f44;
    padding-top: 2.125rem;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    text-align: left;
    padding-bottom: 2.375rem;
  }
  .aboutPage .m-about-qs-descriptions-item {
    padding-top: 0;
  }
  .aboutPage .js-afterelement-block {
    display: none;
  }
  .aboutPage .data-link--visible:after {
    content: "";
    display: none;
  }
  .aboutPage .data-link--visible:before {
    background-color: #373f44;
    background-image: url(../img/js_tp_button_close-gold.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 38%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s;
  }
  .aboutPage .slick-next {
    right: 0rem !important;
    top: -rem(59px) !important;
    width: 5.3125rem !important;
    height: 2.0625rem !important;
    background: url(../img/page-about/right_icon.svg) right center no-repeat !important;
  }
  .aboutPage .slick-next:before {
    content: "" !important;
  }
  .aboutPage .slick-prev {
    transform: rotate(360deg);
    left: -rem(53px) !important;
    top: -rem(76px) !important;
    opacity: 1 !important;
    width: 5.3125rem !important;
    height: 2.0625rem !important;
    z-index: 10;
    background: url(../img/page-about/left_icon.svg) right center no-repeat !important;
  }
  .aboutPage .m-about-qs-descriptions-item--img {
    padding-top: 2.3125rem;
  }
}
@media screen and (max-width: 23.4375rem) {
  .aboutPage .m-about-qs-headline .m-about-qs-years-switch::before {
    display: block;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    height: 12.75rem;
  }
}
@media screen and (max-width: 48rem) {
  .faqPage.centerWrapper {
    padding: 0 1.875rem;
  }
}
.faqPage .faq-title {
  font-weight: 300;
  font-size: 2rem;
  line-height: 1.875rem;
  margin: 4.375rem 0 2.0625rem;
}
.faqPage .faq-btns {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 3.125rem 0 5.625rem;
}

.accordion {
  background: #fff;
  width: 100%;
  border-bottom: 0.0625rem solid #A6A9AC;
}
.accordion a {
  color: red;
  text-decoration: underline;
}
.accordion a:hover {
  text-decoration: none;
}
.accordion table {
  margin-top: 1.25rem;
}
.accordion table tr td {
  padding: 0.3125rem 1.25rem;
  border: 0.0625rem solid red;
}
.accordion table tr th {
  border: 0.0625rem solid red;
  padding: 0.3125rem 1.25rem;
}
.accordion__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2.125rem 3.75rem 2.0625rem 0;
  cursor: pointer;
  box-sizing: border-box;
}
@media screen and (max-width: 48rem) {
  .accordion__panel {
    padding: 2.5rem 3.75rem 1.25rem 0;
  }
}
.accordion__icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1.625rem;
  height: 0.875rem;
  transform: translateY(-50%);
  transform-origin: 50% 50%;
  transition: all 0.3s ease-in-out;
}
.accordion.active .accordion__icon {
  transform: translateY(-50%) rotate(180deg);
}
.accordion__icon svg {
  display: block;
  width: 100%;
  height: 100%;
  color: #C0A468;
  transition: all 0.3s ease-in-out;
}
.accordion.active .accordion__icon svg {
  color: #000;
}
.accordion__title {
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  letter-spacing: 0.02em;
}
@media screen and (max-width: 48rem) {
  .accordion__title {
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}
.accordion__content {
  display: none;
}
.accordion__content-inner {
  font-family: "Roboto Condensed", Arial, sans-serif !important;
  font-size: 1.125rem !important;
  font-weight: 300 !important;
  line-height: 1.33 !important;
  letter-spacing: 0.01875rem;
  color: #373f44;
  padding: 0.75rem 5.4375rem 2.5rem 0;
  box-sizing: border-box;
  background: #fff;
}
@media screen and (max-width: 48rem) {
  .accordion__content-inner {
    padding-right: 0;
  }
}
.accordion__content-inner img {
  display: block;
  max-width: 100%;
  height: auto;
}

.prof-button {
  color: #22407c;
  border: 0.0625rem solid #22407c;
  border-radius: 0.25rem;
  max-width: 14rem;
  display: block;
  margin: 0 auto 0.9375rem;
  margin-top: 3.9375rem;
  margin-bottom: 0;
  line-height: 2.875rem;
  font-size: 1.6rem;
  text-align: center;
  height: 3rem;
  font-family: Roboto, Arial, Helvetica, Verdana, sans-serif !important;
  letter-spacing: 0;
  transition: 0.3s ease;
}
.prof-button:hover {
  background-color: #22407c;
  color: white;
}

@media screen and (max-width: 48rem) {
  .prof-button {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }
}