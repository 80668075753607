
@import "common/_mixins";
@import "common/_variables";
@import "common/_colors";
@import "common/_fonts";
@import "common/_reset";
@import "common/_app";
@import "common/_popup";
@import "common/_select";
@import "common/_form";

@import "common/typography";

@import "common/blocks/_clear";
@import "common/blocks/_wrapper";
@import "common/blocks/_btn";
@import "common/blocks/_header";
@import "common/blocks/_footer";

// components
@import "common/blocks/components/inner-page-nav.scss";
@import "common/blocks/components/text-block";
@import "common/blocks/components/news-block";
@import "common/blocks/components/product";

// inner-page
@import "common/blocks/inner-page/inner-page.scss";

// roasting
@import "common/blocks/roasting/roasting.scss";

// varieties
@import "common/blocks/varieties/varieties.scss";

// grain-processing
@import "common/blocks/grain-processing/grain-processing.scss";

// cooking-methods
@import "common/blocks/cooking-methods/cooking-methods.scss";

//main page
@import "common/blocks/main-page/_main-page";
@import "common/blocks/main-page/_main-slideshow";
@import "common/blocks/main-page/_m-bannerMore";
@import "common/blocks/main-page/_new-blocks";
@import "common/blocks/main-page/_main-adaptive";


//product page
@import "common/blocks/product-page/_product-page";
@import "common/blocks/product-page/_product-page-adaptive";

//chain-stores page
@import "common/blocks/chain-stores/_chain-stores";

//expertise page
@import "common/blocks/expertise/_expertise";

//news page
@import "common/blocks/news/_news";
@import "common/blocks/news/_news-adaptive";

//404error
@import "common/blocks/404error/_404error";

//assortment
@import "common/blocks/assortment/_assortment";
@import "common/blocks/assortment/_assortment-adptive";

//contacts
@import "common/blocks/contacts/_contacts";
@import "common/blocks/contacts/_contacts-adaptive";

//about
@import "common/blocks/about/_about";
@import "common/blocks/about/_about-adaptive";

//faq
@import "common/blocks/faq/_faq";

.prof-button {
  color: #22407c;
  border: rem(1px) solid #22407c;
  border-radius: rem(4px);
  max-width: rem(224px);
  display: block;
  margin: 0 auto rem(15px);
  margin-top: rem(63px);
  margin-bottom: 0;
  line-height: rem(46px);
  font-size: 1.6rem;
  text-align: center;
  height: rem(48px);
  font-family: Roboto, Arial, Helvetica, Verdana, sans-serif!important;
  letter-spacing: 0;
  transition: .3s ease;
  &:hover {
    background-color: #22407c;
    color: white;
  }
}

@media screen and (max-width: rem(768px)) {
  .prof-button {
    margin-top: rem(30px);
    margin-bottom: rem(30px);
  }
}
