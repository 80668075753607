@media screen and (max-width: rem(768px)) {
   .News .Jumbotron .m-jumbotron img {
        height: rem(199px);
        width: auto;
        margin-bottom: -rem(20px);
    }
    .News .m-tp-singleimage {
       margin: 0;
    }
    .News .Newslist .m-newsItem .c-tp-headline--span {
        font-size: rem(11px);
        font-stretch: condensed;
        line-height: 2.55;
        text-align: left;
        color: #373f44;
    }
    .News .Newslist .m-newsItem-content-header {
        font-size: rem(24px) !important;
        font-weight: bold !important;
        font-stretch: condensed !important;
        line-height: 1.42 !important;
        text-align: left !important;
    }
    .News .Newslist .m-newsItem-content-description {
        font-size: rem(16px) !important;
        font-stretch: condensed !important;
        line-height: 1.5 !important;
        text-align: left;
        color: #373f44;
        margin-top: rem(23px);
        margin-bottom: rem(45px);
    }
    .News .Newslist .m-newsItem-content {
        position: relative;
    }
    .News .Newslist .m-newsItem {
        padding-top: rem(7px);
    }
    .News .Newslist .m-newsItem .c-tp-headline--span {
        top: -rem(10px);
        left: 0;
    }
    .News .Newslist .m-newsItem .m-tp-singleimage img {
        height: rem(200px);
        margin-bottom: rem(10px);
    }
    .News .Jumbotron .m-jumbotron-content {
        max-width: rem(150px);
        position: absolute;
        top: rem(60px);
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

    }
    .News .Jumbotron .m-jumbotron-content-header--1 {
        font-size: rem(20px);
        font-weight: bold;
        font-stretch: condensed;
        line-height: 1.05;
        text-align: center;
        color: #ffffff;
        padding: rem(6px);
    }
}
