html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  // font-size: rem(14px);
  font-size: 4.9vw;
  // @media (min-width: $xs) {
  //   font-size: 2.9vw;
  // }
  @media (max-width: $md) and ( orientation: landscape) {
    font-size: 1.8vw;
  }
  @media (min-width: $sm) and ( orientation: portrait) {
    font-size: 1.3vw;
  }
  @media (min-width: $md) and ( orientation: landscape) {
    font-size: 1.111111vw;
  }

  @media (min-width: $max) {
    font-size: 1.111111vw;
  }
}
body {
  font-family: $alt-font ,Arial,Verdana,Geneva,Helvetica,sans-serif;
  font-size: rem(16px);
}

// * {
// 	font-family: $main-font ,Arial,Verdana,Geneva,Helvetica,sans-serif !important;
// }
.clear {
	clear: both;
}
.no-scroll {
  overflow: hidden;
}

@media screen and (min-width: rem(421px)) and (max-width: rem(768px)) {
	html, body {
		// min-width: rem(768px) !important;
	}	
}
// @media screen and (min-width: rem(650px)) and (max-width: rem(768px)) {
// 	html, body {
// 		min-width: initial !important;
// 	}	
// }

@media (max-width: $md) and ( orientation: portrait) {
  .full-width-img {
    width: calc(100% + #{rem(34px)}) !important;
    margin-left: rem(-17px);
  }
  .g-tp-column {
    padding: 0 rem(17px);
  }
  .m-tp-textblock {
    padding-left: 0;
    padding-right: 0;
  }
}

.slick-dotted.slick-slider {
  .slick-dots {
    bottom: rem(-35px);
    li {
      width: rem(12px);
      height: rem(12px);
      margin: 0 rem(8px);
      button {
        padding: 0;
        width: 100%;
        height: 100%;
        &::before {
          font-size: 0;
          width: 100%;
          height: 100%;
          transform: scale(0.6);
          transition: 0.4s;
          transform-origin: center;
          border: 1px solid #423C39;
          border-radius: 50%;
          opacity: 1;
        }
      }
      &.slick-active{
        button {
          &::before {
            transform: scale(1);
            background: #423C39;
          }
        }
      }
    }
  }
  @media screen and (max-width: rem(768px))  {
    .slick-dots {
      bottom: rem(-27px);
      li {
        margin: 0 rem(11px);
      }
    }
  }
}

.mCSB_horizontal {
  width: 100%;
  .mCSB_container {
    display: flex;
    padding-bottom: rem(29px);
    justify-content: center;
    @media screen and (max-width: rem(768px)) {
      justify-content: flex-start;
    }
    @media screen and (min-width: rem(769px)) {
      width: 100% !important;
      padding-bottom: 0;
    }
  }
  .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    height: rem(7px);
    opacity: 1;
    @media screen and (min-width: rem(769px)) {
      display: none;
    }
    .mCSB_dragger {
      .mCSB_dragger_bar {
        margin: 0;
        height: 100%;
        background: #22292E;
        border: 2px solid #CED0D1;
        opacity: 1 !important;
      }
    }
    .mCSB_draggerRail {
      height: 100%;
      margin: 0;
      background: #CED0D1;
    }
  }
}