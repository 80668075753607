.Assortment {
  .Stagesliderfullwidth {
    margin-bottom: rem(83px);
  }
  .assortment-collection-block {
    position: relative;
    padding: 0 rem(155px);
    margin-bottom: rem(80px);
    &::before {
      content: '';
      position: absolute;
      top: rem(70px);
      left: 0;
      width: 100%;
      height: vh(635px);
      display: block;
      background: $brown;
      z-index: -1;
    }
    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 48.5%;
      height: vh(632px);
      object-fit: cover;
      z-index: -1;
    }
    &-content {
      width: 51.5%;
      margin-left: auto;
      padding-left: rem(61px);
      padding-top: rem(105px);
      margin-bottom: rem(32px);
    }
    &-title {
      margin-bottom: rem(27px);
      color: #FFF;
    }
    &-text {
      min-height: rem(130px);
      color: $yellow;
      p {
        line-height: 1.1;
      }
    }
    &-products {
      display: flex;
      justify-content: center;
    }
  }
  @media screen and (max-width: rem(768px)) {
    .Stagesliderfullwidth {
      margin-bottom: 0;
    }
    .assortment-collection-block {
      padding: 0;
      margin-bottom: rem(35px);
      &::before {
        height: 80%;
      }
      &-img {
        position: static;
        width: 100%;
        height: rem(303px);
      }
      &-content {
        width: 100%;
        padding: rem(22px) rem(25px) rem(16px);
        margin-bottom: 0;
      }
      &-title {
        margin-bottom: rem(20px);
        font-size: rem(24px);
        line-height: 1.1;
        // word-break: break-word;
        -webkit-hyphens: auto; /* for Safari */
        hyphens: auto;
      }
      &-text {
        p {
          font-size: rem(14px);
          letter-spacing: 0.015em;
          line-height: 1.23;
          &:not(:last-child) {
            margin-bottom: rem(18px);
          }
        }
      }
      &-products {
        justify-content: flex-start;
        margin: 0 rem(25px);
        padding: 0 0;
        padding-bottom: rem(25px);
        // overflow-x: auto;
        &::-webkit-scrollbar {
          height: rem(7px);
        }
        &::-webkit-scrollbar-track {
          background: #CED0D1;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: #22292E;
          border-radius: 10px;
        }
      }
    }
  }
}