@media screen and (max-width: rem(350px)) {
    .m-about-qs-headline .m-about-qs-years-switch::before {
            display: block;
            content: "";
            background-position: center center;
            background-repeat: no-repeat;
            height: rem(200px);
    }
}    
@media screen and (max-width: rem(768px)) {
    .aboutPage {
        .m-about-qs-headline {
            display: table;
            table-layout: fixed;
            min-height: rem(191px);
            width: 100%;
            background-image: url(../img/page-about/main-banner-slider.jpg);
            background-size: 153%;
            background-repeat: no-repeat;
        }
        .m-tp-swiper-container .swiper-button-prev {
            top: 5.3em;
            right: auto;
            left: 0;
            width: 2.5em;
            height: 2.5em;
            background-size: 2.5em;
            bottom: 10.8em;
        }
        .m-about-qs-headline .m-about-qs-years-switch::before {
            display: block;
            content: "";
            background-position: center center;
            background-repeat: no-repeat;
            height: rem(238px);
        }
        .m-about-qs-descriptions .copytext {
            font-size: rem(24px) !important;
            font-weight: 300 !important;
            font-stretch: condensed !important;
            line-height: 1.33 !important;
            text-align: left !important;
            color: #373f44;
            padding-top: rem(34px);
            padding-right: rem(10px);
            padding-left: rem(10px);
            text-align: left;
            padding-bottom: rem(38px);
        }
        .m-about-qs-descriptions-item {
            width: 100%;
        }    
        .m-bannerMore-image {
            width: rem(165px) !important;
            height: rem(165px) !important;
            position: initial; 
            margin-top: rem(0px);
            margin-left: rem(0px);
            margin: 0 auto;
        }
        .m-about-qs-descriptions-item {
            padding-top: rem(37px);
        }
        .m-about-qs-descriptions {
            padding-right: rem(0px);
            padding-left: rem(0px);
        }
        .m-about-qs-headline .m-about-qs-years-switch--visible span:before {
             background-color: #373f44;
             box-shadow: 0 0 rem(2px) 0 rgba(0, 0, 0, 0.5);
        }
        .m-about-qs-headline .m-about-qs-years-switch--visible span {
            border: none;
        }
        .slider-header {
            min-height: rem(171px);
            width: 100%;
            background-image: url(../img/page-about/main-banner-slider.jpg);
            background-size: 163%;
            background-repeat: no-repeat;
            z-index: 10;
            background-position: 47% 0%;
        }
        .data-link-text {
            text-align: center;
            display: inline-block;
            padding-bottom: .25em;
            border-bottom: solid transparent rem(2px);
            font-family: 'Roboto Condensed',Arial,sans-serif;
            font-size: 2.1rem;
            font-weight: bold;
            color: #373f44;
            margin-bottom: rem(5px);
            width: 100%;
            margin: 0 auto;
            margin-top: rem(25px);
            // margin-left: rem(20px);
        }
        .data-link:before {
            content: "";
            display: block;
            box-sizing: content-box;
            width: rem(50px);
            height: rem(50px);
            margin: 0 auto;
            margin-bottom: rem(12px);
            border: rem(1px) solid #ccc;
            border-radius: 50%;
            background-color: #ffffff;
            transition: all 0.3s;
            background-image: url(../img/icon_plus.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-size: 44%;
            background-position: center;
            background-repeat: no-repeat;
        }
        .slick-slider {
            top: -rem(27px);
        }
        .copytext {
            font-size: rem(24px) !important;
            font-weight: 300 !important;
            font-stretch: condensed !important;
            line-height: 1.33 !important;
            text-align: left !important;
            color: #373f44;
            padding-top: rem(34px);
            padding-right: rem(10px);
            padding-left: rem(10px);
            text-align: left;
            padding-bottom: rem(38px);
        }
        .m-about-qs-descriptions-item {
            padding-top: 0;
        }
        .js-afterelement-block {
            display: none;
        }
        .data-link--visible:after {
            content: '';
            display: none;
        }
         .data-link--visible:before { 
            background-color: #373f44;
            background-image: url(../img/js_tp_button_close-gold.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-size: 38%;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.3s;
            
        } 
        .slick-next {
            right: rem(0px) !important;
            top: -rem(59px) !important;
            width: rem(85px) !important;
            height: rem(33px) !important;
            background: url(../img/page-about/right_icon.svg) right center no-repeat !important;
            &:before {
                content: '' !important;
            }
        }
        .slick-prev {
            transform: rotate(360deg);
            left: -rem(53px) !important;
            top: -rem(76px) !important;
            opacity: 1 !important;
            width: rem(85px) !important;
            height: rem(33px) !important;
            z-index: 10;
            background: url(../img/page-about/left_icon.svg) right center no-repeat !important; 
        }   
        .m-about-qs-descriptions-item--img {
            padding-top: rem(37px);
        }
         
    }    
}    
@media screen and (max-width: rem(375px)) {
     .aboutPage {
        .m-about-qs-headline .m-about-qs-years-switch::before {
            display: block;
            content: "";
            background-position: center center;
            background-repeat: no-repeat;
            height: rem(204px);
        }
    }    
}

