.ContactsPage {
  .m-inner-page {
    padding-bottom: rem(68px);
  }
}

.contactsPage {
  font-family: "Roboto Condensed";
  @media screen and (max-width: rem(768px)) {
    .g-tp-row {
      margin: 0 rem(-27px) !important;
    }
  }
  .Jumbotron {
    position: relative;

    .m-jumbotron-content {
      max-width: rem(377px);
      position: absolute;
      top: rem(41px);
      left: rem(45px);
    }

    .m-jumbotron-content-line {
      width: 100%;
      height: rem(2px);
      background-color: #ffffff;
    }

    .m-jumbotron-content-header {
      font-weight: bold !important;
      color: #fff !important;
      line-height: rem(34px) !important;
      font-size: rem(29px) !important;
      text-transform: uppercase;
      padding-top: rem(8px);
      padding-bottom: rem(7px);
    }

    .m-jumbotron-content-header--1 {
      font-size: rem(32px);
      font-weight: bold;
      line-height: 1.31;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: rem(0.7px);
      margin-top: rem(5px);
      margin-bottom: rem(5px);
    }

    .m-tp-singleimage {
      margin: 0 0 3.429em;
    }

    .m-jumbotron {
      img {
        height: rem(213px);
        width: rem(974px);
        padding-bottom: 0;
      }
    }
  }

  .match_group_1 {
    .tp-component-productlist {
      .c-tp-headline--120 {
        font-size: rem(26px);
        font-weight: 300;
        font-stretch: condensed;
        font-family: "Roboto Condensed";
        line-height: 1.23;
        text-align: left;
        color: #373f44;
        text-decoration: none !important;
        max-width: rem(608px);
        display: block;

        @media screen and (max-width: rem(768px)) {
          font-size: rem(20px);
          line-height: rem(24px);
          max-width: rem(340px);
        }
      }
    }

    .tp-component-productlistitem {
      padding: 0 0 rem(59px);


      &+.tp-component-productlistitem {
        padding-top: rem(60px);
        border-top: rem(1px) solid #A6A9AC;
      }

      &:nth-child(1) {
        .c-tp-headline--120 {
          margin-bottom: 1.1em;
        }
      }
      @media screen and (max-width: rem(768px)) {
        padding: 0 0 rem(37px);
        &+.tp-component-productlistitem {
          padding-top: rem(37px);
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        .tp-component-product {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          @media screen and (max-width: rem(768px)) {
            display: block;
          }

          .c-tp-headline--120 {
            width: rem(456px);

            @media screen and (max-width: rem(768px)) {
              width: 100%;
            }
          }
        }
      }

      &:nth-child(4) {
        padding-bottom: rem(24px);
      }

      .c-tp-headline--small {
        display: block;
        font-size: rem(16px);
        line-height: rem(20px);
        font-weight: 300;
        color: #8D8D8D;
        margin-top: rem(4px);
        max-width: rem(280px);
        @media screen and (max-width: rem(768px)) {
          font-size: rem(14px);
          line-height: rem(16px);
        }
      }

      .c-tp-simplebutton {
        margin-right: auto;
        display: inline-block;
        margin-bottom: 0;
      }

      .m-metanav-link--contact-custom--1 {
        display: inline-block;
        position: relative;
        text-decoration: none;
        transition: all 0.5s;

        &:hover {
          opacity: 0.6;
          text-decoration: none;
          transition: all 0.5s;

          &:after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateY(100%);
            height: rem(2px);
            opacity: 0.6;
            background-color: #373f44;
            transition: all 0.5s;
          }
        }

        &:after {
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          bottom: 0;
          transform: translateY(100%);
          height: rem(2px);
          background-color: #373f44;
          transition: all 0.5s;
        }
      }

      .m-metanav-link--contact-custom--2 {
        position: relative;
        text-decoration: underline;
        transition: all 0.5s;
        font-weight: 300;
        font-family: "Roboto Condensed";
        font-size: rem(26px);
        line-height: rem(30px);

        @media screen and (max-width: rem(768px)) {
          margin-top: rem(0px);
          display: inline-block;
          font-size: rem(20px);
        }

        &:hover {
          opacity: 0.6;
          text-decoration: none;
          transition: all 0.5s;
        }
      }

      .m-metanav-link--contact-custom--3 {
        position: relative;
        text-decoration: none;
        font-size: rem(16px);
        line-height: rem(20px);
        font-weight: 300;
        @media screen and (max-width: rem(768px)) {
          margin-top: rem(0px);
          margin-bottom: 0;
          font-size: rem(14px);
        }
      }

      .m-metanav-link--contact-custom--4 {
        display: block;
        margin-top: rem(8px);
        margin-bottom: rem(-7px);

        @media screen and (max-width: rem(768px)) {
          margin-top: rem(0px);
          margin-bottom: 0;
          font-size: rem(14px);
        }
      }

      .m-metanav-link--contact-custom {
        border-bottom: none;
        text-decoration: underline;
        transition: all 0.5s;
        font-weight: 300;
        font-family: "Roboto Condensed";
        font-size: rem(26px);
        line-height: rem(30px);

        @media screen and (max-width: rem(768px)) {
          margin-top: rem(0px);
          display: inline-block;
          font-size: rem(20px);
        }

        &:hover {
          opacity: 0.6;
          border-bottom: none;
          text-decoration: none;
          transition: all 0.5s;
        }
      }

      .tp-component-product {
        text-align: left;
      }
    }

    .m-tp-textblock--centered {
      .c-tp-headline {
        margin: 0;
      }
    }
  }

  #map {
    height: rem(350px);
    width: 100%;
  }
}
