$select-padding-y: rem(13px);
$select-padding-x: rem(25px);
$select-arrow-width: rem(30px);
$z-index--dropdown: 9;
$select-font: 'Roboto Condensed', Arial, Verdana, Geneva, Helvetica, sans-serif;
$select-font-size: rem(15px);
$select-border-color: transparent;
$select-bg: white;
$select-bg--selected: transparent;
$select-color: #5e5e5e;
$select-placeholder-color: black;
$select-color--selected: black;
$select-shadow: 0 rem(8px) rem(18px) rgba(#9a80c9, 0.3);
.select2-container {
  box-sizing: border-box;

  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100%;
  font-size: $select-font-size;

  .select2-selection--single {
    box-sizing: border-box;

    cursor: pointer;
    display: block;

    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: block;
      padding-top: $select-padding-y;
      padding-bottom: $select-padding-y;
      padding-left: $select-padding-x;
      padding-right: $select-padding-x + $select-arrow-width;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select2-selection__clear {
      position: relative;
    }
  }

  &[dir="rtl"] {
    .select2-selection--single {
      .select2-selection__rendered {
        padding-right: $select-padding-x;
        padding-left: $select-padding-x + $select-arrow-width;
      }
    }
  }

  .select2-selection--multiple {
    box-sizing: border-box;

    cursor: pointer;
    display: block;

    min-height: rem(32px);

    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: rem(8px);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .select2-search--inline {
    float: left;

    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: rem(5px);
      padding: 0;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }


  &--open, &--default .select2-selection {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.select2-dropdown {
  background-color: $select-bg;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: rem(-100000px);
  width: 100%;
  z-index: $z-index--dropdown;
  font: $select-font;
  border-radius: rem(25px);
  box-shadow: $select-shadow;
  margin-top: rem(-1px);
  padding: rem(10px) 0 rem(14px);
}

.select2-results {
  display: block;
  border-top: rem(1px) solid $select-border-color;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 0.5em $select-padding-x;

  user-select: none;
  -webkit-user-select: none;

  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: rem(4px);

  .select2-search__field {
    padding: rem(4px);
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}


.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;

  // styles required for IE to work

  background-color: #fff;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: rem(1px) !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: rem(1px) !important;
  white-space: nowrap !important;
}

.select2-container--default {
  .select2-selection--single {
    background-color: $select-bg;
    border: 0;
    border-radius: rem(25px);
    box-shadow: $select-shadow;

    .select2-selection__rendered {
      color: $select-color;
      font-family: $select-font;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }

    .select2-selection__placeholder {
      color: $select-placeholder-color;
    }

    .select2-selection__arrow {
      width: rem(30px);
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      b {
        width: rem(15px);
        height: rem(15px);
        background: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.71045L8.5 7.71045L2 1.71045' stroke='%23C0A468' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
        background-size: contain;
        transform-origin: center;
        transition: transform 0.3s;
      }
    }
  }

  &[dir="rtl"] {
    .select2-selection--single {
      .select2-selection__clear {
        float: left;
      }

      .select2-selection__arrow {
        left: rem(1px);
        right: auto;
      }
    }
  }

  &.select2-container--disabled {
    .select2-selection--single {
      background-color: #eee;
      cursor: default;

      .select2-selection__clear {
        display: none;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .select2-selection--multiple {
    background-color: white;
    border: rem(1px) solid #aaa;
    border-radius: rem(4px);
    cursor: text;

    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 rem(5px);
      width: 100%;

      li {
        list-style: none;
      }
    }

    .select2-selection__placeholder {
      color: #999;

      margin-top: rem(5px);

      float: left;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: rem(5px);
      margin-right: rem(10px);
    }

    .select2-selection__choice {
      background-color: #e4e4e4;

      border: rem(1px) solid #aaa;
      border-radius: rem(4px);
      cursor: default;

      float: left;

      margin-right: rem(5px);
      margin-top: rem(5px);
      padding: 0 rem(5px);
    }

    .select2-selection__choice__remove {
      color: #999;
      cursor: pointer;

      display: inline-block;
      font-weight: bold;

      margin-right: rem(2px);

      &:hover {
        color: #333;
      }
    }
  }

  &[dir="rtl"] {
    .select2-selection--multiple {
      .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
        float: right;
      }

      .select2-selection__choice {
        margin-left: rem(5px);
        margin-right: auto;
      }

      .select2-selection__choice__remove {
        margin-left: rem(2px);
        margin-right: auto;
      }
    }
  }

  &.select2-container--focus {
    .select2-selection--multiple {
      border: solid black rem(1px);
      outline: 0;
    }
  }

  &.select2-container--disabled {
    .select2-selection--multiple {
      background-color: #eee;
      cursor: default;
    }

    .select2-selection__choice__remove {
      display: none;
    }
  }


  .select2-search--dropdown {
    .select2-search__field {
      border: rem(1px) solid #aaa;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: textfield;
    }
  }

  .select2-results > .select2-results__options {
    max-height: rem(200px);
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      background-color: #ddd;
    }

    .select2-results__option {
      padding-left: 1em;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background: $select-bg--selected;
    color: $select-color--selected;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: rem(6px);
  }
}
.select2-hidden-accessible {
  position: absolute;
  top: 0;
  left: 100000;
  opacity: 0;
  z-index: -100;
  display: none !important;
}

//overides
.select2 {
  &.select2-container--open {
    .select2-selection.select2-selection--single {
      border-color: #284178;
    }
  }
  .select2-selection.select2-selection--single {
    background-color: transparent;
    border: none;
    border: rem(1px) solid #B9B9B9;
    box-shadow: none;
    border-radius: rem(4px);
    .select2-selection__rendered {
      font-weight: 300;
      font-size: rem(18px);
      line-height: rem(22px);
      padding: rem(12px) rem(40px) rem(13px) rem(20px);
      letter-spacing: 0.02em;
      color: #000 !important;
      @media screen and (max-width: rem(768px)) {
        font-size: rem(14px);
        line-height: rem(22px);
        padding: rem(8px) rem(40px) rem(9px) rem(15px);
      }
    }
    .select2-selection__placeholder {
      color: #858585;
    }
    .select2-selection__arrow {
      width: rem(17px);
      height: rem(10px);
      right: rem(12px);
      top: 50%;
      transform: translateY(-50%);
      b {
        width: rem(17px);
        height: rem(10px);
        background-size: contain;
      }
    }
  }
}
.select2-dropdown {
  background: #fff;
  color: #000;;
  box-shadow: none;
  border-radius: 0 0 rem(4px) rem(4px);
  font-weight: 300;
  font-size: rem(16px);
  line-height: rem(22px);
  padding: rem(6px) 0;
  border: rem(1px) solid #284178;
  margin-top: rem(-10px);
  
  .select2-results__option {
    padding: rem(8px) rem(20px) rem(9px) rem(20px);
    transition: all .2s ease-in-out;
    &:hover {
      background: rgba(#C8AF7B, .2) !important;
    }
    &[aria-selected=true] {
      background: transparent;
    }
  }
}
body > .select2-container {
  width: auto;
  z-index: 99;
}
