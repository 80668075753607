.InfoBlock {
  @media screen and (min-width: rem(768px)) {
    .g-tp-column {
      &--first {
        padding-right: rem(9px);
      }
      &--last {
        padding-left: rem(9px);
      }
    }
  }
}
.m-info {
  display: block;
  margin-bottom: rem(18px);
  &-banner {
    height: rem(268px);
    background-size: cover;
    background-position: center;
    .m-info--full & {
      height: rem(326px);
    }
  }
  &-header {
    padding: rem(15px) rem(10px) rem(25px);
    background: $beige;
    .m-info--full & {
      padding: rem(27px) rem(10px) rem(20px);
    }
  }
  &-headline {
    font-size: rem(18px);
    margin-bottom: rem(7px) !important;
    font-family: $alt-font;
  }
  &-link {
    display: inline-block;
    position: relative;
    color: $light-grey;
    font-family: $alt-font-light;
    font-size: rem(16px);
    &::before {
      content: '';
      position: absolute;
      bottom: rem(-2px);
      left: 0;
      display: block;
      width: calc(100% - #{rem(22px)});
      height: 1px; 
      background: #65696D4D;
    }
    &::after {
      top: 50%;
      height: rem(12px);
      width: rem(8px);
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L1 9' stroke='%2365696D' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
  @media screen and (max-width: rem(768px)) {
    display: inline-block;
    width: 100%;
    margin-bottom: rem(20px);
    &-banner {
      height: rem(159px);
      .m-info--full & {
        height: rem(159px);
      }
    }
    &-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: rem(18px) rem(14px)  rem(15px) rem(17px);
      .m-info--full & {
        padding: rem(18px) rem(14px) rem(15px) rem(17px);
      }
    }
    &-headline {
      line-height: 0.8;
      font-size: rem(16px);
      font-family: $alt-font-light;
    }
    &-link {
      display: block;
      padding-right: rem(10px);
      font-size: rem(12px);
      
    }
  }
}
.m-faq {
  width: 100%;
  padding-top: rem(28px);
  padding-bottom: rem(40px);
  &-icon {
    display: block;
    width: rem(69px);
    margin: 0 auto rem(14px);
  }
  &-headline {
    margin-bottom: rem(32px) !important;
    font-size: rem(36px);
    font-family: "Roboto Condensed";
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  .g-tp-flex {
    flex-direction: column;
    align-items: center;
  }
  &-select {
    margin-bottom: rem(45px);
    .select {
      width: rem(510px);
    }
    .c-tp-formelement-select-container {
      width: rem(510px);
      background: #FFF;
      border-color: #B9B9B9;
      &::after {
        top: 50%;
        transform: translateY(-50%);
        right: rem(21px);
        background: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 1.71045L8.5 7.71045L2 1.71045' stroke='%23C0A468' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
        background-size: contain;
      }
      select {
        font-size: rem(16px);
        font-family: $alt-font-light;
      }
    }
  }
  .btn {
    padding-top: rem(14px) !important;
    padding-bottom: rem(14px) !important;
  }
  @media screen and (max-width: rem(768px)) {
    form {
      width: 100%;
    }
    &-select {
      margin-bottom: rem(0px);
      .select {
        width: 100%;
      }
    }
    &-headline {
      margin-bottom: rem(13px) !important;
      font-size: rem(24px);
    }
  }
}